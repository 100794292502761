import React from "react";
import { AppState, AppData } from "../../contexts/AppData";
import { RouteComponentProps } from "react-router";
import { TradingService } from "../../services/TradingService";
import { Util } from "../../services/Util";
import { SideNav } from "../../components/SideNav";
import { BasicStyles } from "../../styles";
import MaterialTable from "material-table";
import { BottomNav } from "../../components/BottomNav";

type Props = AppState & RouteComponentProps<{}>;

export class Notifications extends React.Component<Props, {}> {
  async componentDidMount() {
    const loggedIn = await TradingService.isLoggedIn();
    if (typeof loggedIn === "boolean") {
      this.props.history.push("/login");
    } else {
      await this.props.loadAllNotifications();
      await this.props.initializeAll();
      await TradingService.clearNotifications();
      await this.props.loadAllNotifications()
      this.props.doneLoading();
    }
  }

  render() {
    const notifications = this.props.notifications.map(n => ({
      ...n,
      date: new Date(n.createdAt)
    }));
    return (
      <div>
        <SideNav currentState={"home"} />
        <img height={150} src="logo.png" />

        <div style={BasicStyles.primaryTableFull}>
          <MaterialTable
            isLoading={this.props.loading}
            columns={[
              {
                title: "Notification",
                field: "text" as "text",
                type: "string" as "string"
              },
              {
                title: "Service",
                field: "service" as "service",
                type: "string"
              },
              {
                title: "Date",
                field: "date" as "date",
                type: "datetime"
              }
            ]}
            data={notifications}
            options={{ pageSize: 10 }}
            title="Notifications"
          />
        </div>
        <BottomNav currentState="home" />
      </div>
    );
  }
}

export function NotificationsContainer(props: RouteComponentProps) {
  return (
    <AppData.Consumer>
      {context => <Notifications {...props} {...context} />}
    </AppData.Consumer>
  );
}
