import React from "react";
import Card from "@material-ui/core/Card";
import { Link } from "react-router-dom";
import { BasicStyles } from "../styles";
import { Util } from "../services/Util";
import Divider from "@material-ui/core/Divider";

type PropType = {
  balance: number;
  fundingAmount: number;
  usdBalance: number;
  label: string;
  icon: string;
  link: string;
};
export function ExchangePanel(props: PropType) {
  return (
    <Card className="gridBox">
      <Link
        to={props.link}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          textAlign: "left",
          marginBottom: "10px",
        }}
      >
        <div>
          <h5 style={{ margin: 0, fontSize: "large", fontWeight: 400 }}>
            {props.label}
          </h5>
          <span style={{ fontSize: "x-large" }}>
            ${props.balance.toLocaleString()}
          </span>
        </div>

        <img
          className="roundIcon"
          height="40"
          src={props.icon}
        />
      </Link>
      <Divider />

      <div
        style={{
          fontWeight: 300,
          fontSize: "small",
          textAlign: "left",
          maxWidth: "250px",
        }}
      >
        <div style={BasicStyles.slightMargin}>
          Cash: ${Util.toFixedNumber(props.usdBalance)}
        </div>

        <div style={BasicStyles.slightMargin}>
          Investing ${props.fundingAmount} Per Month
        </div>
      </div>
    </Card>
  );
}
