import React from "react";
import { AppState, AppData, ServiceName } from "../../contexts/AppData";
import { RouteComponentProps } from "react-router";
import { BasicStyles } from "../../styles";
import MaterialTable from "material-table";
import { Util } from "../../services/Util";
import { TradingService } from "../../services/TradingService";
import { Loading } from "../../components/Loading";
import { SideNav } from "../../components/SideNav";
import { BottomNav } from "../../components/BottomNav";
import {
  ProductGain,
  IUserTrade,
  IFilledUserTrade,
  IntervalMap
} from "../../types";
import { CapGainsBarChart } from "../../components/CapGainsBarChart";
import { CapitalGainsLineChart } from "../../components/CapitalGainsLineChart";
import { GainCalculator } from "../../components/GainCalculator";
import { HasAssetDeposits } from "../../constants";
type Props = AppState &
  RouteComponentProps<{
    currency: string;
    service: string;
  }>;

export class Holding extends React.Component<Props, {}> {
  async componentDidMount() {
    const loggedIn = await TradingService.isLoggedIn();
    if (typeof loggedIn === "boolean") {
      this.props.history.push("/login");
    } else {
      this.props.setUser(loggedIn);
      const service = this.props.match.params.service;
      await this.props.initialize(service as ServiceName);
      this.getMergedPotentials();
      this.props.doneLoading();
    }
  }

  getProductTrades() {
    let trades = new Array<IFilledUserTrade>();
    const product = this.props.match.params.currency;
    const service = this.props.match.params.service;
    const serviceData = this.props.services[service];
    if (serviceData && serviceData.allTrades.filled) {
      trades = trades.concat(
        serviceData.allTrades.filled.filter(
          t => Util.getCurrencyFromPair(t.productId) === product
        )
      );
    }
    return trades;
  }

  getMergedTotalInvested() {
    let total = 0;
    const trades = this.getProductTrades();
    total += trades.reduce((sum, t) => {
      if (t.side === "buy") {
        return sum + t.amount * t.price;
      } else {
        return sum;
      }
    }, 0);
    return Util.toFixedNumber(total);
  }

  getAggregateGains() {
    const service = this.props.match.params.service;
    const product = this.props.match.params.currency;
    const serviceData = this.props.services[service];
    const currencyTrades = this.getProductTrades().filter(
      t => t.side === "buy" && t.status != "cancelled"
    );
    let gains = 0;
    let totalShares = 0;
    let totalSpent = 0;
    let unrealizedLT = 0;
    let unrealizedST = 0;
    let realizedLT = 0;
    let realizedST = 0;

    for (const t of currencyTrades) {
      gains += (t.realized || 0) + (t.unrealized || 0);
      unrealizedLT += t.unrealizedLT || 0;
      unrealizedST += t.unrealizedST || 0;
      realizedLT += t.realizedLT || 0;
      realizedST += t.realizedST || 0;
      if (t.side === "buy") {
        totalShares += t.amount - (t.realizedAmount || 0);
        totalSpent += (t.amount - (t.realizedAmount || 0)) * t.price;
      }
    }

    gains = Util.toFixedNumber(gains);
    totalShares = Util.toFixedNumber(totalShares, 4);
    totalSpent = Util.toFixedNumber(totalSpent);
    unrealizedLT = Util.toFixedNumber(unrealizedLT);
    unrealizedST = Util.toFixedNumber(unrealizedST);
    realizedLT = Util.toFixedNumber(realizedLT);
    realizedST = Util.toFixedNumber(realizedST);

    const avgPrice = totalSpent / totalShares;
    const currentPrice = serviceData.serviceRates.rates[product];
    const balanceUsd = serviceData.balances[product].unlocked * currentPrice;
    const balanceGainUsd = Util.toFixedNumber(balanceUsd - totalSpent);
    const tradeGain = Util.toFixedNumber(gains);
    const gain = HasAssetDeposits[service] ? tradeGain : balanceGainUsd; 

    const gainPercent = Util.toFixedNumber((gain / totalSpent) * 100);

    const gainProfile = {
      currency: product,
      gain,
      service,
      totalShares,
      totalSpent,
      avgPrice,
      currentPrice,
      realizedLT,
      realizedST,
      unrealizedLT,
      unrealizedST,
      gainPercent
    };
    return gainProfile;
  }

  getServiceData() {
    const service = this.props.match.params.service;
    const product = this.props.match.params.currency;
    const serviceData = this.props.services[service];
    return serviceData;
  }

  getCurrentPrice() {
    const serviceData = this.getServiceData();
    const product = this.props.match.params.currency;
    const currentPrice = Util.toFixedNumber(
      serviceData.serviceRates.rates[product]
    );
    return currentPrice;
  }

  getCurrentBalance() {
    const serviceData = this.getServiceData();
    const product = this.props.match.params.currency;
    const balance = Util.toFixedNumber(serviceData.balances[product].unlocked);
    return balance;
  }

  getCapitalGains() {
    const capitalGains = this.getProductTrades()
      .filter(t => t.unrealized)
      .sort((t1, t2) => t2.gainAgeDays - t1.gainAgeDays);

    let chartData = new Array<{
      date: string;
      longterm: number;
      longtermBalance: number;
      shortterm: number;
      shorttermBalance: number;
    }>();

    if (capitalGains.length) {
      let gainIndex = 0;
      let currentDate = new Date();
      let endDate = new Date(Date.now() + IntervalMap.yearly);
      let monthOffset = 365 / 12;

      while (gainIndex <= 12) {
        let longterm = 0;
        let longtermBalance = 0;
        let shortterm = 0;
        let shorttermBalance = 0;
        let currentOffset = monthOffset * gainIndex;

        for (const gain of capitalGains) {
          if (gain.gainAgeDays + currentOffset > 365) {
            longtermBalance += gain.amount;
            longterm += gain.unrealized;
          } else {
            shorttermBalance += gain.amount;
            shortterm += gain.unrealized;
          }
        }
        longterm = Util.toFixedNumber(longterm);
        shortterm = Util.toFixedNumber(shortterm);
        shorttermBalance = Util.toFixedNumber(shorttermBalance, 3);
        longtermBalance = Util.toFixedNumber(longtermBalance, 3);
        chartData.push({
          longterm,
          shortterm,
          longtermBalance,
          shorttermBalance,
          date: currentDate.toLocaleDateString()
        });

        currentDate = new Date(currentDate.getTime() + IntervalMap.monthly);
        gainIndex++;
      }
    }
    return chartData;
  }

  getMergedPotentials() {
    const service = this.props.match.params.service;
    const product = this.props.match.params.currency;
    let allPotentials = [] as Array<ProductGain>;
    const serviceData = this.props.services[service];
    if (serviceData && serviceData.potential) {
      allPotentials = allPotentials.concat(
        Object.values(serviceData.potential).filter(
          p => Util.getCurrencyFromPair(p.productId) === product
        )
      );
    }
    const sorted = allPotentials.sort(
      (a, b) => b.yearly.percent - a.yearly.percent
    );
    const sum = sorted.reduce(
      (total, potential) => total + potential.yearly.percent,
      0
    );
    const count = sorted.length;
    const max = count > 0 ? sorted[0].yearly.percent : 0;
    const min = count > 0 ? sorted[count - 1].yearly.percent : 0;

    for (const s of sorted) {
      console.log(
        s.productId,
        s.yearly.percent,
        (s.yearly.percent - min) / (max - min)
      );
    }

    return sorted;
  }

  render() {
    const gains = this.getAggregateGains();
    const totalInvested = this.getMergedTotalInvested();
    const capitalGains = this.getCapitalGains();
    const product = this.props.match.params.currency;
    const service = this.props.match.params.service;
    return (
      <div>
        <SideNav currentState={"home"} />
        {window.innerWidth <= 700 ? <img height={150} src="/logo.png" /> : null}
        <h2
          style={{
            fontSize: "48px",
            ...BasicStyles.moneyText
          }}
        >
          {product}
        </h2>
        <div style={BasicStyles.primaryTableFull}>
          <MaterialTable
            isLoading={this.props.loading}
            columns={[
              {
                title: "Service",
                field: "service" as "service",
                type: "string" as "string"
              },
              {
                title: "Shares",
                field: "totalShares" as "totalShares",
                type: "numeric"
              },
              {
                title: "Invested",
                field: "totalSpent" as "totalSpent",
                type: "currency"
              },
              {
                title: "Avg. Price",
                field: "avgPrice" as "avgPrice",
                type: "currency"
              },
              {
                title: "Current Price",
                field: "currentPrice" as "currentPrice",
                type: "currency"
              },
              {
                title: "Gain",
                field: "gain" as "gain",
                type: "currency"
              },
              {
                title: "%",
                field: "gainPercent" as "gainPercent",
                type: "numeric"
              }
            ]}
            data={[gains]}
            totalCount={1}
            options={{ pageSize: 1 }}
            title="Robo-Trades Holding"
          />
        </div>
        <div style={BasicStyles.slightPadding}>
          <h1>Unrealized Capital Gains</h1>
          <div
            style={{ ...BasicStyles.primaryCard, ...BasicStyles.rowOrColumn }}
          >
            <div>
              <h2>Long Term: ${gains.unrealizedLT}</h2>
              <h2>Short Term: ${gains.unrealizedST}</h2>
            </div>
            <CapitalGainsLineChart gains={capitalGains} />
          </div>
        </div>

        <div style={BasicStyles.slightPadding}>
          <h1>Gain Calculator</h1>
          <div
            style={{ ...BasicStyles.primaryCard, ...BasicStyles.rowOrColumn }}
          >
            <GainCalculator
              trades={this.getProductTrades()}
              currentPrice={gains.currentPrice}
              currentBalance={gains.totalShares}
              gains={capitalGains}
            />
          </div>
        </div>

        <BottomNav currentState="home" />
      </div>
    );
  }
}
export function HoldingContainer(
  props: RouteComponentProps<{ currency: string; service: string }>
) {
  return (
    <AppData.Consumer>
      {context => <Holding {...props} {...context} />}
    </AppData.Consumer>
  );
}
