import React from "react";
import * as qs from "qs";
import Button from "@material-ui/core/Button";
import { IUser } from "../../types";
import { TradingService } from "../../services/TradingService";
import { AppData } from "../../contexts/AppData";
import { RouteComponentProps } from "react-router";
import { UpdateUser } from "../../components/UpdateUser";
import { Loading } from "../../components/Loading";
import { SideNav } from "../../components/SideNav";
import { BottomNav } from "../../components/BottomNav";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

const liveKey = "pk_live_YVMWXaWNgc1bQm0iahekh2fN";
const testKey = "pk_test_2Dr5cUREN5hXRAbjIG8UI0Yb";
const backendUrl = process.env.REACT_APP_BACKEND || "http://localhost:8000";

const stripeKey = backendUrl.includes("localhost") ? testKey : liveKey;
const prodUrl = "https://www.robo-trades.com/pro";
const DOMAIN = window.location.hostname;
const testUrl = window.location.protocol + "//" + DOMAIN + ":3000/pro";
const redirectUrl = backendUrl.includes("localhost") ? testUrl : prodUrl;

const planId = backendUrl.includes("localhost")
  ? "plan_Ga175J1YhiV82k"
  : "plan_Ga4BkoniW7HUqd";

type Props = RouteComponentProps<{}> & {
  user: IUser;
  setUser: (user: IUser) => void;
};
type State = {
  error?: string;
  cancelOpen: boolean;
};
export class ProfileComponent extends React.Component<Props, State> {
  state = {
    cancelOpen: false
  };
  async componentDidMount() {
    const loggedIn = await TradingService.isLoggedIn();
    if (typeof loggedIn === "boolean") {
      this.props.history.push("/login");
    } else {
      this.props.setUser(loggedIn);
    }
  }

  handleResult(result: any) {
    if (result.error) {
      var displayError = document.getElementById("error-message");
      this.setState({ error: result.error.message });
    }
  }

  handleSubscribe() {
    const stripe = (window as any).Stripe(stripeKey);
    const DOMAIN = window.location.hostname;
    stripe
      .redirectToCheckout({
        items: [{ plan: planId, quantity: 1 }],
        customerEmail: this.props.user.email,
        successUrl: redirectUrl + "/success?session_id={CHECKOUT_SESSION_ID}",
        cancelUrl: redirectUrl + "/canceled"
      })
      .then((r: any) => this.handleResult(r));
  }

  async handleUnsubscribe() {
    const resp = await window.fetch(backendUrl + "/stripe/cancel", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + TradingService.getToken()
      }
    });
    const canceled = (await resp.json()) as Promise<{ canceled: boolean }>;
    return canceled;
  }

  render() {
    if (!this.props.user || !this.props.user.email) {
      return <Loading />;
    }
    const isSubbed = this.props.user.stripeSubscription;
    const subDate = this.props.user.subscriptionValidTil;
    const setCancelOpen = (open = false) => this.setState({ cancelOpen: open });
    return (
      <div>
        <SideNav currentState={"profile"} />
        <img height={150} src="/logo.png" />
        <UpdateUser
          user={this.props.user}
          onSubmit={user => TradingService.updateUser(user)}
        />
        {!isSubbed ? (
          <Button onClick={() => this.handleSubscribe()}>Upgrade to Pro</Button>
        ) : (
          <div>
            <Button onClick={() => setCancelOpen(true)}>Unsubscribe</Button>
          </div>
        )}
        {subDate ? (
          <div>
            <h2> Robo-Trades Pro Member </h2>
            <span>
              {isSubbed ? "Renews" : "Expires"}{" "}
              {new Date(subDate * 1000).toLocaleDateString()}
            </span>
          </div>
        ) : null}
        <Dialog
          open={this.state.cancelOpen}
          onClose={() => setCancelOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Cancel your subscription?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will lose access to auto-approve and the daily trade schedule
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCancelOpen(false)} color="primary">
              Never Mind
            </Button>
            <Button
              onClick={() => {
                this.handleUnsubscribe();
                setCancelOpen(false);
              }}
              color="primary"
              autoFocus
            >
              Cancel it
            </Button>
          </DialogActions>
        </Dialog>

        <BottomNav currentState="profile" />
      </div>
    );
  }
}
export function ProfileContainer(props: RouteComponentProps) {
  return (
    <AppData.Consumer>
      {context => <ProfileComponent {...props} {...context} />}
    </AppData.Consumer>
  );
}
