import React from "react";
import { IUserTrade } from "../types";
import Timeline from "@material-ui/icons/Timeline";
import {
  LineChart,
  Bar,
  Legend,
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid,
  Line,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { Util } from "../services/Util";
export function MonthlyOrdersBarChart(props: {
  orders: Array<IUserTrade>;
  goal: number;
}) {
  const monthlyData = Object.values(
    props.orders
      .filter((o) => o.status === "placed" || o.status === "filled")
      .reduce(
        (group, o) => {
          const placedVal =
            o.order.created_at || o.order.createdDateTime || o.order.postedDate;
          if (placedVal) {
            const placed = new Date(placedVal);
            const placedKey =
              placed.getMonth() + 1 + "-" + placed.getFullYear();

            if (!group[placedKey]) {
              group[placedKey] = {
                month: placedKey,
                year: placed.getFullYear(),
                date: placed,
                placed: 0,
                filled: 0,
              };
            }
            group[placedKey].placed = Util.toFixedNumber(
              group[placedKey].placed + o.amount * o.price
            );
          }

          const filledVal =
            o.order.done_at || o.order.updated_at || o.order.postedDate;
          if (filledVal) {
            const filled = new Date(filledVal);
            const filledKey =
              filled.getMonth() + 1 + "-" + filled.getFullYear();

            if (!group[filledKey]) {
              group[filledKey] = {
                month: filledKey,
                year: filled.getFullYear(),
                date: filled,
                placed: 0,
                filled: 0,
              };
            }
            group[filledKey].filled = Util.toFixedNumber(
              group[filledKey].filled + o.amount * o.price
            );
          }
          return group;
        },
        {} as {
          [monthKey: string]: {
            month: string;
            year: number;
            placed: number;
            date: Date;
            filled: number;
          };
        }
      )
  ).sort((a, b) => a.date.getTime() - b.date.getTime());
  if (!monthlyData || !monthlyData.length) {
    return (
      <ResponsiveContainer height="75%">
        <div>
          <div>
            <Timeline style={{ fontSize: "200px", color: "gray" }} />
          </div>
          <p>Approve some trades to get a cool graph here</p>
        </div>
      </ResponsiveContainer>
    );
  }
  return (
    <ResponsiveContainer height="75%">
      <LineChart
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        data={monthlyData.slice(-12)}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line dataKey="placed" stroke="#8884d8" />
        <Line dataKey="filled" stroke="#82ca9d" />
        <ReferenceLine
          y={props.goal}
          label={
            {
              position: "top",
              value: "Investing Goal",
            } as any
          }
          strokeDasharray="5 5"
          stroke="gray"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
