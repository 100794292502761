export const HasAssetDeposits = {
  coinbase: true,
  kraken: true,
} as { [key: string]: boolean };
export const Membership = {
  Pro: {
    cost: 5,
    limit: {
      coinbase: 15,
      "coinbase-advanced": 15,
      sofi: 15,
      robinhood: 15,
      kraken: 15,
      binance: 15,
      celcius: 0,
    },
  },
  Free: {
    cost: 0,
    limit: {
      coinbase: 1,
      "coinbase-advanced": 1,
      sofi: 3,
      robinhood: 3,
      kraken: 3,
      binance: 3,
      celcius: 0,
    },
  },
};
