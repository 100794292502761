import { IFilledUserTrade } from "../types";
import React from "react";
import {
  IUser,
  IUserFunding,
  IUserTrade,
  Rates,
  IUserDividend,
  IUserNotification,
  Candle,
  CurrencyCandles,
  CurrencyBalances,
  ServicesCurrencyBalances,
  ProductGains,
  IUserFund,
  ServiceRates,
  UsdBalanceHistory,
  UsdBalanceHistoryAggregate,
} from "../types";
import { TradingService } from "../services/TradingService";
import { RouteComponentProps } from "react-router";
import Socket from "socket.io-client";
import { Util } from "../services/Util";
import { Cache } from "../services/Cache";

export const AppData = React.createContext<AppState>({} as AppState);

interface Props {}
export type ServiceName =
  | "coinbase-advanced"
  | "robinhood"
  | "sofi"
  | "kraken"
  | "binance"
  | "celcius";

const backendUrl = process.env.REACT_APP_BACKEND || "http://localhost:8000";
let client: ReturnType<typeof Socket["connect"]>;
export interface AppState {
  loading: boolean;
  user: IUser;
  notifications: Array<IUserNotification>;
  configure: boolean;
  funds: Array<IUserFund>;
  services: ServiceData;
  getSocket: () => typeof Socket["Socket"];
  isLoggedIn: () => Promise<IUser | boolean>;
  setUser: (user: IUser) => void;
  initializeAll: () => Promise<void>;
  initialize: (service: ServiceName, loadMany?: boolean) => Promise<void>;
  initializeSeries: (service: ServiceName, loadMany?: boolean) => Promise<void>;
  refreshFunds: () => Promise<void>;
  refreshFunding: (service: ServiceName) => Promise<void>;
  refreshTrades: (service: ServiceName) => Promise<void>;
  updateTrade: (service: ServiceName, trade: IUserTrade) => Promise<void>;
  updateFunding: (
    service: ServiceName,
    fundingId: IUserFunding
  ) => Promise<void>;
  loadAllNotifications: () => Promise<void>;
  getTotalBalance: (
    service: string,
    balances: any,
    serviceRates: any
  ) => number;
  startLoading: () => void;
  doneLoading: () => void;
  getNotificationCountFor: (service: ServiceName) => number;

  loadRates: (
    service: string,
    funding?: Array<IUserFunding>
  ) => Promise<ServiceRates>;

  loadCandles: (
    service: string,
    funding?: Array<IUserFunding>,
    balance?: CurrencyBalances
  ) => Promise<CurrencyCandles>;
  getPairs: (
    service: string,
    funding?: Array<IUserFunding>,
    balance?: CurrencyBalances
  ) => Array<{ symbol: string; price: number; pair: string }>;
  getPriceOfPair: (service: string, pair: string) => number;
  getMergedBalanceHistory: () => { [date: string]: ServicesCurrencyBalances };
  getUsdBalanceHistory: () => UsdBalanceHistoryAggregate;
  getMergedTotalBalance: () => number;
  getMergedBalances: () => CurrencyBalances;
  getMergedCandles: () => CurrencyCandles;
}
type ServiceData = {
  [service: string]: {
    totalBalance: number;
    balances: CurrencyBalances;
    balanceHistory: { [date: string]: CurrencyBalances };
    candles: CurrencyCandles;
    trades: Array<IUserTrade>;
    dividends: Array<IUserDividend>;
    pendingTrades: Array<IUserTrade>;
    filledTrades: Array<IUserTrade>;
    allTrades: { pending: Array<IUserTrade>; filled: Array<IFilledUserTrade> };
    funding: Array<IUserFunding>;
    serviceRates: { currency: string; rates: Rates };
    potential: ProductGains;
  };
};

function getDefaultServiceState() {
  return {
    totalBalance: 0,
    balances: {
      USD: {
        locked: 0,
        unlocked: 0,
      },
    },
    balanceHistory: {},
    trades: [],
    pendingTrades: [],
    allTrades: { pending: [], filled: [] },
    filledTrades: [],
    dividends: [],
    serviceRates: { currency: "USD", rates: {} },
    candles: {},
    funding: [],
    potential: {},
  };
}

export class AppDataProvider extends React.Component<Props, AppState> {
  state: AppState = {
    loading: true,
    configure: false,
    user: {} as IUser,
    notifications: [],
    funds: [],
    services: {
      "coinbase-advanced": getDefaultServiceState(),
      robinhood: getDefaultServiceState(),
      sofi: getDefaultServiceState(),
      kraken: getDefaultServiceState(),
      binance: getDefaultServiceState(),
      celcius: getDefaultServiceState(),
    },

    isLoggedIn: async () => {
      const loggedIn = await TradingService.isLoggedIn();
      return loggedIn;
    },

    setUser: (user: IUser) => {
      TradingService.setUser(user);
      return this.setState({ user });
    },

    getSocket() {
      if (!client || client.disconnected) {
        client = Socket.connect(backendUrl, {
          transports: ["websocket", "polling"],
        });
      }
      client.emit("login", TradingService.getToken());
      return client;
    },

    initializeAll: async function () {
      await Promise.all([
        this.initialize("robinhood", true),
        this.initialize("sofi", true),
        this.initializeSeries("kraken", true),
        this.initialize("binance", true),
        this.initialize("celcius", true),
        this.initialize("coinbase-advanced", true),
      ]);
    },

    initialize: async (service: ServiceName, loadMany = false) => {
      try {
        console.log("Initializing", service);
        const hasCredentials = await TradingService.getSecret(service);
        if (!hasCredentials) {
          return;
        }
        const [
          balances,
          balanceHistory,
          serviceRates,
          trades,
          dividends,
          funding,
          notifications,
          allTrades,
        ] = await Promise.all([
          TradingService.getBalanceForService(service),
          TradingService.getBalanceHistoryForService(service),
          this.state.loadRates(service),
          TradingService.getInternalTradesForService(service),
          TradingService.getDividends(service),
          TradingService.getAllUserFunding(service),
          TradingService.notifications(),
          TradingService.getAllTradesForService(service),
        ]);

        const mostRecentDate = Util.getClosestDate(
          new Date(),
          Object.keys(balanceHistory)
        );
        const mostRecentHistoryBalance = balanceHistory[mostRecentDate];
        const candles = await this.state.loadCandles(
          service,
          funding,
          mostRecentHistoryBalance
        );
        const totalBalance = this.state.getTotalBalance(
          service,
          balances,
          serviceRates
        );

        const pendingTrades = trades.filter((t) => !t.status);
        const filledTrades = trades.filter((t) => t.status === "filled");

        const serviceData = {
          balances,
          balanceHistory,
          serviceRates,
          totalBalance,
          dividends,
          funding,
          trades,
          candles,
          pendingTrades,
          filledTrades,
          allTrades,
        };

        const services = Object.assign({}, this.state.services, {
          ...this.state.services,
          [service]: serviceData,
        });

        await this.setState({ services, notifications });
      } catch (e) {
        if (!loadMany) {
          this.state.doneLoading();
        }
      }
    },

    initializeSeries: async (service: ServiceName, loadMany = false) => {
      try {
        console.log("Initializing", service);
        const hasCredentials = await TradingService.getSecret(service);
        if (!hasCredentials) {
          return;
        }
        const balances = await TradingService.getBalanceForService(service);
        const balanceHistory = await TradingService.getBalanceHistoryForService(
          service
        );
        const serviceRates = await this.state.loadRates(service);
        const trades = await TradingService.getInternalTradesForService(
          service
        );
        const dividends = await TradingService.getDividends(service);
        const funding = await TradingService.getAllUserFunding(service);
        const notifications = await TradingService.notifications();
        const allTrades = await TradingService.getAllTradesForService(service);

        const mostRecentDate = Util.getClosestDate(
          new Date(),
          Object.keys(balanceHistory)
        );
        const mostRecentHistoryBalance = balanceHistory[mostRecentDate];
        const candles = await this.state.loadCandles(
          service,
          funding,
          mostRecentHistoryBalance
        );
        const totalBalance = this.state.getTotalBalance(
          service,
          balances,
          serviceRates
        );

        const pendingTrades = trades.filter((t) => !t.status);
        const filledTrades = trades.filter((t) => t.status === "filled");

        const serviceData = {
          balances,
          balanceHistory,
          serviceRates,
          totalBalance,
          dividends,
          funding,
          trades,
          candles,
          pendingTrades,
          filledTrades,
          allTrades,
        };

        const services = Object.assign({}, this.state.services, {
          ...this.state.services,
          [service]: serviceData,
        });

        await this.setState({ services, notifications });
      } catch (e) {
        if (!loadMany) {
          this.state.doneLoading();
        }
      }
    },

    loadAllNotifications: async () => {
      const notifications = await TradingService.notifications();
      this.setState({ notifications });
    },

    refreshFunds: async () => {
      const funds = await TradingService.getAllUserFunds();
      await this.setState({ funds });
    },

    refreshFunding: async (service: ServiceName) => {
      const funding = await TradingService.getAllUserFunding(service);
      const services = Object.assign({}, this.state.services, {
        ...this.state.services,
        [service]: {
          ...this.state.services[service],
          funding,
        },
      });
      await this.setState({ services });
    },

    refreshTrades: async (service: ServiceName) => {
      const trades = await TradingService.getInternalTradesForService(service);
      const services = Object.assign({}, this.state.services, {
        ...this.state.services,
        [service]: {
          ...this.state.services[service],
          trades,
        },
      });
      await this.setState({ services });
    },

    updateTrade: async (service: ServiceName, trade: IUserTrade) => {
      const trades = this.state.services[service].trades || [];
      const index = trades.findIndex((t) => t._id === trade._id);
      if (index > -1) {
        trades[index] = trade;
      } else {
        trades.push(trade);
      }
      const services = Object.assign({}, this.state.services, {
        ...this.state.services,
        [service]: {
          ...this.state.services[service],
          trades,
        },
      });
      await this.setState({ services });
    },

    updateFunding: async (service: ServiceName, userFunding: IUserFunding) => {
      const funding = this.state.services[service].funding || [];
      const index = funding.findIndex((t) => t._id === userFunding._id);
      if (index > -1) {
        funding[index] = userFunding;
      } else {
        funding.push(userFunding);
      }
      const services = Object.assign({}, this.state.services, {
        ...this.state.services,
        [service]: {
          ...this.state.services[service],
          funding,
        },
      });
      await this.setState({ services });
    },

    getTotalBalance: (
      service: string,
      balances: any,
      serviceRates: any
    ): number => {
      let total = 0;
      for (let currency in balances) {
        const balance = Number(balances[currency].unlocked);
        const rate = currency != "USD" ? serviceRates.rates[currency] : 1;
        total += balance && rate ? balance * rate : 0;
      }
      return total;
    },

    startLoading: () => {
      this.setState({ loading: true });
    },

    doneLoading: () => {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 150);
    },

    getNotificationCountFor: (service: string) => {
      let count = 0;
      const serviceData = this.state.services[service];
      if (serviceData && serviceData.funding) {
        count += serviceData.pendingTrades.filter((t) => !t.status).length;
      }
      return count;
    },

    loadRates: async (service: string, funding?: Array<IUserFunding>) => {
      const serviceRates = await TradingService.getRatesForService(service);
      if (funding) {
        for (const fund of funding) {
          const currency = Util.getCurrencyFromPair(fund.purchaseSymbol);
          if (!serviceRates.rates[currency]) {
            // we don't have the candles for this. (Caching) need to fetch
            const rate = await TradingService.getRatesForServicePair(
              service,
              currency
            );
            Object.assign(serviceRates.rates, rate);
          }
        }
      }
      return serviceRates;
    },

    loadCandles: async (
      service: string,
      funding?: Array<IUserFunding>,
      balance?: CurrencyBalances
    ) => {
      const candles = await TradingService.getAllCandles(service, 90);
      const currencyCandles: CurrencyCandles = {};
      for (const productId in candles) {
        const currency = Util.getCurrencyFromPair(productId);
        currencyCandles[currency] = candles[productId];
      }
      if (funding) {
        for (const fund of funding) {
          // we don't have the candles for this. (Caching) need to fetch
          const currency = Util.getCurrencyFromPair(fund.purchaseSymbol);
          if (candles[currency] && !candles[fund.purchaseSymbol]) {
            candles[fund.purchaseSymbol] = candles[currency];
          }
          if (!candles[fund.purchaseSymbol]) {
            currencyCandles[currency] = await TradingService.getCandles(
              service,
              fund.purchaseSymbol,
              90
            );
          }
        }
      }
      return currencyCandles;
    },

    getPairs: (
      service: string,
      funding?: Array<IUserFunding>,
      balance?: CurrencyBalances
    ) => {
      const serviceData = this.state.services[service];
      const serviceFunding = funding || serviceData.funding;
      const serviceBalance = balance || serviceData.balances;

      const fundingCurrencies = serviceFunding.map((f) =>
        Util.getCurrencyFromPair(f.purchaseSymbol)
      );

      const balancePairs = Object.entries(serviceBalance)
        .filter(([k, v]) => k != "USD")
        .map(([k, v]) => ({
          currency: Util.getPairFromCurrency(service, k),
        }));

      const allPairs = serviceFunding
        .map((f) => f.purchaseSymbol)
        .concat(balancePairs.map((b) => b.currency));

      return Array.from(new Set(allPairs)).map((pair) => ({
        symbol: Util.getCurrencyFromPair(pair),
        price: this.state.getPriceOfPair(service, pair),
        pair,
      }));
    },

    getPriceOfPair: (service: string, pair: string) => {
      const serviceData = this.state.services[service];
      const currency = Util.getCurrencyFromPair(pair);
      let currentRate = serviceData.serviceRates.rates[currency];
      if (!currentRate) {
        const currencyCandles = serviceData.candles[currency];
        const lastCandle = currencyCandles[currencyCandles.length - 1];
        currentRate = lastCandle ? lastCandle.close : 1;
      }
      return Util.toFixedNumber(currentRate);
    },

    getMergedBalanceHistory: () => {
      const cacheKey = `getMergedBalanceHistory`;
      const cached = Cache.get(cacheKey);
      if (cached) {
        return cached;
      }
      const services = this.state.services;
      const allServices = Object.keys(services);
      const sortedDates = allServices
        .map((s) => Object.keys(services[s].balanceHistory))
        .flat()
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      const startDate = sortedDates[0];
      const endDate = sortedDates[sortedDates.length - 1];

      let lastServiceCheckpoint: {
        [service: string]: CurrencyBalances;
      } = {};
      let balances: { [date: string]: CurrencyBalances } = {};
      if (!startDate || !endDate) {
        return balances;
      }

      let datePtrStr = startDate;
      let datePtr = new Date(datePtrStr);
      const isValidDate = !!Date.parse(datePtrStr);
      while (isValidDate && datePtrStr != new Date().toDateString()) {
        console.log(datePtrStr);
        if (!balances[datePtrStr]) {
          balances[datePtrStr] = {};
        }
        for (const s of allServices) {
          const history = services[s].balanceHistory[datePtrStr];
          if (history) {
            lastServiceCheckpoint[s] = history;
          }
          if (lastServiceCheckpoint[s]) {
            // merge checkpoint into history
            for (const currency in lastServiceCheckpoint[s]) {
              if (!balances[datePtrStr][currency]) {
                balances[datePtrStr][currency] = {
                  unlocked: lastServiceCheckpoint[s][currency].unlocked,
                  locked: lastServiceCheckpoint[s][currency].locked,
                };
              } else {
                balances[datePtrStr][currency].unlocked +=
                  lastServiceCheckpoint[s][currency].unlocked;
                balances[datePtrStr][currency].locked +=
                  lastServiceCheckpoint[s][currency].locked;
              }
            }
          }
        }
        datePtr.setDate(datePtr.getDate() + 1);
        datePtrStr = datePtr.toDateString();
        datePtr = new Date(datePtrStr);
      }
      console.log(balances);
      Cache.put("getMergedBalanceHistory", balances, Cache.Times.daily);
      return balances;
    },

    getMergedTotalBalance: () => {
      let total = 0;
      for (const service of Object.keys(this.state.services)) {
        const serviceData = this.state.services[service];
        if (serviceData && serviceData.totalBalance) {
          const totalBalance = this.state.services[service].totalBalance;
          console.log(service, totalBalance);
          total += totalBalance;
        }
      }
      return Util.toFixedNumber(total);
    },

    getMergedBalances: () => {
      const merged = {} as {
        [currency: string]: {
          locked: number;
          unlocked: number;
        };
      };
      for (const service of Object.keys(this.state.services)) {
        for (const currency of Object.keys(
          this.state.services[service].balances
        )) {
          if (!merged[currency]) {
            merged[currency] = {
              ...this.state.services[service].balances[currency],
            };
          } else {
            merged[currency].locked += this.state.services[service].balances[
              currency
            ].locked;
            merged[currency].unlocked += this.state.services[service].balances[
              currency
            ].unlocked;
          }
        }
      }
      return merged;
    },

    getMergedCandles: () => {
      let candles: CurrencyCandles = {};
      for (const service of Object.keys(this.state.services)) {
        const serviceData = this.state.services[service];
        for (const currency of Object.keys(serviceData.candles)) {
          if (!candles[currency]) {
            candles[currency] = serviceData.candles[currency];
          }
        }
      }
      return candles;
    },

    getUsdBalanceHistory: () => {
      const totalCashValue = this.state.getMergedTotalBalance();
      const balances = this.state.getMergedBalances();
      const candles = this.state.getMergedCandles();
      const holdings = this.state.getMergedBalanceHistory();

      const dateBalances: {
        [date: string]: UsdBalanceHistory;
      } = {};

      let maxServices = 0;
      let maxBalance: typeof dateBalances["0"] | undefined;
      let lowestBalance: typeof dateBalances["0"] | undefined;
      const currencyCandles = ["USD"].concat(Object.keys(candles));
      const mostRecentDate = Util.getClosestDate(
        new Date(),
        Object.keys(holdings)
      );
      const mostRecentHolding = holdings[mostRecentDate] || {};
      const balanceCurrencies = Object.keys(mostRecentHolding);
      console.log(
        balanceCurrencies.filter((c) => !currencyCandles.includes(c))
      );
      const cacheKey = "HistoricalBalance";
      const cached = Cache.get(cacheKey);
      const cacheTolerable =
        cached &&
        cached.maxBalance &&
        Math.abs(cached.maxBalance.usd - totalCashValue) / totalCashValue <
          0.15;
      if (cacheTolerable) {
        return cached as UsdBalanceHistoryAggregate;
      }
      Cache.expire(cacheKey);
      Cache.expire(`getMergedBalanceHistory`);
      for (let currency of currencyCandles) {
        if (candles[currency]) {
          const currencyCandles = candles[currency];
          let offset = 0;
          if (
            mostRecentHolding &&
            mostRecentHolding[currency] &&
            balances[currency]
          ) {
            offset =
              balances[currency].unlocked -
              mostRecentHolding[currency].unlocked;
            console.log(currency, offset);
          }
          const dates = Object.keys(holdings).filter((h) =>
            Object.keys(holdings[h]).includes(currency)
          );
          let mostRecentDateIndex = 0;
          for (const candle of currencyCandles) {
            const date = new Date(candle.time);

            while (
              date > new Date(dates[mostRecentDateIndex]) &&
              mostRecentDateIndex < dates.length - 1
            ) {
              mostRecentDateIndex++;
            }

            if (date < new Date(dates[mostRecentDateIndex])) {
              mostRecentDateIndex--;
            }

            const closestBalanceDate = dates[mostRecentDateIndex];
            const mostRecentHolding = holdings[closestBalanceDate] || {};
            const holding = mostRecentHolding[currency];
            const unlocked = holding ? holding.unlocked + offset : 0;
            const key = date.toLocaleDateString();
            const price = candle.close || candle.open;
            const value = Util.toFixedNumber(unlocked * price);
            if (!dateBalances[key]) {
              const usdBalance =
                balances["USD"].unlocked + balances["USD"].locked;
              dateBalances[key] = {
                date: key,
                usd: value + usdBalance,
                services: 1,
                currencies: [currency],
                contributions: [{ currency, value: value + usdBalance }],
              };
            } else if (!dateBalances[key].currencies.includes(currency)) {
              dateBalances[key].usd += value;
              dateBalances[key].services++;
              dateBalances[key].usd = Util.toFixedNumber(dateBalances[key].usd);
              dateBalances[key].currencies.push(currency);
              dateBalances[key].contributions.push({ currency, value });
            }
            if (maxServices < dateBalances[key].services) {
              maxServices = dateBalances[key].services;
            }
          }
        }
      }
      console.log("Filtering services with count lower than", maxServices - 1);
      let usdHistory = Object.values(dateBalances).filter(
        (c) => c.services >= maxServices - 1
      );

      for (const data of usdHistory) {
        if (!maxBalance || data.usd > maxBalance.usd) {
          maxBalance = data;
        }

        if (!lowestBalance || data.usd < lowestBalance.usd) {
          lowestBalance = data;
        }
      }

      const payload = {
        usdHistory,
        lowestBalance,
        maxBalance,
      } as UsdBalanceHistoryAggregate;
      Cache.put(cacheKey, payload, Cache.Times.daily);
      return payload;
    },
  };

  render() {
    return (
      <AppData.Provider value={this.state}>
        {this.props.children}
      </AppData.Provider>
    );
  }
}
