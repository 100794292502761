import React from "react";
import { AppState, AppData, ServiceName } from "../../contexts/AppData";
import { RouteComponentProps } from "react-router";
import { BasicStyles } from "../../styles";
import MaterialTable from "material-table";
import { Util } from "../../services/Util";
import { TradingService } from "../../services/TradingService";
import { Loading } from "../../components/Loading";
import { SideNav } from "../../components/SideNav";
import { BottomNav } from "../../components/BottomNav";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { IntervalMap, IUserFunding } from "../../types";
type Props = AppState & RouteComponentProps<{}>;

const styles = {
  row: {
    ...BasicStyles.row,
    justifyContent: "space-between",
    maxWidth: "150px",
    margin: "auto",
  },
  card: {
    ...BasicStyles.primaryCard,
    minWidth: "150px",
  },
};

export class Allocated extends React.Component<Props, {}> {
  async componentDidMount() {
    const loggedIn = await TradingService.isLoggedIn();
    if (typeof loggedIn === "boolean") {
      this.props.history.push("/login");
    } else {
      this.props.setUser(loggedIn);
      await this.props.initializeAll();
      this.props.doneLoading();
    }
  }

  getMonthlySum() {
    let total = 0;
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.funding) {
        total += serviceData.funding.reduce(
          (sum, f) => sum + (IntervalMap.monthly / f.interval) * f.amount,
          0
        );
      }
    }
    return Util.toFixedNumber(total);
  }

  getServiceFundingAmount(service: string) {
    const serviceData = this.props.services[service];
    if (serviceData && serviceData.funding) {
      return serviceData.funding.reduce(
        (sum, f) => sum + (IntervalMap.monthly / f.interval) * f.amount,
        0
      );
    } else return 0;
  }

  getAllFunding() {
    let funding = new Array<IUserFunding>();
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.funding) {
        funding = funding.concat(serviceData.funding);
      }
    }
    return funding;
  }

  getServiceFundingData(service: string) {
    const monthlyFunds = this.getMonthlySum();
    const funds = this.getServiceFundingAmount(service);
    const percent = Util.toFixedNumber((funds / monthlyFunds) * 100 || 0);
    return {
      service,
      yearly: funds * 12,
      monthly: funds,
      weekly: funds / 4,
      percent,
    };
  }

  getAllProductFundingData() {
    const monthlyFunds = this.getMonthlySum();
    return this.getAllFunding().map((f) => {
      const monthly = (IntervalMap.monthly / f.interval) * f.amount;
      const percent = Util.toFixedNumber((monthly / monthlyFunds) * 100 || 0);
      return {
        funding: f,
        service: f.service,
        product: Util.getCurrencyFromPair(f.purchaseSymbol),
        monthly,
        yearly: monthly * 12,
        weekly: monthly / 4,
        percent,
      };
    });
  }

  getAllServiceFundingData() {
    const monthly = this.getMonthlySum();
    return Object.keys(this.props.services)
      .map((s) => this.getServiceFundingData(s))
      .concat([
        {
          service: "TOTAL",
          monthly,
          yearly: monthly * 12,
          weekly: monthly / 4,
          percent: 100,
        },
      ]);
  }

  getServiceFundingTable() {
    const serviceData = this.getAllServiceFundingData();
    return (
      <div style={BasicStyles.primaryTableFull}>
        <MaterialTable
          isLoading={this.props.loading}
          columns={[
            {
              title: "Service",
              field: "service" as "service",
              type: "string" as "string",
            },
            {
              title: "Weekly",
              field: "weekly" as "weekly",
              type: "currency",
            },
            {
              title: "Monthly",
              field: "monthly" as "monthly",
              type: "currency",
            },
            {
              title: "Yearly",
              field: "yearly" as "yearly",
              type: "currency",
            },
            {
              title: "%",
              field: "percent" as "percent",
              type: "numeric",
            },
          ]}
          data={serviceData}
          totalCount={serviceData.length}
          options={{ pageSize: serviceData.length }}
          title="Investing Schedule"
        />
      </div>
    );
  }

  getProductFundingTable() {
    const serviceData = this.getAllProductFundingData();
    return (
      <div style={BasicStyles.primaryTableFull}>
        <MaterialTable
          isLoading={this.props.loading}
          columns={[
            {
              title: "Symbol",
              field: "product" as "product",
              type: "string" as "string",
              editable: "never",
            },
            {
              title: "Service",
              field: "service" as "service",
              type: "string" as "string",
              editable: "never",
            },
            {
              title: "Weekly",
              field: "weekly" as "weekly",
              type: "currency",
              editable: window.innerWidth > 750 ? undefined : "never",
            },
            {
              title: "Monthly",
              field: "monthly" as "monthly",
              type: "currency",
              editable: "never",
            },
            {
              title: "Yearly",
              field: "yearly" as "yearly",
              type: "currency",
              editable: "never",
            },
            {
              title: "%",
              field: "percent" as "percent",
              type: "numeric",
              editable: "never",
            },
          ]}
          data={serviceData}
          totalCount={serviceData.length}
          options={{ pageSize: serviceData.length }}
          title="Scheduled Purchases"
          cellEditable={{
            onCellEditApproved: async (
              newValue,
              oldValue,
              rowData,
              columnDef
            ) => {
              const { funding } = rowData;
              funding.amount =
                (IntervalMap.weekly / rowData.funding.interval) * newValue;
              await TradingService.updateUserFunding(funding.service, funding);
              this.props.updateFunding(funding.service as ServiceName, funding);
            },
          }}
        />
      </div>
    );
  }

  render() {
    const monthlyFunds = this.getMonthlySum();
    if (this.props.loading) {
      return <Loading />;
    }
    return (
      <div>
        <SideNav currentState={"home"} />
        {window.innerWidth <= 700 ? <img height={150} src="/logo.png" /> : null}
        <div style={BasicStyles.slightPadding}>
          <h2
            style={{
              fontSize: "48px",
              ...BasicStyles.moneyText,
            }}
          >
            You're planning to invest ${monthlyFunds} per month
          </h2>
          {this.getServiceFundingTable()}
          {this.getProductFundingTable()}
        </div>

        <BottomNav currentState="home" />
      </div>
    );
  }
}
export function AllocatedContainer(props: RouteComponentProps) {
  return (
    <AppData.Consumer>
      {(context) => <Allocated {...props} {...context} />}
    </AppData.Consumer>
  );
}
