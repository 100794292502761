import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { IUser, IUserUpdate } from "../types";
import { BasicStyles } from "../styles";

interface Props {
  user: IUser;
  onSubmit: (user: IUserUpdate) => Promise<void>;
}
export function UpdateUser(props: Props) {
  const { user } = props;
  const [email, setEmail] = React.useState(user.email || "");
  const [postTaxIncome, setPostTaxIncome] = React.useState(
    user.postTaxIncome || 0
  );
  const [password, setPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [phone, setPhone] = React.useState(user.phone || "");
  const [alerts, setAlerts] = React.useState(user.alerts || false);
  const [changingPassword, setChangingPassword] = React.useState(false);
  return (
    <Paper style={BasicStyles.primaryCardFull}>
      <TextField
        label="Email"
        margin="normal"
        value={email}
        type="text"
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="Password"
        margin="normal"
        value={password}
        type="password"
        onChange={(e) => setPassword(e.target.value)}
      />
      {changingPassword ? (
        <TextField
          label="New Password"
          margin="normal"
          value={newPassword}
          type="password"
          onChange={(e) => setNewPassword(e.target.value)}
        />
      ) : null}
      <TextField
        label="Post Tax Income"
        margin="normal"
        value={postTaxIncome}
        type="text"
        onChange={(e) => setPostTaxIncome(Number(e.target.value))}
      />

      <TextField
        label="Phone"
        margin="normal"
        value={phone}
        type="text"
        onChange={(e) => setPhone(e.target.value)}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={alerts}
            onChange={(e) => setAlerts(!!!e.target.valueAsNumber)}
            value="checkedB"
            color="primary"
          />
        }
        label="Receive Text Alerts"
      />
      <Button
        style={BasicStyles.slightMargin}
        disabled={changingPassword}
        variant="contained"
        color="secondary"
        onClick={(e) => setChangingPassword(true)}
      >
        Change Password
      </Button>

      <Button
        style={BasicStyles.slightMargin}
        variant="contained"
        color="primary"
        disabled={!password}
        onClick={(e) =>
          props.onSubmit({
            alerts,
            email,
            newPassword,
            password,
            phone,
            postTaxIncome
          })
        }
      >
        Save Changes
      </Button>
    </Paper>
  );
}
