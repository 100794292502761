import React from "react";
import VpnKey from "@material-ui/icons/VpnKey";
import Build from "@material-ui/icons/Build";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import { TradingService } from "../../../services/TradingService";
import { Intervals } from "../../../types";
import { BasicStyles } from "../../../styles";
import { Balance } from "../../../components/Balance";
import { Prices } from "../../../components/Prices";
import { Util } from "../../../services/Util";
import { TradeList } from "../../../components/TradeList";
import { Loading } from "../../../components/Loading";
import { Props, BaseContainer, State } from "../base/BaseContainer";
import { SideNav } from "../../../components/SideNav";
import { BottomNav } from "../../../components/BottomNav";
import { Emoji } from "../../../services/Emoji";
import { Link } from "react-router-dom";
import { Membership } from "../../../constants";
import { DividendsHistoryChart } from "../../../components/DividendsHistory";
import MaterialTable from "material-table";

const styles = {
  input: {
    minWidth: "100px",
    marginRight: "15px",
  },
  roundIcon: {
    borderRadius: "50px",
    margin: "25px",
  },
};

export class CelciusContainer extends BaseContainer {
  state: State & {
    passphrase: string;
    apiKey: string;
    accessKey: string;
    help: boolean;
  } = {
    ...this.state,
    help: false,
    passphrase: "",
    apiKey: "",
    accessKey: "",
    service: "celcius",
  };
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setFakeSecret();
    await super.initialize();
  }

  setPassphrase(passphrase: string) {
    this.setState({ passphrase });
  }

  setApiKey(apiKey: string) {
    this.setState({ apiKey });
  }

  async saveSecret() {
    const { apiKey } = this.state;
    const secret = { apiKey };
    this.setState({ requestStarted: true });
    try {
      await TradingService.saveSecret("celcius", secret);
      window.location.reload();
    } catch (e) {}
    this.setState({ requestStarted: false });
  }

  clearSecret() {
    this.setState({
      passphrase: "",
      apiKey: "",
      accessKey: "",
      needsSecret: true,
    });
  }

  setFakeSecret() {
    this.setState({
      passphrase: "",
      apiKey: "",
      accessKey: "",
    });
  }

  setServiceSecretComponent() {
    const ActionButton = this.state.needsSecret ? (
      <Button
        variant="contained"
        color="primary"
        onClick={() => this.saveSecret()}
      >
        Save
      </Button>
    ) : (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => this.clearSecret()}
      >
        Reset
      </Button>
    );

    const step = !this.state.apiKey
      ? "/demos/celcius-step1.jpeg"
      : "/demos/celcius-step2.jpeg";
    return this.state.needsSecret ? (
      <Paper style={{ ...BasicStyles.column, ...BasicStyles.primaryCardFull }}>
        <h1>Enter your Celcius Api Key</h1>
        {this.state.help && step ? (
          <div>
            <img src="/demos/celcius-step1.png" style={{ maxWidth: "100%" }} />
            <img src="/demos/celcius-step2.png" style={{ maxWidth: "100%" }} />
          </div>
        ) : null}
        <div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              this.setState({ help: !this.state.help });
            }}
          >
            {this.state.help ? "Hide" : "Show"} Help
          </Button>
        </div>
        <TextField
          id="celcius-api-key"
          data-lpignore="true"
          label="api key"
          type="password"
          value={this.state.apiKey}
          disabled={!this.state.needsSecret}
          onChange={(e) => this.setApiKey(e.target.value)}
          margin="normal"
        />
        {ActionButton}
      </Paper>
    ) : (
      this.apiCredentialsFilled()
    );
  }

  apiCredentialsFilled() {
    return (
      <Paper style={BasicStyles.primaryCard}>
        <div>
          <VpnKey fontSize="large" />
        </div>
        <p>Credentials stored!</p>
        <Button onClick={() => this.clearSecret()}>Click here to reset</Button>
      </Paper>
    );
  }

  getDividends() {
    const serviceData = this.context.services[this.state.service];
    return this.context.services[this.state.service].dividends.map((d) => {
      const rate = serviceData.serviceRates.rates[d.productId];
      return {
        ...d,
        amount: d.amount * rate,
      };
    });
  }

  getAllDividends() {
    const dividends = {} as {
      [productId: string]: {
        amount: number;
        amountUsd: number;
        productId: string;
        balance: number;
        usdAmount: number;
        percent: number;
      };
    };
    const serviceData = this.context.services[this.state.service];
    if (serviceData && serviceData.dividends) {
      const balances = serviceData.balances;
      const rates = serviceData.serviceRates.rates;
      for (const dividend of serviceData.dividends) {
        const { productId, amount } = dividend;
        const balance = balances[productId]
          ? Util.toFixedNumber(balances[productId].unlocked, 5)
          : 0;
        const rate = rates[productId] ? rates[productId] : 0;
        const usdAmount = Util.toFixedNumber(balance * rate);

        if (!dividends[productId]) {
          dividends[productId] = {
            productId: productId,
            balance,
            usdAmount,
            amount: 0,
            amountUsd: 0,
            percent: 0,
          };
        }
        dividends[productId].amount += dividend.amount;
        dividends[productId].amount = Util.toFixedNumber(
          dividends[productId].amount,
          5
        );
        dividends[productId].amountUsd = Util.toFixedNumber(
          dividends[productId].amount * rate,
          2
        );
        dividends[productId].percent = balance
          ? Util.toFixedNumber((dividends[productId].amount / balance) * 100)
          : 0;
      }
    }
    return Object.values(dividends).sort((a, b) => b.usdAmount - a.usdAmount);
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    const totalGain = this.getTotalGain();
    const enableTradingText =
      this.state.bot && this.state.bot.enabled ? "Disable" : "Enable";
    const dividends = this.getDividends();

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.requestStarted}
          autoHideDuration={6000}
        >
          <SnackbarContent
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={"success"}>
                <LinearProgress />
                <div>Loading your data...</div>
              </span>
            }
          />
        </Snackbar>

        <SideNav currentState={"celcius"} />

        <a href="/dashboard">
          <img style={styles.roundIcon} height="100" src="celcius-icon.png" />
        </a>
        {this.state.configure || this.state.needsSecret ? (
          <div>
            <div>{this.setServiceSecretComponent()}</div>
            {!this.state.needsSecret ? (
              <div>
                <Button
                  onClick={() => this.setBotState(enableTradingText)}
                  color="primary"
                  variant="contained"
                >
                  {enableTradingText} Trading
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <div>
              <Balance
                balance={this.state.totalBalance}
                unlocked={0}
                locked={0}
                holdings={this.holdings()}
                gain={totalGain}
                funding={this.state.funding}
              />
            </div>
            <div
              style={{
                ...BasicStyles.primaryCard,
                minWidth: "400px",
                marginTop: "30px",
                marginBottom: "0px",
              }}
            >
              <DividendsHistoryChart dividends={dividends} />
            </div>
            <div style={BasicStyles.primaryCard}>
              <h2>Reward Summary ({this.getAllDividends().length})</h2>
              <MaterialTable
                isLoading={this.state.loading}
                columns={[
                  {
                    title: "Symbol",
                    field: "productId" as "productId",
                    type: "string" as "string",
                  },
                  {
                    title: "Shares",
                    field: "balance" as "balance",
                    type: "numeric",
                  },
                  {
                    title: "USD Value",
                    field: "usdAmount" as "usdAmount",
                    type: "numeric",
                  },
                  {
                    title: "Shares Gained",
                    field: "amount" as "amount",
                    type: "numeric",
                  },
                  {
                    title: "USD Gained",
                    field: "amountUsd" as "amountUsd",
                    type: "numeric",
                  },
                ]}
                data={this.getAllDividends()}
                title="Dividends"
              />
            </div>
            <IconButton onClick={() => this.setState({ configure: true })}>
              <Build fontSize="large" />
            </IconButton>
          </div>
        )}
        <BottomNav currentState="celcius" />
      </div>
    );
  }
}
