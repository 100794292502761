import React from "react";
import Paper from "@material-ui/core/Paper";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import {
  GainType,
  IUserFunding,
  Candle,
  CurrencyBalances,
  UsdBalanceHistoryAggregate,
  IntervalMap,
} from "../types";
import { BasicStyles } from "../styles";
import { Util } from "../services/Util";
import {
  PieChart,
  Pie,
  Cell,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  AreaChart,
  Area,
} from "recharts";
import randomColor from "randomcolor";
import { HalfPie } from "./BalancePieCharts/HalfPie";
import { Cache } from "../services/Cache";

export function HistoricalBalance(props: {
  totalCashValue: number;
  balances: CurrencyBalances;
  holdings: { [date: string]: CurrencyBalances };
  candles: { [currency: string]: Array<Candle> };
  usdBalanceAgg: UsdBalanceHistoryAggregate;
  monthlyGrowth: number;
  pastGrowth: number;
}) {
  const { holdings, candles, usdBalanceAgg, monthlyGrowth, pastGrowth } = props;
  const holdingsLength = (props.holdings && props.holdings.length) || 0;
  const { usdHistory: chartData, lowestBalance, maxBalance } = usdBalanceAgg;
  const styles = {
    row: {
      display: "flex",
      justifyContent: "space-between",
    },
  };

  const firstChartData = chartData[0] && chartData[0].date;
  const firstDate = new Date(firstChartData);
  const firstChartPoint = chartData[0] || { usd: 0, date: new Date() };
  const initialValue = firstChartPoint.usd;
  const lastChartPoint = chartData[chartData.length - 1] || {usd: 0, date: new Date()};
  const timespan =
    (new Date(lastChartPoint.date).getTime() -
      new Date(firstChartPoint.date).getTime()) /
    IntervalMap.monthly;

  const shortTermGrowth =
    (lastChartPoint.usd - firstChartPoint.usd) / firstChartPoint.usd / timespan;

  const typicalMonthlyGrowth = 0.08 / 12;
  const yearlyGrowth = (Math.pow(1 + pastGrowth, 12) - 1) || 0;
  const typicalYearlyGrowth = Math.pow(1 + typicalMonthlyGrowth, 12) - 1;
  const upString = `Past Growth (+${Util.toFixedNumber(
    yearlyGrowth * 100,
    0
  )}%)`;
  const typicalString = `Typical Growth (+${Util.toFixedNumber(
    typicalYearlyGrowth * 100,
    0
  )}%)`;

  const data = chartData.map((v, i) => {
    const date = new Date(v.date);
    const numMonths =
      (date.getTime() - firstDate.getTime()) / IntervalMap.monthly;

    const balance = Util.toFixedNumber(
      initialValue + numMonths * monthlyGrowth
    );
    return {
      balance,
      [upString]: Util.toFixedNumber(
        balance * (1 + shortTermGrowth * numMonths)
      ),
      [typicalString]: Util.toFixedNumber(
        balance * (1 + typicalMonthlyGrowth * numMonths)
      ),

      Balance: v.usd,
      date: date.toDateString(),
    };
  });
  const projectionFirstDate = new Date(lastChartPoint.date);
  projectionFirstDate.setDate(projectionFirstDate.getDate() + 30);

  const projection = new Array(12).fill(0).map((v, i) => {
    const start = new Date(firstChartPoint.date);
    const date = new Date(lastChartPoint.date);
    date.setDate(date.getDate() + 30 * (i + 1));
    const timespan = i + 1;

    const balance = Util.toFixedNumber(
      lastChartPoint.usd + timespan * monthlyGrowth
    );

    return {
      balance,
      [upString]: Util.toFixedNumber(
        balance * Math.pow(1 + pastGrowth, timespan) - 1
      ),
      [typicalString]: Util.toFixedNumber(
        balance * Math.pow(1 + typicalMonthlyGrowth, timespan) - 1
      ),

      Balance: balance,
      date: date.toDateString(),
    };
  });

  return (
    <ResponsiveContainer height="75%">
      <AreaChart
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        data={data.concat(projection)}
      >
        <XAxis dataKey="date" />
        <Tooltip />
        <Legend />
        <Area stackId="1" dataKey="Balance" stroke="blue" dot={false} />
        {lowestBalance && (
          <ReferenceDot
            label={
              "$" + Util.toFixedNumber(lowestBalance.usd, 0).toLocaleString()
            }
            x={lowestBalance.date}
            y={lowestBalance.usd}
            fill="#f44336"
            r={2}
          />
        )}

        {maxBalance && (
          <ReferenceDot
            label={"$" + Util.toFixedNumber(maxBalance.usd, 0).toLocaleString()}
            x={maxBalance.date}
            y={maxBalance.usd}
            fill="#66bb6a"
            r={2}
          />
        )}
        <Area
          dot={false}
          stackId="1"
          type="monotone"
          dataKey={upString}
          fill="#82ca9d"
          stroke="#82ca9d"
        />
        <ReferenceLine
          x={projectionFirstDate.toDateString()}
          label={
            {
              position: "center",
              value: "Future",
            } as any
          }
          strokeDasharray="5 5"
          stroke="gray"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
