export const KrakenTradingPairs = [
  { name: "BCH/USD" },
  { name: "COMP/USD" },
  { name: "DAI/USD" },
  { name: "DASH/USD" },
  { name: "DOT/USD" },
  { name: "EOS/USD" },
  { name: "GNO/USD" },
  { name: "ICX/USD" },
  { name: "KAVA/USD" },
  { name: "KNC/USD" },
  { name: "LINK/USD" },
  { name: "LSK/USD" },
  { name: "NANO/USD" },
  { name: "OMG/USD" },
  { name: "OXT/USD" },
  { name: "PAXG/USD" },
  { name: "QTUM/USD" },
  { name: "REPV2/USD" },
  { name: "SC/USD" },
  { name: "STORJ/USD" },
  { name: "TRX/USD" },
  { name: "USDC/USD" },
  { name: "WAVES/USD" },
  { name: "XDG/USD" },
  { name: "ETC/USD" },
  { name: "ETH/USD" },
  { name: "LTC/USD" },
  { name: "MLN/USD" },
  { name: "REP/USD" },
  { name: "XTZ/USD" },
  { name: "XBT/USD" },
  { name: "XLM/USD" },
  { name: "XMR/USD" },
  { name: "XRP/USD" },
  { name: "ZEC/USD" },
  { name: "EUR/USD" },
  { name: "GBP/USD" },
  { name: "CRV/USD" },
];
