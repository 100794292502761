import { ServiceRates } from "../types";
import { Bot } from "../types";
import {
  IUserFunding,
  IUserTrade,
  Candle,
  IUserUpdate,
  IUser,
  BotState,
  IUserNotification,
  CurrencyBalances,
  IFilledUserTrade,
  ProductGains,
  IUserFund,
  CurrencyCandles,
} from "../types";
import { Cache } from "./Cache";

const backendUrl = process.env.REACT_APP_BACKEND || "http://localhost:8000";
export class TradingService {
  static jwt: string = "";
  static user?: IUser;

  static parseResp(res: Response) {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  }

  static getToken() {
    if (!this.jwt) {
      this.jwt = window.sessionStorage.getItem("jwt") || "";
    }
    return this.jwt;
  }

  static setToken(token: string) {
    Cache.clear();
    window.sessionStorage.setItem("jwt", token);
    this.jwt = token || "";
  }

  static setUser(user: IUser) {
    window.sessionStorage.setItem("user", JSON.stringify(user));
    this.user = user;
  }

  static getUser() {
    if (!this.user) {
      const userStr = window.sessionStorage.getItem("user");
      this.user = userStr ? JSON.parse(userStr) : undefined;
    }
    return this.user;
  }

  static logOut() {
    window.sessionStorage.removeItem("jwt");
    Cache.clear();
  }

  static async isLoggedIn(): Promise<IUser | boolean> {
    try {
      const resp = window
        .fetch(backendUrl + "/user/loggedin", {
          method: "GET",
          headers: {
            Authorization: "Bearer " + this.getToken(),
          },
        })
        .then(this.parseResp);
      const user = (await resp) as IUser;
      this.setUser(user);
      return user;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  static async notifications(): Promise<IUserNotification[]> {
    const resp = window
      .fetch(backendUrl + "/user/notifications", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    const notifications = (await resp) as Promise<Array<IUserNotification>>;
    return notifications;
  }

  static async clearNotifications(): Promise<{ succes: boolean }> {
    const resp = window
      .fetch(backendUrl + "/user/notifications/view", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    const notifications = (await resp) as Promise<{ succes: boolean }>;
    return notifications;
  }

  static async updateUser(updates: IUserUpdate) {
    const resp = window
      .fetch(backendUrl + `/user/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
        body: JSON.stringify(updates),
      })
      .then(this.parseResp);
    return resp as Promise<any>;
  }

  static async upgradeToPremium(stripeSession: string) {
    const resp = window
      .fetch(backendUrl + `/user/premium`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
        body: JSON.stringify({ stripeSession }),
      })
      .then(this.parseResp);
    return resp as Promise<any>;
  }

  static async login(email: string, password: string) {
    const resp = window
      .fetch(backendUrl + "/user/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      })
      .then(this.parseResp);
    return resp as Promise<{ jwt: string }>;
  }

  static async getTwoFactorChallenge(
    service: string,
    username: string,
    password: string
  ) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/2fa`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
        body: JSON.stringify({ username, password }),
      })
      .then(this.parseResp);
    return resp as Promise<{ challenge: string }>;
  }

  static async answerTwoFactor(
    service: string,
    username: string,
    password: string,
    twoFactorChallenge: string,
    twoFactorCode: string
  ) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/2fa-response`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
        body: JSON.stringify({
          username,
          password,
          twoFactorCode,
          twoFactorChallenge,
        }),
      })
      .then(this.parseResp);
    return resp as Promise<{ success: boolean }>;
  }

  static async register(email: string, password: string) {
    const resp = window
      .fetch(backendUrl + "/user/register", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      })
      .then(this.parseResp);
    return resp as Promise<{ created: boolean }>;
  }

  static async passwordReset(email: string) {
    const resp = window
      .fetch(backendUrl + "/user/passwordreset", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      })
      .then(this.parseResp);
    return resp as Promise<{ created: boolean }>;
  }

  static async submitPasswordResetSecret(
    email: string,
    secret: string,
    newPassword: string
  ) {
    const resp = window
      .fetch(backendUrl + `/user/passwordreset/${secret}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, newPassword }),
      })
      .then(this.parseResp);
    return resp as Promise<{ created: boolean }>;
  }

  static async saveSecret(service: string, secret: any) {
    const resp = window
      .fetch(backendUrl + `/secret/${service}`, {
        method: "POST",
        body: JSON.stringify(secret),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
  }

  static async getSecret(service: string) {
    const resp = window
      .fetch(backendUrl + `/secret/${service}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<{
      service: string;
      encryptedSecrets: string;
    }>;
  }

  static async getUserFunding(service: string, fundingId: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/funding/${fundingId}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<IUserFunding>;
  }

  static async getAllUserFunds() {
    const resp = window
      .fetch(backendUrl + `/funds`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<Array<IUserFund>>;
  }

  static async updateUserFund(fund: Partial<IUserFund>) {
    const resp = window
      .fetch(backendUrl + `/funds`, {
        method: "POST",
        body: JSON.stringify(fund),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<any>;
  }

  static async getAllUserFunding(service: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/funding`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<Array<IUserFunding>>;
  }

  static async updateUserFunding(
    service: string,
    funding: Partial<IUserFunding>
  ) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/funding`, {
        method: "POST",
        body: JSON.stringify(funding),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<any>;
  }

  static async deleteUserFunding(
    service: string,
    funding: Partial<IUserFunding>
  ) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/funding`, {
        method: "DELETE",
        body: JSON.stringify(funding),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<any>;
  }

  static async getBalanceForService(service: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/balance`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<{
      [currency: string]: { locked: number; unlocked: number };
    }>;
  }

  static async getBalanceHistoryForService(service: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/balance/history`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<{ [date: string]: CurrencyBalances }>;
  }

  static async getRatesForService(service: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/rates`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<{
      currency: string;
      rates: { [currency: string]: number };
    }>;
  }

  static async getRatesForServicePair(service: string, pair: string) {
    pair = pair.replace("/", "-");
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/rates/${pair}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<ServiceRates>;
  }

  static async getDividends(service: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/dividends`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<
      Array<{
        productId: string;
        amount: number;
        date: string;
      }>
    >;
  }

  static async getAllCandles(service: string, days?: number) {
    const daysQs = days ? `?days=${days}` : "";
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/candles/${daysQs}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<CurrencyCandles>;
  }

  static async getCandles(service: string, pair: string, days?: number) {
    pair = pair.replace("/", "-");
    const daysQs = days ? `?days=${days}` : "";
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/candles/${pair}${daysQs}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<Array<Candle>>;
  }

  static async getTradingPairs(service: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/pairs`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<Array<string>>;
  }

  static async getUserTrade(service: string, tradeId: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/trade/${tradeId}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<IUserTrade>;
  }

  static async getInternalTradesForService(service: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<Array<IUserTrade>>;
  }

  static async getAllTradesForService(service: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/trades`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<{
      pending: Array<IUserTrade>;
      filled: Array<IFilledUserTrade>;
    }>;
  }

  static async approveUserTrade(trade: IUserTrade) {
    const resp = await window.fetch(
      backendUrl + `/exchange/${trade.service}/approve`,
      {
        method: "POST",
        body: JSON.stringify(trade),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      }
    );
    return resp.status === 200;
  }

  static async rejectUserTrade(trade: IUserTrade) {
    const resp = await window.fetch(
      backendUrl + `/exchange/${trade.service}/reject`,
      {
        method: "POST",
        body: JSON.stringify(trade),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      }
    );
    return resp.status === 200;
  }

  static async cancelTrade(trade: IUserTrade) {
    const resp = await window.fetch(
      backendUrl + `/exchange/${trade.service}/cancel`,
      {
        method: "POST",
        body: JSON.stringify(trade),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      }
    );
    return resp.status === 200;
  }

  static async getTradingBot(service: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/bot`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<any>;
  }

  static async enableTrading(service: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/enable`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<any>;
  }

  static async disableTrading(service: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/disable`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<any>;
  }

  static async getPotential(service: string) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/potential`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<ProductGains>;
  }

  static async backtest(
    service: string,
    amount: number,
    interval: number,
    symbol: string
  ) {
    const resp = window
      .fetch(backendUrl + `/exchange/${service}/backtest`, {
        method: "POST",
        body: JSON.stringify({ funding: { amount, interval }, symbol }),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getToken(),
        },
      })
      .then(this.parseResp);
    return resp as Promise<Bot>;
  }
}
