import React from "react";
import { AppState, AppData } from "../../contexts/AppData";
import { RouteComponentProps } from "react-router";
import { BasicStyles } from "../../styles";
import MaterialTable from "material-table";
import { Util } from "../../services/Util";
import { TradingService } from "../../services/TradingService";
import { Loading } from "../../components/Loading";
import { SideNav } from "../../components/SideNav";
import { BottomNav } from "../../components/BottomNav";
import { ProductGain } from "../../types";
import { Link } from "react-router-dom";
import { HasAssetDeposits } from "../../constants";
type Props = AppState & RouteComponentProps<{}>;

export class Performance extends React.Component<Props, {}> {
  async componentDidMount() {
    const loggedIn = await TradingService.isLoggedIn();
    if (typeof loggedIn === "boolean") {
      this.props.history.push("/login");
    } else {
      this.props.setUser(loggedIn);
      await this.props.initializeAll();
      this.getMergedPotentials();
      this.props.doneLoading();
    }
  }

  getMergedTotalInvested() {
    let total = 0;
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.allTrades.filled) {
        total += this.props.services[service].allTrades.filled.reduce(
          (sum, t) => {
            if (t.side === "sell") {
              return sum - t.amount * t.price;
            } else if (t.side === "buy") {
              return sum + t.amount * t.price;
            } else {
              return sum;
            }
          },
          0
        );
      }
    }
    return Util.toFixedNumber(total);
  }

  getAllCurrencyGains() {
    const allGains = new Array<{
      currency: string;
      service: string;
      gain: number;
      totalShares: number;
      totalSpent: number;
      avgPrice: number;
      gainPercent: number;
      currentPrice: number;
    }>();
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.balances) {
        for (const currency of Object.keys(serviceData.balances)) {
          const balance = serviceData.balances[currency];
          if (balance.locked + balance.unlocked === 0) {
            continue;
          }
          const currencyTrades = serviceData.allTrades.filled.filter(
            (t) =>
              Util.getCurrencyFromPair(t.productId) === currency &&
              t.side === "buy" &&
              t.status != "cancelled"
          );
          if (currencyTrades.length) {
            const gains = currencyTrades.map(
              (t) => (t.realized || 0) + (t.unrealized || 0)
            );

            const currencyGain = gains.reduce((sum, g) => sum + g, 0);

            const totalShares = Util.toFixedNumber(
              currencyTrades.reduce((sum, t) => {
                if (t.side == "buy") {
                  return sum + (t.amount - (t.realizedAmount || 0));
                }
                return sum;
              }, 0),
              4
            );

            const totalSpent = Util.toFixedNumber(
              currencyTrades.reduce((sum, t) => {
                if (t.side == "buy") {
                  return sum + (t.amount - (t.realizedAmount || 0)) * t.price;
                }
                return sum;
              }, 0)
            );
            const avgPrice = totalSpent / totalShares;
            const currentPrice = serviceData.serviceRates.rates[currency];
            const balanceUsd =
              serviceData.balances[currency].unlocked * currentPrice;
            const balanceGainUsd = Util.toFixedNumber(balanceUsd - totalSpent);
            const tradeGain = Util.toFixedNumber(currencyGain);
            const gain = HasAssetDeposits[service] ? tradeGain : balanceGainUsd;
            const gainPercent = Util.toFixedNumber((gain / totalSpent) * 100);
            allGains.push({
              currency,
              gain,
              service,
              totalShares,
              totalSpent,
              avgPrice,
              currentPrice,
              gainPercent,
            });
          }
        }
      }
    }
    return allGains.sort((g1, g2) => g2.gain - g1.gain);
  }

  getMergedPotentials() {
    let allPotentials = [] as Array<ProductGain>;
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.potential) {
        allPotentials = allPotentials.concat(
          Object.values(serviceData.potential)
        );
      }
    }
    const sorted = allPotentials.sort(
      (a, b) => b.yearly.percent - a.yearly.percent
    );
    const sum = sorted.reduce(
      (total, potential) => total + potential.yearly.percent,
      0
    );
    const count = sorted.length;
    const max = count > 0 ? sorted[0].yearly.percent : 0;
    const min = count > 0 ? sorted[count - 1].yearly.percent : 0;

    for (const s of sorted) {
      console.log(
        s.productId,
        s.yearly.percent,
        (s.yearly.percent - min) / (max - min)
      );
    }

    return sorted;
  }

  render() {
    if (this.props.loading) {
      return <Loading />;
    }

    const gains = this.getAllCurrencyGains();
    const totalInvested = this.getMergedTotalInvested();
    return (
      <div>
        <SideNav currentState={"home"} />
        {window.innerWidth <= 700 ? <img height={150} src="/logo.png" /> : null}
        <h2
          style={{
            fontSize: "48px",
            ...BasicStyles.moneyText,
          }}
        >
          Invested: ${totalInvested.toLocaleString()}
        </h2>
        <div style={BasicStyles.primaryTableFull}>
          <MaterialTable
            isLoading={this.props.loading}
            columns={[
              {
                title: "Symbol",
                field: "currency" as "currency",
                type: "string" as "string",
                render: (data) => (
                  <Link to={`/${data.service}/holding/${data.currency}`}>
                    {data.currency}
                  </Link>
                ),
              },
              {
                title: "Service",
                field: "service" as "service",
                type: "string" as "string",
              },
              {
                title: "Shares",
                field: "totalShares" as "totalShares",
                type: "numeric",
              },
              {
                title: "Invested",
                field: "totalSpent" as "totalSpent",
                type: "currency",
              },
              {
                title: "Avg. Price",
                field: "avgPrice" as "avgPrice",
                type: "currency",
              },
              {
                title: "Current Price",
                field: "currentPrice" as "currentPrice",
                type: "currency",
              },
              {
                title: "Gain",
                field: "gain" as "gain",
                type: "currency",
              },
              {
                title: "%",
                field: "gainPercent" as "gainPercent",
                type: "numeric",
              },
            ]}
            data={gains}
            totalCount={gains.length}
            options={{ pageSize: gains.length }}
            title="Robo-Trades Performance"
          />
        </div>
        <BottomNav currentState="home" />
      </div>
    );
  }
}
export function PerformanceContainer(props: RouteComponentProps) {
  return (
    <AppData.Consumer>
      {(context) => <Performance {...props} {...context} />}
    </AppData.Consumer>
  );
}
