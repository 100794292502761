import React from "react";
import {
  AreaChart,
  Area,
  Legend,
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid
} from "recharts";
import { Switch, FormControlLabel } from "@material-ui/core";
export function CapitalGainsLineChart(props: {
  gains: Array<{
    date: string;
    longterm: number;
    longtermBalance: number;
    shortterm: number;
    shorttermBalance: number;
  }>;
}) {
  const [showBalances, setShowBalances] = React.useState(false);
  if (!props.gains) {
    return <div />;
  }

  return (
    <div>
      <AreaChart
        width={window.innerWidth > 700 ? 400 : 350}
        height={300}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5
        }}
        data={props.gains}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis domain={["0", "dataMax"]} />
        <Tooltip />
        <Legend />
        <Area
          dataKey={showBalances ? "longtermBalance" : "longterm"}
          stroke="#5ab300"
          fill="#5ab300"
        />
        <Area
          dataKey={showBalances ? "shorttermBalance" : "shortterm"}
          stroke="#0000b3"
          fill="#0000b3"
        />
      </AreaChart>
      <FormControlLabel
        control={
          <Switch
            checked={showBalances}
            onChange={() => setShowBalances(!showBalances)}
          />
        }
        label={showBalances ? "Show Gains" : "Show Balances"}
      />
    </div>
  );
}
