import React from "react";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import { GainType, IUserFunding } from "../types";
import { BasicStyles } from "../styles";
import { Util } from "../services/Util";
import { PieChart, Pie, Cell } from "recharts";
import randomColor from "randomcolor";
import { HalfPie } from "./BalancePieCharts/HalfPie";

export function Balance(props: {
  balance: number;
  unlocked: number;
  locked: number;
  gain: number;
  holdings?: Array<{ currency: string; unlocked: number; usd: number }>;
  funding: Array<IUserFunding>;
}) {
  const { gain } = props;
  const { gainStyle, gainWord } = Util.getGainType(gain);
  const holdingsLength = (props.holdings && props.holdings.length) || 0;
  const stash = props.funding.reduce((sum, f) => sum + f.rolloverBalance, 0);
  const styles = {
    row: {
      display: "flex",
      justifyContent: "space-between"
    }
  };

  return (
    <Paper style={BasicStyles.primaryCard}>
      <h1
        style={{
          color: "#66bb6a",
          fontWeight: 200,
          fontSize: "64px",
          marginBottom: "15px"
        }}
      >
        ${Util.toFixedNumber(props.balance).toLocaleString()}
      </h1>
      <div
        style={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "165px",
          fontWeight: 200
        }}
      >
        {gain ? (
          <span style={styles.row}>
            <span>
              <span>{gainWord}: </span>{" "}
            </span>
            <span style={gainStyle}>${Math.abs(gain).toFixed(2)}</span>
          </span>
        ) : null}
        <Tooltip title="Cash available for trades" aria-label="add">
          <span style={styles.row}>
            <span>
              <span>Cash: </span>
            </span>
            <span>${props.unlocked.toFixed(2)}</span>
          </span>
        </Tooltip>
        <Tooltip title="Cash put aside for future trades" aria-label="add">
          <span style={styles.row}>
            <span>
              <span>Stashed: </span>
            </span>
            <span>${stash.toFixed(2)}</span>
          </span>
        </Tooltip>
        <Tooltip title="Value of pending trades" aria-label="add">
          <span style={styles.row}>
            <span>
              <span>Pending: </span>
            </span>
            <span>${props.locked.toFixed(2)}</span>
          </span>
        </Tooltip>
      </div>
    </Paper>
  );
}
