import React from "react";
import VpnKey from "@material-ui/icons/VpnKey";
import Build from "@material-ui/icons/Build";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import { TradingService } from "../../../services/TradingService";
import { Intervals } from "../../../types";
import { BasicStyles } from "../../../styles";
import { Balance } from "../../../components/Balance";
import { Prices } from "../../../components/Prices";
import { Util } from "../../../services/Util";
import { TradeList } from "../../../components/TradeList";
import { Loading } from "../../../components/Loading";
import { Props, BaseContainer, State } from "../base/BaseContainer";
import { SideNav } from "../../../components/SideNav";
import { BottomNav } from "../../../components/BottomNav";
import { Emoji } from "../../../services/Emoji";
import { Link } from "react-router-dom";
import { Membership } from "../../../constants";
import { KrakenTradingPairs } from "./Pairs";
import { KrakenClient } from "../../../services/Kraken";

const styles = {
  input: {
    minWidth: "100px",
    marginRight: "15px",
  },
  roundIcon: {
    borderRadius: "50px",
    margin: "25px",
  },
};

export class KrakenContainer extends BaseContainer {
  state: State & {
    privKey: string;
    apiKey: string;
    accessKey: string;
    help: boolean;
    tradingPairs: Array<{ name: string }>;
  } = {
    ...this.state,
    help: false,
    privKey: "",
    apiKey: "",
    accessKey: "",
    service: "kraken",
    tradingPairs: KrakenTradingPairs,
    createFunding: {
      ...this.state.createFunding,
      nextFunding: Date.now(),
      purchaseSymbol: "BTC-USD",
    },
  };
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setFakeSecret();
    await this.getTradingPairs();
    await super.initialize();
  }

  async getTradingPairs() {
    const pairs = await TradingService.getTradingPairs(this.state.service);
    const usdPairs = pairs.map((p) => ({ name: p }));
    this.setState({ tradingPairs: usdPairs });
    return usdPairs;
  }

  setPrivKey(privKey: string) {
    this.setState({ privKey });
  }

  setApiKey(apiKey: string) {
    this.setState({ apiKey });
  }

  async saveSecret() {
    const { apiKey, privKey } = this.state;
    const secret = { apiKey, privKey };
    this.setState({ requestStarted: true });
    try {
      await TradingService.saveSecret("kraken", secret);
      await this.setBotState("enable");
      window.location.reload();
    } catch (e) {}
    this.setState({ requestStarted: false });
  }

  clearSecret() {
    this.setState({
      apiKey: "",
      privKey: "",
      needsSecret: true,
    });
  }

  setFakeSecret() {
    this.setState({
      apiKey: "",
      privKey: "",
    });
  }

  setServiceSecretComponent() {
    const ActionButton = this.state.needsSecret ? (
      <Button
        variant="contained"
        color="primary"
        onClick={() => this.saveSecret()}
      >
        Save
      </Button>
    ) : (
      <Button
        variant="outlined"
        color="primary"
        onClick={() => this.clearSecret()}
      >
        Reset
      </Button>
    );

    return this.state.needsSecret ? (
      <Paper style={{ ...BasicStyles.column, ...BasicStyles.primaryCardFull }}>
        <h1>
          Enter your{" "}
          <a href="https://www.kraken.com/u/settings/api">Kraken Api Key</a>
        </h1>
        <div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              this.setState({ help: !this.state.help });
            }}
          >
            {this.state.help ? "Hide" : "Show"} Help
          </Button>
        </div>
        <TextField
          id="privkey"
          data-lpignore="true"
          label="Private Key"
          type="password"
          value={this.state.privKey}
          disabled={!this.state.needsSecret}
          onChange={(e) => this.setPrivKey(e.target.value)}
          margin="normal"
        />
        <TextField
          id="apiKey"
          data-lpignore="true"
          label="Api Key"
          type="password"
          value={this.state.apiKey}
          disabled={!this.state.needsSecret}
          onChange={(e) => this.setApiKey(e.target.value)}
          margin="normal"
        />
        {ActionButton}
      </Paper>
    ) : (
      this.apiCredentialsFilled()
    );
  }

  createFundingComponent() {
    const createAmount = this.state.createFunding.amount;
    const tooMuchFunding =
      this.state.funding.length >= Membership.Pro.limit[this.state.service];
    const freeLimit = Membership.Free.limit[this.state.service];
    const createDisabled =
      (createAmount != undefined && createAmount < 5) ||
      !createAmount ||
      tooMuchFunding;
    if (tooMuchFunding) {
      return (
        <Paper style={BasicStyles.primaryCardFull}>
          <h1>Pro Plan has a Max of 10 Investments</h1>
          <h2>Contact us for a custom plan</h2>
        </Paper>
      );
    }

    return (
      <Paper style={BasicStyles.primaryCardFull}>
        {this.isProUser() || this.state.funding.length < freeLimit ? (
          <div style={BasicStyles.rowOrColumn}>
            <TextField
              id="standard-name"
              label="$ Amount"
              margin="normal"
              value={createAmount}
              type="number"
              onChange={(e) => this.handleFundingAmountChange(e.target.value)}
            />
            <TextField
              label="How Often"
              style={styles.input}
              select
              value={this.state.createFunding.interval}
              margin="normal"
              onChange={(e) => this.handleFundingIntervalChange(e.target.value)}
            >
              {Intervals.map((i) => (
                <MenuItem value={i.value}>{i.name}</MenuItem>
              ))}
            </TextField>
            <Autocomplete
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="To purchase"
                  margin="normal"
                  style={styles.input}
                />
              )}
              getOptionLabel={(option) => Util.getCurrencyFromPair(option.name)}
              options={this.state.tradingPairs}
              onChange={(_, value) =>
                value && this.handleFundingPurchaseSymbolChange(value.name)
              }
            />
            <Button
              disabled={createDisabled}
              color="primary"
              onClick={() => this.addFunding()}
            >
              Invest
            </Button>
          </div>
        ) : (
          <div style={BasicStyles.autoMargin}>
            <h1>Free Tier allows for 1 Investing Plan</h1>
            <Link to={"/pro"}>
              <Button color="primary" variant="contained">
                {" "}
                Upgrade For More
              </Button>
            </Link>
          </div>
        )}
      </Paper>
    );
  }

  apiCredentialsFilled() {
    return (
      <Paper style={BasicStyles.primaryCard}>
        <div>
          <VpnKey fontSize="large" />
        </div>
        <p>Credentials stored!</p>
        <Button onClick={() => this.clearSecret()}>Click here to reset</Button>
      </Paper>
    );
  }

  fundingListComponent() {
    const empty = (
      <Paper style={BasicStyles.primaryCardFull}>
        No active funding yet...
      </Paper>
    );
    const funding = this.state.funding.map((f, i) => {
      const nextFundingStr = Util.toInputDate(new Date(f.nextFunding));
      return (
        <Paper style={BasicStyles.primaryCardFull}>
          <TextField
            id="standard-name"
            label="$ Amount"
            margin="normal"
            value={f.amount}
            type="number"
            onChange={(e) =>
              this.handleFundingUpdate("amount", i, Number(e.target.value))
            }
          />
          <TextField
            label="How Often"
            select
            value={f.interval}
            margin="normal"
            onChange={(e) =>
              this.handleFundingUpdate("interval", i, Number(e.target.value))
            }
          >
            {Intervals.map((i) => (
              <MenuItem value={i.value}>{i.name}</MenuItem>
            ))}
          </TextField>
          <Autocomplete
            renderInput={(params) => (
              <TextField
                {...params}
                label="To purchase"
                margin="normal"
                style={styles.input}
              />
            )}
            getOptionLabel={(option) => Util.getCurrencyFromPair(option.name)}
            options={this.state.tradingPairs}
            onChange={(_, value) =>
              value && this.handleFundingUpdate("purchaseSymbol", i, value.name)
            }
          />
          <TextField
            id="next-run"
            label="Next Run"
            margin="normal"
            value={nextFundingStr}
            type="datetime-local"
            onChange={(e) => {
              const newFunding = new Date(e.target.value + ".000Z");
              try {
                if (newFunding.toISOString()) {
                  this.handleFundingUpdate(
                    "nextFunding",
                    i,
                    newFunding.getTime()
                  );
                }
              } catch (e) {
                this.handleFundingUpdate(
                  "nextFunding",
                  i,
                  new Date().getTime()
                );
              }
            }}
          />

          <Button color="primary" onClick={() => this.updateFunding(f)}>
            Update Funding
          </Button>
          <Button color="secondary" onClick={() => this.removeFunding(f)}>
            Remove Funding
          </Button>
        </Paper>
      );
    });
    if (this.state.funding.length) {
      return funding;
    } else {
      return empty;
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    const totalGain = this.getTotalGain();
    const enableTradingText =
      this.state.bot && this.state.bot.enabled ? "Disable" : "Enable";

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={this.state.requestStarted}
          autoHideDuration={6000}
        >
          <SnackbarContent
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={"success"}>
                <LinearProgress />
                <div>Loading your data...</div>
              </span>
            }
          />
        </Snackbar>

        <SideNav currentState={"kraken"} />

        <a href="/dashboard">
          <img style={styles.roundIcon} height="100" src="kraken-icon.jpg" />
        </a>
        {this.state.configure || this.state.needsSecret ? (
          <div>
            <div>{this.setServiceSecretComponent()}</div>
            {!this.state.needsSecret ? (
              <div>
                <Button
                  onClick={() => this.setBotState(enableTradingText)}
                  color="primary"
                  variant="contained"
                >
                  {enableTradingText} Trading
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <div>
              <Balance
                balance={this.state.totalBalance}
                unlocked={this.state.balances.USD.unlocked}
                locked={this.state.balances.USD.locked}
                holdings={this.holdings()}
                gain={totalGain}
                funding={this.state.funding}
              />
            </div>
            <div>{this.createFundingComponent()}</div>
            <div>
              <Prices
                fundingLimit={this.getFundingLimit()}
                pro={this.isProUser()}
                pairs={this.getPairs()}
                candles={this.state.candles}
                pendingTrades={this.getPendingTrades()}
                suggestedTrades={this.getUnapprovedTrades()}
                onCancel={(t) => this.cancelTrade(t)}
                onReject={(t) => this.rejectTrade(t)}
                onApprove={(t) => this.approveTrade(t)}
                onUpdateFunding={(f) => this.updateFunding(f)}
                onRemoveFunding={(f) => this.removeFunding(f)}
                rates={this.state.serviceRates.rates}
                funding={this.state.funding}
              />
            </div>
            <div>
              <h2>Trade History ({this.getHistoricalTrades().length})</h2>
              <TradeList
                trades={this.getHistoricalTrades()}
                rates={this.state.serviceRates.rates}
              />
            </div>
            <IconButton onClick={() => this.setState({ configure: true })}>
              <Build fontSize="large" />
            </IconButton>
          </div>
        )}
        <BottomNav currentState="kraken" />
      </div>
    );
  }
}
