import React from "react";
import Button from "@material-ui/core/Button";
import { IUserTrade, Rates } from "../../types";
import { EmptyTrade } from "../EmptyTrade";
import { Util } from "../../services/Util";
import { BasicStyles } from "../../styles";
import MaterialTable from "material-table";
const styles = {
  order: {
    maxWidth: "150px",
    minWidth: "150px",
    padding: "8px",
    margin: "5px"
  },
  orders: {
    display: "flex",
    flexDirection: "row" as "row",
    flexWrap: "wrap" as "wrap",
    justifyContent: "space-between" as "space-between",
    maxWidth: "360px",
    margin: "0 auto",
    marginBottom: "25px"
  }
};

function getApproveTradeTable(props: {
  trades: Array<IUserTrade>;
  rates: Rates;
}) {
  return props.trades
    .map(t => {
      const side = t.side[0].toUpperCase() + t.side.slice(1);
      const currency = Util.getCurrencyFromPair(t.productId);
      const delta = (t.price - props.rates[currency]) || 0;
      return {
        ...t,
        side,
        currency,
        price: t.type === "market" ? "MKT" : t.price,
        delta,
        gain: Util.getGainPerTrade(t, props.rates),
        total: Util.toFixedNumber(t.amount * t.price),
        action: side + " " + currency
      };
    })
    .reverse();
}

function sortByUpdated(a: IUserTrade, b: IUserTrade) {
  return b.lastUpdated - a.lastUpdated;
}

function sortByCreated(a: IUserTrade, b: IUserTrade) {
  const aDate = parseInt(a._id.slice(0, 8), 16);
  const bDate = parseInt(b._id.slice(0, 8), 16);
  return bDate - aDate;
}

function getTradeTableData(props: { trades: Array<IUserTrade>; rates: Rates }) {
  return props.trades
    .map(t => {
      const side = t.side[0].toUpperCase() + t.side.slice(1);
      const currency = Util.getCurrencyFromPair(t.productId);
      const delta = (t.price - props.rates[currency]) || 0;
      return {
        ...t,
        side,
        currency,
        delta,
        gain: Util.getGainPerTrade(t, props.rates),
        total: Util.toFixedNumber(t.amount * t.price),
        action: side + " " + currency
      };
    })
    .sort(sortByCreated);
}

export function FilledTradeDetails(props: {
  trades: Array<IUserTrade>;
  rates: Rates;
}) {
  const data = getTradeTableData(props).sort(sortByUpdated);
  return (
    <div style={BasicStyles.primaryTable}>
      <MaterialTable
        columns={[
          { title: "Trade", field: "action" as "action" },
          {
            title: "Price",
            field: "price" as "price",
            type: "currency"
          },
          {
            title: "Total",
            field: "total" as "total",
            type: "currency"
          },
          {
            title: "Gain",
            field: "gain" as "gain",
            type: "currency"
          },
          {
            title: "Date",
            field: "lastUpdated" as "lastUpdated",
            type: "date",
            render: e => new Date(e.lastUpdated).toLocaleString()
          }
        ]}
        data={data}
        title=""
      />
    </div>
  );
}

export function PlacedTradeDetails(props: {
  trades: Array<IUserTrade>;
  rates: Rates;
  onCancel?: (trade: IUserTrade) => {};
}) {
  const data = getTradeTableData(props);
  return (
    <div style={BasicStyles.primaryTable}>
      <MaterialTable
        columns={[
          { title: "Trade", field: "action" as "action" },
          {
            title: "Price",
            field: "price" as "price",
            type: "currency"
          },
          {
            title: "Total",
            field: "total" as "total",
            type: "currency"
          },
          {
            title: "Price Gap",
            field: "delta" as "delta",
            type: "currency"
          },
          {
            title: "Date",
            field: "_id" as "_id",
            type: "date",
            render: e =>
              new Date(
                parseInt(e._id.substring(0, 8), 16) * 1000
              ).toLocaleString()
          }
        ]}
        data={data}
        title=""
        actions={[
          {
            icon: "cancel",
            tooltip: "Cancel Trade",
            onClick: (event, rowData) => {
              props.onCancel && props.onCancel(rowData as IUserTrade);
            }
          }
        ]}
        options={{
          actionsColumnIndex: -1
        }}
      />
    </div>
  );
}

export function SuggestedTradeDetails(props: {
  trades: Array<IUserTrade>;
  rates: Rates;
  onReject?: (trade: IUserTrade) => {};
  onApprove?: (trade: IUserTrade) => {};
}) {
  const data = getApproveTradeTable(props);
  return (
    <div style={BasicStyles.primaryTable}>
      <MaterialTable
        columns={[
          { title: "Trade", field: "action" as "action" },
          {
            title: "Price",
            field: "price" as "price",
            type: "string"
          },
          {
            title: "Total",
            field: "total" as "total",
            type: "currency"
          },
          {
            title: "Price Gap",
            field: "delta" as "delta",
            type: "currency"
          },
          {
            title: "Date",
            field: "_id" as "_id",
            type: "date",
            render: e =>
              new Date(
                parseInt(e._id.substring(0, 8), 16) * 1000
              ).toLocaleString()
          }
        ]}
        data={data}
        title=""
        actions={[
          {
            icon: "check",
            tooltip: "Approve Trade",
            onClick: (event, rowData) => {
              props.onApprove && props.onApprove(rowData as IUserTrade);
            }
          },
          {
            icon: "cancel",
            tooltip: "Reject Trade",
            onClick: (event, rowData) => {
              props.onReject && props.onReject(rowData as IUserTrade);
            }
          }
        ]}
      />
    </div>
  );
}

export function TradeList(props: {
  trades: Array<IUserTrade>;
  rates: Rates;
  onCancel?: (trade: IUserTrade) => {};
  onReject?: (trade: IUserTrade) => {};
  onApprove?: (trade: IUserTrade) => {};
}) {
  if (props.trades && props.trades.length) {
    const t = props.trades[0];
    switch (t.status) {
      case "filled":
        return <FilledTradeDetails {...props} />;
      case "placed":
        return <PlacedTradeDetails {...props} />;
      case undefined:
        return <SuggestedTradeDetails {...props} />;
      default:
        return <EmptyTrade />;
    }
  } else {
    return <EmptyTrade />;
  }
}
