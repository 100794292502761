import { IUserTrade, Rates, Candle, Intervals } from "../types";

export class Util {
  static toInputDate(date: Date) {
    try {
      const newDate = new Date(date).toISOString();
      const newDateStr = newDate.slice(0, newDate.lastIndexOf("."));
      return newDateStr;
    } catch (e) {
      const newDate = new Date().toISOString();
      const newDateStr = newDate.slice(0, newDate.lastIndexOf("."));
      return newDateStr;
    }
  }

  static getCurrencyFromPair(pair: string) {
    return pair.includes("-") ? pair.split("-")[0] : pair.split("/")[0];
  }

  static getPairFromCurrency(service: string, currency: string) {
    if (service === "coinbase" || service === "coinbase-advanced") {
      return currency + "-USD";
    } else {
      return currency;
    }
  }

  static toFixedNumber(value: number, decimals = 2) {
    const shifted = Math.floor(value * Math.pow(10, decimals));
    const unshifted = shifted / Math.pow(10, decimals);
    return Number(unshifted.toFixed(decimals));
  }

  static invert(value: number) {
    return 1 / Number(value);
  }

  static getGainType(totalGain: number) {
    const gainColor = totalGain > 0 ? "green" : "#f44336";
    const gainStyle = { color: gainColor };
    const gainWord = totalGain > 0 ? "Gain" : "Loss";
    return { gainColor, gainWord, gainStyle };
  }

  static getGainPerTrade(t: IUserTrade, rates: Rates) {
    const currency = t.productId.split("-")[0];
    const currentRate = Number(rates[currency]);
    const gain = Util.toFixedNumber(
      (currentRate - Number(t.price)) * Number(t.amount)
    );
    return gain;
  }

  static toCandle(row: Array<number>): Candle {
    const [time, low, high, open, close, volume] = row;
    return { time, low, high, open, close, volume };
  }

  static getIntervalName(time: number) {
    const interval = Intervals.find(i => i.value === time);
    return interval ? interval.name : null;
  }

  static wait(time: number) {
    return new Promise(r => setTimeout(r, time));
  }

  static getClosestDate(to: Date, arr: Array<string | number>) {
    const closestArr = arr.sort(h => new Date(h).getTime() - to.getTime());
    return closestArr[0];
  }
}
