import { IntervalMap } from "../types";
import { TradingService } from "./TradingService";
export class Cache {
  static Times = {
    hourly: 60 * 60 * 1000,
    daily: 24 * 60 * 60 * 1000,
    weekly: 7 * 24 * 60 * 60 * 1000,
    monthly: 4 * 7 * 24 * 60 * 60 * 1000,
    yearly: 12 * 4 * 7 * 24 * 60 * 60 * 1000,
  };

  static clear() {
    window.localStorage.clear();
  }

  static put(cacheKey: string, cacheValue: any, expire: number) {
    const cache = {
      created: Date.now(),
      cacheValue,
      expire,
    };
    console.log("Cache::Put", cacheKey);
    const user = TradingService.getUser();
    const userCacheKey = user ? user.email + "-" + cacheKey : cacheKey;
    window.localStorage.setItem(userCacheKey, JSON.stringify(cache));
  }

  static expire(cacheKey: string) {
    console.log("Cache::Expire", cacheKey);
    window.localStorage.removeItem(cacheKey);
  }

  static get(cacheKey: string) {
    const user = TradingService.getUser();
    const userCacheKey = user ? user.email + "-" + cacheKey : cacheKey;
    const cache = window.localStorage.getItem(userCacheKey);
    if (cache) {
      const cacheObj = JSON.parse(cache);
      if (cacheObj.created + cacheObj.expire > Date.now()) {
        console.log("Cache::Hit", cacheKey);
        return cacheObj.cacheValue;
      } else {
        console.log("Cache::Expired", cacheKey);
        return null;
      }
    }
    return null;
  }
}
