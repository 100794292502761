import * as React from "react";
import { IUserFund } from "../types";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { BasicStyles } from "../styles";

const styles = {
  summaryHeader: { width: "100%" },
  fontWeight: { fontWeight: 200 },
  label: { width: "100%", textAlign: "center" as "center" },
  nextRunDate: {
    padding: "7px",
    position: "absolute" as "absolute",
    left: "25px"
  },
  runButton: {
    marginBottom: "15px",
    padding: "10px"
  },
  chips: {
    height: "55px"
  }
};
export function FundListItem(props: { fund: IUserFund }) {
  const goalText = props.fund.goal
    ? `Goal: $${props.fund.goal.toFixed(2)}`
    : "";
  const benchmarkText = props.fund.benchmark
    ? `Benchmark ${props.fund.benchmark}%`
    : "";
  return (
    <ExpansionPanel
      key={props.fund.name}
      style={BasicStyles.primaryCard}
      defaultExpanded={false}
    >
      <ExpansionPanelSummary
        style={styles.summaryHeader}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div style={styles.label}>
          <h2 style={styles.fontWeight}>{props.fund.name}</h2>
          <div style={styles.fontWeight}>
            {goalText} {benchmarkText}
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        style={{ ...BasicStyles.primaryCard, maxWidth: "95vw" }}
      >
        Hello
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
