import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  Legend,
  Line,
  ReferenceLine
} from "recharts";
import React from "react";
import Paper from "@material-ui/core/Paper";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Badge from "@material-ui/core/Badge";
import Chip from "@material-ui/core/Chip";
import Tabs from "@material-ui/core/Tabs";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Sync from "@material-ui/icons/Sync";

import { BasicStyles } from "../../styles";
import {
  Candle,
  IUserTrade,
  Rates,
  IUserFunding,
  IntervalMap,
  Intervals,
  MongoBound
} from "../../types";
import { TradeList } from "../TradeList";
import { Util } from "../../services/Util";
import { EditFunding } from "../EditFunding";
import "./prices.css";

function normalizeTime(time: number) {
  if (time.toString().length > 11) {
    return time;
  } else {
    return time * 1000;
  }
}

function daysAgo(time: number) {}

const PENDING_COLOR = "#30f2cf";
const SUGGESTED_COLOR = "#f5a700";
const styles = {
  summaryHeader: { width: "100%" },
  fontWeight: { fontWeight: 200 },
  label: { width: "100%", textAlign: "center" as "center" },
  nextRunDate: {
    padding: "7px",
    position: "absolute" as "absolute",
    left: "25px"
  },
  runButton: {
    marginBottom: "15px",
    padding: "10px"
  },
  chips: {
    height: "55px"
  },
  pendingChip: {
    position: "absolute" as "absolute",
    right: "25px",
    backgroundColor: PENDING_COLOR
  },
  suggestedChip: {
    position: "absolute" as "absolute",
    right: "125px",
    backgroundColor: SUGGESTED_COLOR
  }
};

function dateDistanceString(timestamp: number) {
  const now = Date.now();
  const diff = timestamp - now;
  const abs = Math.abs(diff);
  const past = diff < 0;
  let description = "";

  const moreThanSecond = abs >= IntervalMap.hourly / 60 / 60;
  const moreThanMinute = abs >= IntervalMap.hourly / 60;
  const moreThanHour = abs >= IntervalMap.hourly;
  const moreThanDay = abs >= IntervalMap.daily;
  const moreThanWeek = abs >= IntervalMap.weekly;
  const moreThanMonth = abs >= IntervalMap.monthly;

  if (moreThanMonth) {
    const months = Math.floor(abs / IntervalMap.monthly);
    return months + " Month" + (months > 1 ? "s" : "") + (past ? " ago" : "");
  }

  if (moreThanWeek) {
    const weeks = Math.floor(abs / IntervalMap.weekly);
    return weeks + " Week" + (weeks > 1 ? "s" : "") + (past ? " ago" : "");
  }

  if (moreThanDay) {
    const days = Math.floor(abs / IntervalMap.daily);
    return days + " Day" + (days > 1 ? "s" : "") + (past ? " ago" : "");
  }

  if (moreThanHour) {
    const hours = Math.floor(abs / IntervalMap.hourly);
    return hours + " Hour" + (hours > 1 ? "s" : "") + (past ? " ago" : "");
  }

  if (moreThanMinute) {
    const minutes = Math.floor(abs / IntervalMap.hourly / 60);
    return (
      minutes + " Minute" + (minutes > 1 ? "s" : "") + (past ? " ago" : "")
    );
  }

  if (moreThanSecond) {
    const seconds = Math.floor(abs / IntervalMap.hourly / 60);
    return (
      seconds + " Second" + (seconds > 1 ? "s" : "") + (past ? " ago" : "")
    );
  }
}

export function PriceChart(props: {
  pair: { symbol: string; price: number; pair: string };
  candles: { [currency: string]: Array<Candle> };
  suggestedTrades: Array<IUserTrade>;
  pendingTrades: Array<IUserTrade>;
  onCancel: (trade: IUserTrade) => any;
  onReject: (trade: IUserTrade) => any;
  onApprove: (trade: IUserTrade) => any;
  onUpdateFunding: (funding: IUserFunding) => any;
  onRemoveFunding: (funding: IUserFunding) => any;
  rates: Rates;
  funding: IUserFunding;
  pro: boolean;
}) {
  const pair = props.pair;
  const funding = props.funding;
  const filteredPending = props.pendingTrades.filter(
    t => Util.getCurrencyFromPair(t.productId) === pair.symbol
  );

  const filteredSuggested = props.suggestedTrades.filter(
    t => Util.getCurrencyFromPair(t.productId) === pair.symbol
  );

  const defaultTab = filteredSuggested.length
    ? 0
    : filteredPending.length
    ? 1
    : 2;
  const [tabNum, setValue] = React.useState(defaultTab);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const daysOfData = window.innerWidth > 700 ? 30 : 30;
  const candleData = (props.candles[pair.symbol] || [])
    .filter(candles => candles.high && candles.low)
    .map(candles => ({
      ...candles,
      daysAgo: parseInt(
        (
          (Date.now() - normalizeTime(candles.time)) /
          (1000 * 60 * 60 * 24)
        ).toString(),
        10
      )
    }))
    .slice(-1 * daysOfData);
  const pendingTradeLines = props.pendingTrades
    .filter(t => t.productId === pair.pair)
    .map((t, index) => (
      <ReferenceLine
        key={index}
        x={0}
        y={t.price > pair.price || t.type === "market" ? pair.price : t.price}
        stroke={PENDING_COLOR}
      />
    ));

  const suggestedTradeLines = props.suggestedTrades
    .filter(t => t.productId === pair.pair)
    .map((t, index) => (
      <ReferenceLine
        key={index}
        x={0}
        y={t.price > pair.price || t.type === "market" ? pair.price : t.price}
        stroke={SUGGESTED_COLOR}
      />
    ));
  const shouldShowRollover =
    funding.rolloverBalance && funding.rolloverBalance > 1;

  return (
    <ExpansionPanel
      key={funding.purchaseSymbol + funding.lastFunded}
      style={BasicStyles.primaryCard}
      defaultExpanded={!!filteredPending.length || !!filteredSuggested.length}
    >
      <ExpansionPanelSummary
        style={styles.summaryHeader}
        expandIcon={
          <Badge
            color="primary"
            variant="dot"
            invisible={
              filteredSuggested.length || filteredPending.length ? false : true
            }
            style={BasicStyles.slightMargin}
          >
            <ExpandMoreIcon />
          </Badge>
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div style={styles.label}>
          <h2 style={styles.fontWeight}>
            {pair.symbol} {pair.price.toFixed(2)}
          </h2>
          <div style={styles.fontWeight}>
            {" "}
            ${funding.amount} {Util.getIntervalName(funding.interval)}
            {shouldShowRollover
              ? ` + $${funding.rolloverBalance.toLocaleString()} Stashed`
              : ""}
            {" - Runs in "}
            {dateDistanceString(funding.nextFunding)}
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails
        style={{ ...BasicStyles.primaryCard, maxWidth: "95vw" }}
      >
        <LineChart
          width={window.innerWidth > 700 ? 700 : 400}
          height={300}
          data={candleData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={"daysAgo"} />
          <YAxis domain={["auto", "dataMax + 1"]} />
          <ChartTooltip />
          <Legend />
          <Line
            dot={false}
            type="monotone"
            dataKey="high"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line
            dot={false}
            type="monotone"
            dataKey="low"
            stroke="#bd84d8"
            activeDot={{ r: 8 }}
          />
          {pendingTradeLines}
          {suggestedTradeLines}
        </LineChart>
        <Tabs
          value={tabNum}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            disabled={filteredSuggested.length === 0}
            label={`Suggested (${filteredSuggested.length})`}
          />
          <Tab
            disabled={filteredPending.length === 0}
            label={`Pending (${filteredPending.length})`}
          />
          <Tab label="Funding" />
        </Tabs>
        {tabNum === 2 ? (
          <div className="spacer">
            <EditFunding
              pro={props.pro}
              funding={funding}
              onUpdate={f => props.onUpdateFunding(f)}
              onRemove={f => props.onRemoveFunding(f)}
            />
          </div>
        ) : null}

        {tabNum == 0 && filteredSuggested.length ? (
          <TradeList
            trades={filteredSuggested}
            rates={props.rates}
            onReject={props.onReject}
            onApprove={props.onApprove}
          />
        ) : null}

        {tabNum === 1 && filteredPending.length ? (
          <TradeList
            trades={filteredPending}
            rates={props.rates}
            onCancel={props.onCancel}
            onApprove={props.onApprove}
          />
        ) : null}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}
export function Prices(props: {
  pairs: Array<{ symbol: string; price: number; pair: string }>;
  candles: { [currency: string]: Array<Candle> };
  suggestedTrades: Array<IUserTrade>;
  pendingTrades: Array<IUserTrade>;
  onCancel: (trade: IUserTrade) => any;
  onReject: (trade: IUserTrade) => any;
  onApprove: (trade: IUserTrade) => any;
  onUpdateFunding: (funding: IUserFunding) => any;
  onRemoveFunding: (funding: IUserFunding) => any;
  rates: Rates;
  funding: Array<IUserFunding>;
  pro: boolean;
  fundingLimit: number;
}) {
  const allTrades = props.suggestedTrades.concat(props.pendingTrades);
  const runAllNow = () => {
    props.funding.forEach(f => {
      f.nextFunding = Date.now() - IntervalMap.daily;
      props.onUpdateFunding(f);
    });
  };
  const fundingLimit = props.fundingLimit;
  return (
    <div
      style={{ ...BasicStyles.cardWidth, textAlign: "right", margin: "0 auto" }}
    >
      <Tooltip
        title={`You have used ${
          props.funding.length
        } of your ${fundingLimit} investment plan limit `}
        aria-label="add"
      >
        <Chip
          label={props.funding.length + "/" + fundingLimit}
          style={{ fontSize: "18px", marginRight: "25px" }}
        />
      </Tooltip>
      <Button
        variant="outlined"
        style={styles.runButton}
        onClick={() => runAllNow()}
      >
        Run all now
      </Button>
      {props.funding.map((f, index) => {
        const pair = props.pairs.find(p => p.pair === f.purchaseSymbol)!;
        const fundingSuggestedTrades = props.suggestedTrades.filter(
          t => t.funding === (f as MongoBound<IUserFunding>)._id
        );

        const fundingPendingTrades = props.pendingTrades.filter(
          t => t.funding === (f as MongoBound<IUserFunding>)._id
        );
        return (
          <div key={index}>
            <PriceChart
              funding={f}
              key={index}
              pair={pair}
              candles={props.candles}
              suggestedTrades={fundingSuggestedTrades}
              pendingTrades={fundingPendingTrades}
              onCancel={props.onCancel}
              onReject={props.onReject}
              onApprove={props.onApprove}
              onUpdateFunding={props.onUpdateFunding}
              onRemoveFunding={props.onRemoveFunding}
              rates={props.rates}
              pro={props.pro}
            />
          </div>
        );
      })}
    </div>
  );
}
