import React from "react";
import { IUserFund } from "../../types";
import { AppData } from "../../contexts/AppData";
import { RouteComponentProps } from "react-router";
import { TradingService } from "../../services/TradingService";
import { BasicStyles } from "../../styles";
import { CreateFund } from "../../components/CreateFund";
import { Loading } from "../../components/Loading";
import { FundListItem } from "../../components/FundListItem";
import { SideNav } from "../../components/SideNav";
import { BottomNav } from "../../components/BottomNav";

interface Props extends RouteComponentProps<any> {}
type State = {
  createFund: Partial<IUserFund>;
};

const styles = {
  lessTopMargin: {
    marginTop: "-35px"
  },
  roundIcon: {
    borderRadius: "50px",
    margin: "25px"
  }
};
export class FundsContainer extends React.Component<Props, State> {
  context!: React.ContextType<typeof AppData>;
  static contextType = AppData;

  async componentDidMount() {
    const loggedIn = await TradingService.isLoggedIn();
    if (typeof loggedIn === "boolean") {
      this.props.history.push("/login");
    } else {
      this.context.setUser(loggedIn);
      await this.context.initializeAll();
      await this.context.refreshFunds();
      await this.context.doneLoading();
    }
  }

  async handleCreate(fund: Partial<IUserFund>) {
    TradingService.updateUserFund(fund);
    await this.context.refreshFunds();
  }


  getAllServiceFunding() {
    let funding = [];
    for (const service of Object.keys(this.context.services)) {
      const serviceData = this.context.services[service];
      if (serviceData && serviceData.funding) {
        funding.push(...serviceData.funding);
      }
    }
    return funding;
  }

  fundListComponent() {
    return <div>{this.context.funds.map(f => <FundListItem fund={f} />)}</div>;
  }

  render() {
    if (this.context.loading) {
      return <Loading />;
    }

    return (
      <div>
        <a href="/dashboard">
          <img height="100" src="icon.png" />
          <div style={styles.lessTopMargin}>
            <img style={styles.roundIcon} height="33" src="coinbase-icon.svg" />
            <img
              style={styles.roundIcon}
              height="33"
              src="robinhood-icon.jpg"
            />
            <img style={styles.roundIcon} height="33" src="sofi-icon.png" />
          </div>
        </a>
        <SideNav currentState="coinbase-advanced" />

        <div style={BasicStyles.primaryCardFull}>
          <CreateFund onCreate={fund => this.handleCreate(fund)} />
          {this.fundListComponent()}
        </div>
        <BottomNav currentState="funds" />
      </div>
    );
  }
}
