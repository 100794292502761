import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import RestoreIcon from "@material-ui/icons/Restore";
import FavoriteIcon from "@material-ui/icons/Favorite";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FolderIcon from "@material-ui/icons/Folder";
import Build from "@material-ui/icons/Build";
import Dashboard from "@material-ui/icons/Dashboard";
import Home from "@material-ui/icons/Home";
import Apps from "@material-ui/icons/Apps";
import AccountBox from "@material-ui/icons/AccountBox";
import NotificationsIcon from "@material-ui/icons/Notifications";

import Badge from "@material-ui/core/Badge";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import { AppData, AppState } from "../contexts/AppData";
import { BasicStyles } from "../styles";

const styles = {
  appBox: {
    position: "fixed" as "fixed",
    zIndex: 99998,
    borderTopRadius: "25px",
    top: "0",
    height: "100%",
    width: "100%",
    backgroundColor: "#fff",
    overflow: "hidden",
  },
  bottom: {
    width: "100%",
    position: "fixed" as "fixed",
    bottom: "0",
    zIndex: 99999,
    boxShadow: "4px 4px 4px 2px",
  },
  menuItem: {
    ...BasicStyles.row,
    alignItems: "center",
    textAlign: "center" as "center",
    justifyContent: "space-around" as "space-around",
    margin: "auto",
    maxWidth: "250px",
    minWidth: "250px",
    borderBottom: "solid gray 1px",
    marginBottom: "25px",
  },
  menuText: {
    width: "150px",
  },
};
export function BottomNavComponent(props: {
  currentState?: string;
  serviceNotifications: { [service: string]: number };
  notifications: AppState["notifications"];
}) {
  const [navValue, setNavValue] = React.useState(props.currentState || "home");

  const [showApps, setShowApps] = React.useState(false);

  const unviewedNotifications = props.notifications.filter((p) => !p.viewed);
  return (
    <div className="bottom-nav" style={{ overflow: "hidden" }}>
      {showApps ? (
        <Paper style={styles.appBox}>
          <div>
            <img height={150} src="/logo.png" />
          </div>
          <div style={{ marginBottom: "25px" }}>
            <Link to={"/notifications"}>
              <Badge
                badgeContent={unviewedNotifications.length}
                color="secondary"
              >
                <NotificationsIcon />
              </Badge>
            </Link>
          </div>

          <Link style={styles.menuItem} to={"/coinbase-advanced"}>
            <Badge
              badgeContent={props.serviceNotifications["coinbase-advanced"]}
              color="secondary"
            >
              <img height="50" src="/coinbase-icon.svg" />
            </Badge>
            <h2 style={styles.menuText}>Coinbase</h2>
          </Link>
          <Link style={styles.menuItem} to={"/kraken"}>
            <Badge
              badgeContent={props.serviceNotifications.kraken}
              color="secondary"
            >
              <img height="50" src="/kraken-icon.jpg" />
            </Badge>
            <h2 style={styles.menuText}>Kraken</h2>
          </Link>

          <Link style={styles.menuItem} to={"/robinhood"}>
            <Badge
              badgeContent={props.serviceNotifications.robinhood}
              color="secondary"
            >
              <img height="50" src="/robinhood-icon.jpg" />
            </Badge>
            <h2 style={styles.menuText}>Robinhood</h2>
          </Link>
          <Link style={styles.menuItem} to={"/sofi"}>
            <Badge
              badgeContent={props.serviceNotifications.sofi}
              color="secondary"
            >
              <img height="50" src="/sofi-icon.png" />
            </Badge>
            <h2 style={styles.menuText}>SoFi</h2>
          </Link>
          <Link style={styles.menuItem} to={"/binance"}>
            <Badge
              badgeContent={props.serviceNotifications.binance}
              color="secondary"
            >
              <img height="50" src="/binance-icon.png" />
            </Badge>
            <h2 style={styles.menuText}>Binance US</h2>
          </Link>

          <Link style={styles.menuItem} to={"/celcius"}>
            <Badge
              badgeContent={props.serviceNotifications.celcius}
              color="secondary"
            >
              <img height="50" src="/celcius-icon.png" />
            </Badge>
            <h2 style={styles.menuText}>Celcius</h2>
          </Link>
        </Paper>
      ) : null}
      <BottomNavigation
        value={navValue}
        onChange={(e, v) => setNavValue(v)}
        style={styles.bottom}
      >
        <Link to={"/dashboard"}>
          <BottomNavigationAction
            label=""
            value="home"
            onClick={() => setShowApps(false)}
            icon={
              <Badge badgeContent={0} color="secondary">
                <Home />
              </Badge>
            }
          />
        </Link>
        <BottomNavigationAction
          label=""
          value="apps"
          onClick={() => {
            window.scrollTo({ top: 0 });
            setShowApps(!showApps);
          }}
          icon={
            <Badge badgeContent={0} color="secondary">
              <Apps />
            </Badge>
          }
        />
        <Link to={"/profile"}>
          <BottomNavigationAction
            label=""
            value="profile"
            onClick={() => setShowApps(false)}
            icon={
              <Badge badgeContent={0} color="secondary">
                <AccountBox />
              </Badge>
            }
          />
        </Link>
      </BottomNavigation>
    </div>
  );
}

export function BottomNav(props: { currentState?: string }) {
  return (
    <AppData.Consumer>
      {(context) => (
        <BottomNavComponent
          {...props}
          serviceNotifications={{
            "coinbase-advanced": context.getNotificationCountFor("coinbase-advanced"),
            robinhood: context.getNotificationCountFor("robinhood"),
            sofi: context.getNotificationCountFor("sofi"),
          }}
          notifications={context.notifications}
        />
      )}
    </AppData.Consumer>
  );
}
