import { IFilledUserTrade } from "../../types";
import { CurrencyBalances } from "../../types";
import React from "react";
import { IUserTrade } from "../../types";
import { RouteComponentProps } from "react-router";
import { Balance } from "../../components/Balance";
import { TradingService } from "../../services/TradingService";
import { Loading } from "../../components/Loading";
import {
  Rates,
  IUserUpdate,
  IUser,
  IUserFunding,
  IntervalMap,
  IUserDividend,
  CurrencyCandles,
  ServicesCurrencyBalances,
  Order,
} from "../../types";
import { UpdateUser } from "../../components/UpdateUser";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Build from "@material-ui/icons/Build";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ShowChart from "@material-ui/icons/ShowChart";
import Badge from "@material-ui/core/Badge";
import { BasicStyles } from "../../styles";
import { FullPie } from "../../components/BalancePieCharts/FullPie";
import { Util } from "../../services/Util";
import { YearProjection } from "../../components/YearlyProjection";
import { BottomNav } from "../../components/BottomNav";
import { SideNav } from "../../components/SideNav";
import { AppData, AppState } from "../../contexts/AppData";
import { FundingPieChart } from "../../components/FundingPieChart";
import { MonthlyOrdersBarChart } from "../../components/MonthlyOrdersBarChart";
import { Link } from "react-router-dom";
import { DividendsHistoryChart } from "../../components/DividendsHistory";
import { HistoricalBalance } from "../../components/HistoricalBalanceChart";
import GaugeChart from "react-gauge-chart";
import { FinancialIndependence } from "../../services/FinancialIndependence";
import { TopBar } from "../../components/TopBar";
import { ExchangePanel } from "../../components/ExchangePanel";

type Props = AppState & RouteComponentProps<{}>;

const AppIcons: { [key: string]: { icon: string; name: string } } = {
  "coinbase-advanced": { icon: "coinbase-icon.svg", name: "Coinbase" },
  binance: { icon: "binance-icon.png", name: "Binance" },
  celcius: { icon: "celcius-icon.png", name: "Celcius" },
  kraken: { icon: "kraken-icon.jpg", name: "Kraken" },
  sofi: { icon: "sofi-icon.png", name: "SoFi" },
  robinhood: { icon: "robinhood-icon.jpg", name: "Robinhood" },
};

const styles = {
  gridBox: {
    minWidth: "200px",
    flexGrow: 1,
    overflow: "visible",
    margin: "10px",
    padding: "10px",
  },

  moneyText: {
    fontWeight: 200,
  },
  noBottomMargin: {
    fontWeight: 200,
    marginBottom: "0px",
  },
  topSummary: {
    display: "flex",
    marginLeft: window.innerWidth > 1200 ? "200px" : "0px",
    marginRight: window.innerWidth > 1200 ? "100px" : "0px",
    marginBottom: "0px",
    justifyContent: "center",
    flexDirection: "row" as "row",
    flexWrap: "wrap" as "wrap",
  },
  summary: {
    display: "flex",
    flexDirection: "column" as "column",
    margin: "15px",
    minWidth: window.innerWidth > 700 ? "220px" : "85vw",
    maxWidth: window.innerWidth > 750 ? "650px" : "88vw",
    padding: "15px",
  },
  chartSummary: {
    display: "flex",
    flexDirection: "column" as "column",
    margin: "15px",
    minWidth: window.innerWidth > 700 ? "400px" : "92vw",
    maxWidth: window.innerWidth > 750 ? "650px" : "88vw",
  },
  logoContainer: {
    textAlign: "left" as "left",
    height: "50px",
  },
};
export class DashboardComponent extends React.Component<Props, {}> {
  async componentDidMount() {
    this.props.startLoading();
    const loggedIn = await TradingService.isLoggedIn();
    if (typeof loggedIn === "boolean") {
      this.props.history.push("/login");
    } else {
      this.props.setUser(loggedIn);
      await this.props.initializeAll();
      this.props.doneLoading();
    }
  }

  getMergedBalances() {
    return this.props.getMergedBalances();
  }

  getMergedFilledTrades() {
    let trades = new Array<IFilledUserTrade>();
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.filledTrades) {
        trades = trades.concat(serviceData.allTrades.filled);
      }
    }
    return trades;
  }

  getMergedTotalInvested(start?: Date, end?: Date) {
    const total = this.getMergedFilledTrades().reduce((sum, t) => {
      if (start && new Date(((t as any) as Order).done_at || "") < start) {
        return sum;
      }
      if (end && new Date(((t as any) as Order).done_at || "") > end) {
        return sum;
      }
      if (t.side === "sell") {
        return sum - Util.toFixedNumber(t.amount * t.price);
      } else if (t.side === "buy") {
        return sum + Util.toFixedNumber(t.amount * t.price);
      } else {
        return sum;
      }
    }, 0);
    return Util.toFixedNumber(total);
  }

  getMergedTotalValue() {
    let total = 0;
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.filledTrades) {
        total += this.props.services[service].allTrades.filled.reduce(
          (sum, t) => sum + t.amount * this.getPriceOfPair(t.productId),
          0
        );
      }
    }
    return Util.toFixedNumber(total);
  }

  getMergedCandles() {
    return this.props.getMergedCandles();
  }

  getPriceOfPair(pair: string) {
    const currency = Util.getCurrencyFromPair(pair);
    const service = Object.keys(this.props.services).find((service) =>
      Object.keys(this.props.services[service].serviceRates.rates).includes(
        currency
      )
    );
    if (!service) {
      return 0;
    }
    const currentRate =
      this.props.services[service].serviceRates.rates[currency] || 1;
    return Util.toFixedNumber(currentRate);
  }

  getMergedBalanceHistory() {
    return this.props.getMergedBalanceHistory();
  }

  holdings() {
    return Object.entries(this.getMergedBalances())
      .filter(([k, v]) => v.unlocked)
      .map(([k, v]) => ({
        currency: k,
        unlocked: Util.toFixedNumber(v.unlocked),
        usd: Util.toFixedNumber(v.unlocked * this.getPriceOfPair(k)),
      }));
  }

  getServiceCurrencyBalance(service: string, currency: string) {
    const serviceData = this.props.services[service];
    const balance = serviceData.balances[currency] || { unlocked: 0 };
    return balance.unlocked || 0;
  }

  getServiceBalance(service: string) {
    const serviceData = this.props.services[service];
    return this.getTotalBalance(
      service,
      serviceData.balances,
      serviceData.serviceRates
    );
  }

  getTotalBalance(service: string, balances: any, serviceRates: any): number {
    let total = 0;
    for (let currency in balances) {
      const balance = balances[currency].unlocked;
      const rate = serviceRates.rates[currency];
      total += balance && rate ? balance * rate : 0;
    }
    return Util.toFixedNumber(total);
  }

  getMergedTotalBalance() {
    return this.props.getMergedTotalBalance();
  }

  getPastGrowth() {
    const totalBalance = this.getMergedTotalBalance();
    const totalInvested = this.getMergedTotalInvested();
    const growthUsd = (totalBalance - totalInvested) / totalBalance;
    const allTrades = this.getMergedFilledTrades();
    const oldestTrades = allTrades
      .filter((t) => t.gainAgeDays)
      .sort((a, b) => b.gainAgeDays - a.gainAgeDays);
    const sumOfGains = oldestTrades.reduce(
      (sum, t) => sum + (t.unrealized || 0),
      0
    );
    const avgGainAge =
      oldestTrades.reduce(
        (sum, t) => sum + t.gainAgeDays * (t.unrealized || 0),
        0
      ) / sumOfGains;
    console.log("Weighted Average gain age", avgGainAge);
    const numMonths = avgGainAge / 30;
    return growthUsd / numMonths;
  }

  getFundingAmount(service: string) {
    const serviceData = this.props.services[service];
    if (serviceData && serviceData.funding) {
      return serviceData.funding.reduce(
        (sum, f) =>
          sum +
          Util.toFixedNumber((IntervalMap.monthly / f.interval) * f.amount),
        0
      );
    } else return 0;
  }

  getAllServiceFunding() {
    let funding = [];
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.funding) {
        funding.push(...serviceData.funding);
      }
    }
    return funding;
  }

  getMergedFunding() {
    let total = 0;
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.funding) {
        total += serviceData.funding.reduce(
          (sum, f) => sum + (IntervalMap.monthly / f.interval) * f.amount,
          0
        );
      }
    }
    return Util.toFixedNumber(total);
  }

  getAllDividends() {
    const allDividends = new Array<IUserDividend>();
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.dividends) {
        for (const dividend of serviceData.dividends) {
          allDividends.push(dividend);
        }
      }
    }
    return allDividends;
  }

  getMergedDividendSum() {
    return this.getAllDividends().reduce((sum, d) => {
      if (Date.now() - new Date(d.date).getTime() <= IntervalMap.yearly) {
        sum += d.amount;
      }
      return sum;
    }, 0);
  }

  getAllCurrencyGains() {
    const allGains = new Array<{
      service: string;
      currency: string;
      balance: number;
      balanceGain: number;
      gain: number;
      totalShares: number;
      totalSpent: number;
      avgPrice: number;
      gainPercent: string;
      currentPrice: number;
    }>();
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.balances) {
        for (const currency of Object.keys(serviceData.balances)) {
          const balance = serviceData.balances[currency].unlocked;
          const currencyTrades = serviceData.allTrades.filled.filter(
            (t) =>
              Util.getCurrencyFromPair(t.productId) === currency &&
              t.side === "buy" &&
              t.status != "cancelled"
          );
          if (currencyTrades.length) {
            const gains = currencyTrades.map(
              (t) => (t.realized || 0) + (t.unrealized || 0)
            );
            const currencyGain = gains.reduce((sum, g) => sum + g, 0);

            const totalShares = Util.toFixedNumber(
              currencyTrades.reduce((sum, t) => {
                if (t.type == "sell") {
                  return sum;
                }
                if (t.type === "buy") {
                  return sum + (t.amount - (t.realizedAmount || 0));
                } else {
                  return sum;
                }
              }, 0),
              4
            );

            const totalSpent = Util.toFixedNumber(
              currencyTrades.reduce((sum, t) => {
                if (t.type == "sell") {
                  return sum;
                }
                if (t.type === "buy") {
                  return sum + (t.amount - (t.realizedAmount || 0)) * t.price;
                } else {
                  return sum;
                }
              }, 0)
            );
            const avgPrice = totalSpent / totalShares;
            const currentPrice = Util.toFixedNumber(
              serviceData.serviceRates.rates[currency] || 0
            );
            const balanceGain = Util.toFixedNumber(
              balance * currentPrice - totalSpent
            );
            const gain = Util.toFixedNumber(currencyGain);
            const gainPercent =
              Util.toFixedNumber((gain / totalSpent) * 100) + "%";
            allGains.push({
              currency,
              service,
              gain,
              balance,
              balanceGain,
              totalShares,
              totalSpent,
              avgPrice,
              currentPrice,
              gainPercent,
            });
          }
        }
      }
    }
    return allGains.sort((g1, g2) => g1.gain - g2.gain);
  }

  getGainTableData() {
    const data = this.getAllCurrencyGains().sort(
      (g1, g2) => g2.balanceGain - g1.balanceGain
    );
    return data.slice(0, 7).map((d, i) => {
      return (
        <tr key={i}>
          <td>
            <img
              height="20"
              src={AppIcons[d.service as keyof typeof AppIcons].icon}
            />
          </td>
          <td>{d.currency}</td>
          <td>{Util.toFixedNumber(d.balance, 3)}</td>
          <td>{d.currentPrice}</td>
          <td>{d.balanceGain}</td>
        </tr>
      );
    });
  }

  getTopLoss() {
    return this.getAllCurrencyGains()[0];
  }

  getTopWin() {
    const allGains = this.getAllCurrencyGains();
    return allGains[allGains.length - 1];
  }

  async updateUser(updates: IUserUpdate) {
    await TradingService.updateUser(updates);
  }

  getPotentialCoinbaseFees() {
    /*
     *If the total transaction amount is less than or equal to $10, the fee is $0.99.
     *If the total transaction amount is more than $10 but less than or equal to $25, the fee is $1.49.
     *If the total transaction amount is more than $25 but less than or equal to $50, the fee is $1.99.
     *If the total transaction amount is more than $50 but less than or equal to $200, the fee is $2.99.
     */
    return Util.toFixedNumber(
      this.props.services['coinbase-advanced'].filledTrades.reduce((fees, t) => {
        let fee = 0;
        const tradeValue = Util.toFixedNumber(t.amount * t.price);
        if (tradeValue >= 10) {
          fee = 0.99;
        }
        if (tradeValue >= 25) {
          fee = 1.49;
        }
        if (tradeValue >= 50) {
          fee = 1.99;
        }
        if (tradeValue >= 50) {
          fee = 2.99;
        }
        return fees + fee;
      }, 0)
    );
  }

  getAllServiceTrades() {
    let allTrades = [];
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.trades) {
        allTrades.push(...serviceData.trades);
      }
    }
    return allTrades;
  }

  getTotalDips() {
    let dipSum = 0;
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      for (const trade of serviceData.filledTrades) {
        if (trade.historicalPrice >= trade.price) {
          const potentialSpend = trade.amount * trade.historicalPrice;
          const actualSpend = trade.amount * trade.price;
          const dip = potentialSpend - actualSpend;
          dipSum += dip;
        }
      }
    }
    return dipSum;
  }

  render() {
    if (this.props.loading) {
      return <Loading />;
    }
    const monthlyFunds = this.getMergedFunding();
    const totalBalance = this.getMergedTotalBalance();
    const yearDividends = this.getMergedDividendSum();
    const totalInvested = this.getMergedTotalInvested();
    const coinbasePotentialFees = this.getPotentialCoinbaseFees();
    const gain = Util.toFixedNumber(totalBalance - totalInvested);
    const gainType = Util.getGainType(gain);
    const totalDip = this.getTotalDips();
    const totalSavings = totalDip + coinbasePotentialFees;
    const postTaxIncome = this.props.user.postTaxIncome;
    const savingsRate = postTaxIncome ? (monthlyFunds * 12) / postTaxIncome : 0;
    const monthsOfSavings =
      Util.toFixedNumber(totalBalance / monthlyFunds) || 0;
    const safeWithdrawal = Util.toFixedNumber((totalBalance * 0.04) / 12);
    console.log({ safeWithdrawal });

    const winner = this.getTopWin();
    const winnerGainType = winner ? Util.getGainType(winner.gain) : null;
    const loser = this.getTopLoss();
    const loserGainType = loser ? Util.getGainType(loser.gain) : null;

    return (
      <div
        style={{
          height: "100%",
        }}
      >
        <TopBar />
        <div id="wave" />
        <div
          className="dashboard-container row-or-col"
          style={{
            ...BasicStyles.autoMargin,
            marginTop: "-16rem",
          }}
        >
          <div
            className="col-or-row col-77"
            style={{
              ...BasicStyles.autoMargin,
            }}
          >
            <Card
              style={{
                marginBottom: "15px",
                ...styles.gridBox,
                maxHeight: "420px",
              }}
            >
              <Link to={"/holdings"}>
                <h1
                  style={{
                    ...BasicStyles.moneyText,
                    fontSize: "xxx-large",
                    color: "green",
                  }}
                >
                  ${totalBalance.toLocaleString()}
                </h1>
              </Link>
              <HistoricalBalance
                totalCashValue={totalBalance}
                balances={this.getMergedBalances()}
                candles={this.getMergedCandles()}
                holdings={this.getMergedBalanceHistory()}
                usdBalanceAgg={this.props.getUsdBalanceHistory()}
                monthlyGrowth={this.getMergedFunding()}
                pastGrowth={this.getPastGrowth()}
              />
            </Card>
            <div
              className="row-or-col"
              style={{
                display: "flex",
              }}
            >
              <Card
                style={{
                  ...styles.gridBox,
                  flex: 2,
                }}
              >
                <Link to={"/performance"}>
                  <h1 style={styles.noBottomMargin}>Invested</h1>
                </Link>
                <h2 style={styles.moneyText}>
                  ${totalInvested.toLocaleString()}
                </h2>
                <Divider />
                <div
                  style={{
                    marginTop: "15px",
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    fontWeight: 200,
                  }}
                >
                  <table
                    style={{
                      marginTop: "15px",
                      minWidth: "100%",
                      margin: "0 auto",
                    }}
                  >
                    <tr>
                      <th style={{ fontWeight: 200 }}></th>
                      <th style={{ fontWeight: 200 }}>Symbol</th>
                      <th style={{ fontWeight: 200 }}>Balance</th>
                      <th style={{ fontWeight: 200 }}>Price</th>
                      <th style={{ fontWeight: 200 }}>Gain</th>
                    </tr>
                    {this.getGainTableData()}
                  </table>
                  <Divider
                    style={{ marginBottom: "15px", marginTop: "15px" }}
                  />
                  <span
                    style={{
                      ...BasicStyles.row,
                      ...gainType.gainStyle,
                      ...BasicStyles.autoMargin,
                      width: "200px",
                    }}
                  >
                    <span>{gainType.gainWord}:</span>{" "}
                    <span>${Math.abs(gain).toLocaleString()}</span>
                  </span>
                  <span
                    style={{
                      ...BasicStyles.row,
                      color: "green",
                      ...BasicStyles.autoMargin,
                      width: "200px",
                    }}
                  >
                    <span>Saved:</span>
                    <span>
                      ${Util.toFixedNumber(totalSavings, 0).toLocaleString()}
                    </span>
                  </span>
                </div>
              </Card>

              <Card
                style={{
                  ...styles.gridBox,
                  minWidth: "200px",
                  flex: 1,
                }}
              >
                <Link to={"/allocated"}>
                  <h1 style={styles.noBottomMargin}>Investing</h1>
                </Link>

                <h2 style={styles.moneyText}>
                  ${Util.toFixedNumber(monthlyFunds).toLocaleString()} Monthly
                </h2>
                <Divider />
                <h3>% of Income (savings rate)</h3>
                <GaugeChart
                  id="gauge-chart5"
                  nrOfLevels={420}
                  arcsLength={[0.1, 0.3, 0.5]}
                  colors={["#F2545B", "#FFD400", "#82ca9d"]}
                  textColor="black"
                  percent={savingsRate || 0}
                  arcPadding={0.02}
                />
                <a href="https://www.investopedia.com/terms/s/safe-withdrawal-rate-swr-method.asp">
                  <span>SWR: ${safeWithdrawal || "0"} Monthly</span>
                </a>
              </Card>
            </div>
            <Card
              style={{
                ...styles.gridBox,
                flex: 2,
              }}
            >
              <h1 style={styles.noBottomMargin}>Invest History</h1>
              <MonthlyOrdersBarChart
                orders={this.getAllServiceTrades()}
                goal={monthlyFunds}
              />
            </Card>
          </div>
          <div
            className="col-or-row"
            style={{ ...BasicStyles.autoMargin, alignSelf: "start" }}
          >
            {Object.keys(AppIcons)
              .sort((s1, s2) => {
                return this.getServiceBalance(s2) - this.getServiceBalance(s1);
              })
              .map((s) => (
                <ExchangePanel
                  balance={this.getServiceBalance(s)}
                  usdBalance={this.getServiceCurrencyBalance(s, "USD")}
                  fundingAmount={this.getFundingAmount(s)}
                  icon={AppIcons[s].icon}
                  label={AppIcons[s].name}
                  link={`/${s}`}
                />
              ))}
          </div>
        </div>
        <BottomNav currentState="home" />
      </div>
    );
  }
}
export function DashboardContainer(props: RouteComponentProps) {
  return (
    <AppData.Consumer>
      {(context) => <DashboardComponent {...props} {...context} />}
    </AppData.Consumer>
  );
}
