import React from "react";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { TradingService } from "../../services/TradingService";
import { RouteComponentProps } from "react-router";

const styles = {
  container: {
    maxWidth: "320px",
    margin: "0 auto",
    display: "flex" as "flex",
    flexDirection: "column" as "column",
  },
  header: {
    color: "gray",
  },
  buttons: {
    display: "flex" as "flex",
    justifyContent: "space-around",
  },
  inputs: {
    width: "300px",
  },
  loginContainer: {
    padding: "10px",
    margin: "0 auto",
  },
  logoContainer: {
    maxWidth: "320px",
  },
};

interface IProps
  extends RouteComponentProps<{
    secret?: string;
  }> {}
export class PasswordResetContainer extends React.Component<IProps, {}> {
  state = {
    email: "",
    password: "",
    requestStarted: false,
    password2: "",
  };

  componentDidMount() {
    TradingService.logOut();
  }

  setEmail(newEmail: string) {
    this.setState({ email: newEmail });
  }

  setPassword(newPassword: string) {
    this.setState({ password: newPassword });
  }

  setPassword2(newPassword: string) {
    this.setState({ password2: newPassword });
  }

  async resetpassword() {
    const { email, password } = this.state;
    try {
      this.setState({ requestStarted: true });
      const secret = this.props.match.params.secret;
      if (secret) {
        console.log(email, secret, password);
        const reset = await TradingService.submitPasswordResetSecret(
          email,
          secret,
          password
        );
        await this.login();
      } else {
        await TradingService.passwordReset(email);
      }
      this.setState({ requestStarted: false });
    } catch (e) {
      console.log(e);
    }
  }

  async login() {
    const { email, password } = this.state;
    try {
      this.setState({ requestStarted: true });
      const token = await TradingService.login(email, password);
      this.setState({ requestStarted: false });
      await TradingService.setToken(token.jwt);
      this.props.history.push("/dashboard");
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const secret = this.props.match.params.secret;
    const passwordMismatch =
      secret != "" && this.state.password != this.state.password2;
    const passwordTooShort =
      secret != "" &&
      (this.state.password.length < 5 || this.state.password2.length < 5);

    return (
      <div style={styles.container}>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.requestStarted}
          autoHideDuration={6000}
        >
          <SnackbarContent
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={"success"}>
                <div> Loading...</div>
              </span>
            }
          />
        </Snackbar>

        <div style={styles.logoContainer}>
          <img src="/logo.png" />
        </div>
        <Paper style={styles.loginContainer}>
          <div>
            <TextField
              style={styles.inputs}
              id="email"
              label="email"
              name="email"
              type="email"
              value={this.state.email}
              onChange={(e) => this.setEmail(e.target.value)}
              margin="normal"
            />
          </div>
          <div style={styles.buttons}>
            {!secret ? (
              <Button
                disabled={!this.state.email}
                onClick={() => this.resetpassword()}
              >
                Get Reset Link
              </Button>
            ) : null}

            {secret ? (
              <div>
                <div>
                  <TextField
                    style={styles.inputs}
                    id="password"
                    label="password"
                    type="password"
                    value={this.state.password}
                    onChange={(e) => this.setPassword(e.target.value)}
                    margin="normal"
                  />
                </div>
                <div>
                  <TextField
                    style={styles.inputs}
                    id="password"
                    label="confirm password"
                    type="password"
                    value={this.state.password2}
                    onChange={(e) => this.setPassword2(e.target.value)}
                    margin="normal"
                  />

                  {passwordMismatch ? (
                    <div>
                      <sub>Passwords must match.</sub>
                    </div>
                  ) : null}
                  {passwordTooShort ? (
                    <div>
                      <sub>Passwords must be at least 5 characters</sub>
                    </div>
                  ) : null}
                </div>

                <Button onClick={() => this.resetpassword()}>
                  Reset And Login
                </Button>
              </div>
            ) : null}
          </div>
        </Paper>
      </div>
    );
  }
}
