export const BasicStyles = {
  moneyText: {
    fontWeight: 200
  },
  textContainer: { textAlign: "left" as "left" },
  maxWidthContainer: {
    maxWidth: window.innerWidth > 750 ? "650px" : ""
  },
  autoMargin: {
    margin: "0 auto"
  },
  cardWidth: {
    maxWidth: window.innerWidth > 750 ? "680px" : ""
  },
  primaryCard: {
    padding: "15px",
    display: "flex",
    flexDirection: "column" as "column",
    maxWidth: window.innerWidth > 750 ? "650px" : "",
    ...(window.innerWidth > 750
      ? { margin: "0 auto" }
      : { marginLeft: "10px", marginRight: "10px" }),
    marginBottom: "30px",
    alignItems: "center" as "center"
  },
  primaryCardFull: {
    lineHeight: 2,
    padding: "15px",
    display: "flex",
    flexDirection: "column" as "column",
    maxWidth: window.innerWidth > 750 ? "650px" : "",
    ...(window.innerWidth > 750
      ? { margin: "0 auto" }
      : { marginLeft: "10px", marginRight: "10px" }),
    marginBottom: "30px"
  },
  primaryTableFull: {
    display: "flex",
    flexDirection: "column" as "column",
    maxWidth: window.innerWidth > 750 ? "850px" : "",
    ...(window.innerWidth > 750
      ? { margin: "0 auto" }
      : { marginLeft: "10px", marginRight: "10px" }),

    marginBottom: "30px"
  },

  primaryTable: {
    display: "flex",
    flexDirection: "column" as "column",
    maxWidth: window.innerWidth > 750 ? "675px" : "",
    ...(window.innerWidth > 750
      ? { margin: "0 auto" }
      : { marginLeft: "10px", marginRight: "10px" }),

    marginBottom: "30px"
  },
  slightMargin: {
    margin: "5px"
  },

  slightPadding: {
    padding: "5px"
  },
  column: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "space-between" as "space-between"
  },
  row: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between" as "space-between"
  },
  rowOrColumn:
    window.innerWidth > 750
      ? {
          display: "flex",
          flexDirection: "row" as "row",
          justifyContent: "space-between" as "space-between"
        }
      : {
          display: "flex",
          flexDirection: "column" as "column",
          justifyContent: "space-between" as "space-between"
        },

  fontWeight: { fontWeight: 200 }
};
