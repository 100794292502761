import React from "react";
import {
  AreaChart,
  Area,
  Legend,
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid
} from "recharts";
import {
  Slider,
  Typography,
  Switch,
  FormControlLabel
} from "@material-ui/core";
import { Util } from "../services/Util";
import { BasicStyles } from "../styles";
import { Intervals, IntervalMap, IFilledUserTrade } from "../types";

export function GainCalculator(props: {
  currentPrice: number;
  currentBalance: number;
  trades: Array<IFilledUserTrade>;
  gains: Array<{
    date: string;
    longterm: number;
    longtermBalance: number;
    shortterm: number;
    shorttermBalance: number;
  }>;
}) {
  const startPrice = props.currentPrice;
  const startDate = props.gains.length
    ? new Date(props.gains[0].date).getTime()
    : Date.now();

  const startBalance = props.currentBalance;
  const [simulatePrice, setSimulatePrice] = React.useState(startPrice);
  const [simulateDate, setSimulateDate] = React.useState(startDate);
  const [simulateSell, setSimulateSell] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  if (!props.gains || !props.currentPrice || !props.currentBalance) {
    return <div />;
  }

  if (!simulatePrice && startPrice) {
    setSimulatePrice(startPrice);
  }

  let closestGain = () => {
    const { trades } = props;
    let currentTradeIndex = 0;
    let tradeSum = 0;
    let longtermBalance = 0;
    let shorttermBalance = 0;
    let longtermGain = 0;
    let shorttermGain = 0;
    while (currentTradeIndex < trades.length && tradeSum <= simulateSell) {
      const trade = trades[currentTradeIndex];
      const unrealizedAmount = trade.amount - (trade.realizedAmount || 0);
      const simulatedSellAmount = Math.min(unrealizedAmount, simulateSell);
      const gain = (simulatePrice - trade.price) * simulatedSellAmount;
      const dateOffset = (simulateDate - Date.now()) / IntervalMap.daily;

      if (trade.side === "buy") {
        tradeSum += unrealizedAmount;
        if (trade.gainAgeDays + dateOffset >= 365) {
          longtermBalance += unrealizedAmount;
          longtermGain += gain;
        } else {
          shorttermBalance += unrealizedAmount;
          shorttermGain += gain;
        }
      }

      currentTradeIndex++;
    }

    shorttermGain = Util.toFixedNumber(shorttermGain);
    longtermGain = Util.toFixedNumber(longtermGain);
    longtermBalance = Util.toFixedNumber(longtermBalance);
    shorttermBalance = Util.toFixedNumber(shorttermBalance);

    return {
      shorttermGain,
      shorttermBalance,
      longtermGain,
      longtermBalance,
      date: simulateDate
    };
  };
  const gain = closestGain();

  return (
    <div
      style={{
        width: "100%",
        ...BasicStyles.primaryCard,
        ...BasicStyles.rowOrColumn
      }}
    >
      <div style={BasicStyles.slightPadding}>
        <h2>Total: ${Util.toFixedNumber(simulateSell * simulatePrice || 0)}</h2>
        <h2>Long Term: ${gain ? gain.longtermGain : 0}</h2>
        <h2>Short Term: ${gain ? gain.shorttermGain : 0}</h2>
      </div>
      <div style={{ width: "50%" }}>
        <Typography gutterBottom id="price-slider">
          Sell {simulateSell.toFixed(2)}
        </Typography>
        <Slider
          defaultValue={0}
          aria-labelledby="price-slider"
          valueLabelDisplay="auto"
          step={Util.toFixedNumber(startBalance / 10, 2)}
          marks
          min={0}
          max={startBalance}
          onChange={(_, value) =>
            setSimulateSell(Array.isArray(value) ? value[0] : value)
          }
        />

        <Typography gutterBottom id="price-slider">
          Price {(simulatePrice || 0).toFixed(2)}
        </Typography>
        <Slider
          defaultValue={startPrice}
          aria-labelledby="price-slider"
          valueLabelDisplay="auto"
          step={Util.toFixedNumber(startPrice / 10, 2)}
          marks
          min={Math.round(startPrice / 2)}
          max={Math.round(startPrice * 4)}
          onChange={(_, value) =>
            setSimulatePrice(Array.isArray(value) ? value[0] : value)
          }
        />

        <Typography gutterBottom id="date-slider">
          Date {new Date(simulateDate || startDate).toLocaleDateString()}
        </Typography>
        <Slider
          defaultValue={startPrice}
          aria-labelledby="date-slider"
          valueLabelDisplay="auto"
          step={IntervalMap.monthly}
          valueLabelFormat={v =>
            "+" + Math.floor((v - startDate) / IntervalMap.monthly)
          }
          marks
          min={startDate}
          max={startDate + IntervalMap.yearly}
          onChange={(_, value) =>
            setSimulateDate(Array.isArray(value) ? value[0] : value)
          }
        />
      </div>
    </div>
  );
}
