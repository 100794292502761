import { Emoji } from "../../../services/Emoji";
import React from "react";
import VpnKey from "@material-ui/icons/VpnKey";
import Build from "@material-ui/icons/Build";

import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import { TradingService } from "../../../services/TradingService";
import { Intervals } from "../../../types";
import { BasicStyles } from "../../../styles";
import { Balance } from "../../../components/Balance";
import { Prices } from "../../../components/Prices";
import { Util } from "../../../services/Util";
import { TradeList } from "../../../components/TradeList";
import { Loading } from "../../../components/Loading";
import { Props, BaseContainer, State } from "../base/BaseContainer";
import { BottomNav } from "../../../components/BottomNav";
import { SideNav } from "../../../components/SideNav";
import { Link } from "react-router-dom";
import { Membership } from "../../../constants";

const styles = {
  input: {
    minWidth: "166px"
  },
  keys: {
    padding: "15px",
    maxWidth: window.innerWidth > 750 ? "650px" : "88vw",
    margin: "0 auto",
    marginBottom: "30px"
  },
  roundIcon: {
    borderRadius: "50px",
    margin: "25px"
  }
};

export class SofiContainer extends BaseContainer {
  state: State & {
    username: string;
    password: string;
    twoFactorChallenge: string;
    twoFactorCode: string;
  } = {
    ...this.state,
    username: "",
    password: "",
    twoFactorChallenge: "",
    twoFactorCode: "",
    service: "sofi",
    createFunding: {
      ...this.state.createFunding,
      nextFunding: Date.now(),
      purchaseSymbol: "AAPL"
    }
  };

  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.setFakeSecret();
    super.initialize();
  }

  setUsername(username: string) {
    this.setState({ username });
  }

  setPassword(password: string) {
    this.setState({ password });
  }

  setTwoFactorCode(twoFactorCode: string) {
    this.setState({ twoFactorCode });
  }

  async saveSecret() {
    try {
      if (this.state.twoFactorCode && this.state.twoFactorChallenge) {
        this.setState({ requestStarted: true });
        await this.submitTwoFactor();
      }
      await this.setBotState("enable");
      window.location.reload();
    } catch (e) {}
    this.setState({ requestStarted: false });
  }

  async getTwoFactor() {
    const { challenge } = await TradingService.getTwoFactorChallenge(
      this.state.service,
      this.state.username,
      this.state.password
    );
    this.setState({ twoFactorChallenge: challenge });
  }

  async submitTwoFactor() {
    const { success } = await TradingService.answerTwoFactor(
      "sofi",
      this.state.username,
      this.state.password,
      this.state.twoFactorChallenge,
      this.state.twoFactorCode
    );
  }

  clearSecret() {
    this.setState({
      username: "",
      password: "",
      twoFactorCode: "",
      twoFactorChallenge: "",
      needsSecret: true
    });
  }

  setFakeSecret() {
    this.setState({
      username: "",
      password: "",
      twoFactorCode: "",
      twoFactorChallenge: ""
    });
  }

  setServiceSecretComponent() {
    const ActionButton = this.state.needsSecret ? (
      <Button
        variant="contained"
        color="primary"
        disabled={!this.state.twoFactorCode}
        onClick={() => this.saveSecret()}
      >
        Save
      </Button>
    ) : (
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => this.clearSecret()}
      >
        Reset
      </Button>
    );

    return this.state.needsSecret ? (
      <Paper style={{ ...BasicStyles.column, ...styles.keys }}>
        <h1>SoFi Credentials</h1>
        <TextField
          id="sofi-username"
          data-lpignore="true"
          label="username"
          value={this.state.username}
          disabled={!this.state.needsSecret}
          onChange={e => this.setUsername(e.target.value)}
          margin="normal"
        />
        <TextField
          id="sofi-password"
          data-lpignore="true"
          label="password"
          type="password"
          value={this.state.password}
          disabled={!this.state.needsSecret}
          onChange={e => this.setPassword(e.target.value)}
          margin="normal"
        />
        {!this.state.twoFactorCode ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => this.getTwoFactor()}
          >
            Login
          </Button>
        ) : null}
        <TextField
          id="sofi-twofactor"
          data-lpignore="true"
          label="two factor code"
          type="password"
          value={this.state.twoFactorCode}
          disabled={!this.state.needsSecret}
          onChange={e => this.setTwoFactorCode(e.target.value)}
          margin="normal"
        />
        {ActionButton}
      </Paper>
    ) : (
      this.apiCredentialsFilled()
    );
  }

  createFundingComponent() {
    const createAmount = this.state.createFunding.amount;
    const tooMuchFunding =
      this.state.funding.length >= Membership.Pro.limit[this.state.service];
    const freeLimit = Membership.Free.limit[this.state.service];
    const createDisabled =
      (createAmount != undefined && createAmount < 5) ||
      !createAmount ||
      tooMuchFunding;
    if (tooMuchFunding) {
      return null;
    }
    return (
      <Paper style={styles.keys}>
        {this.isProUser() || this.state.funding.length < freeLimit ? (
          <div style={BasicStyles.rowOrColumn}>
            <TextField
              id="standard-name"
              label="$ Amount"
              margin="normal"
              value={createAmount}
              type="number"
              onChange={e => this.handleFundingAmountChange(e.target.value)}
            />
            <TextField
              style={styles.input}
              label="How Often"
              select
              value={this.state.createFunding.interval}
              margin="normal"
              onChange={e => this.handleFundingIntervalChange(e.target.value)}
            >
              {Intervals.map((i, index) => (
                <MenuItem key={index} value={i.value}>
                  {i.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="To purchase"
              value={this.state.createFunding.purchaseSymbol}
              margin="normal"
              onChange={e =>
                this.handleFundingPurchaseSymbolChange(e.target.value)
              }
            />

            <Button
              disabled={createDisabled}
              color="primary"
              onClick={() => this.addFunding()}
            >
              Add Funding
            </Button>
          </div>
        ) : (
          <div style={BasicStyles.autoMargin}>
            <h1>Free Tier allows for 1 Investing Plan</h1>
            <Link to={"/pro"}>
              <Button color="primary" variant="contained">
                {" "}
                Upgrade For More
              </Button>
            </Link>
          </div>
        )}
      </Paper>
    );
  }

  apiCredentialsFilled() {
    return (
      <Paper style={BasicStyles.primaryCard}>
        <div>
          <VpnKey fontSize="large" />
        </div>
        <p>Credentials stored!</p>
        <Button onClick={() => this.clearSecret()}>Click here to reset</Button>
      </Paper>
    );
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }
    const totalGain = this.getTotalGain();
    const enableTradingText =
      this.state.bot && this.state.bot.enabled ? "Disable" : "Enable";

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
          open={this.state.requestStarted}
          autoHideDuration={6000}
        >
          <SnackbarContent
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={"success"}>
                <LinearProgress />
                <div>Loading your data...</div>
              </span>
            }
          />
        </Snackbar>

        <SideNav currentState={"sofi"} />

        <a href="/dashboard">
          <img style={styles.roundIcon} height="100" src="sofi-icon.png" />
        </a>
        {this.state.configure || this.state.needsSecret ? (
          <div>
            <div>{this.setServiceSecretComponent()}</div>
            {!this.state.needsSecret ? (
              <div>
                <Button
                  onClick={() => this.setBotState(enableTradingText)}
                  color="primary"
                  variant="contained"
                >
                  {enableTradingText} Trading
                </Button>
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <div>
              <Balance
                balance={this.state.totalBalance}
                unlocked={this.state.balances.USD.unlocked}
                locked={this.state.balances.USD.locked}
                holdings={this.holdings()}
                gain={totalGain}
                funding={this.state.funding}
              />
            </div>
            <div>{this.createFundingComponent()}</div>
            <div>
              <Prices
                fundingLimit={this.getFundingLimit()}
                pro={this.isProUser()}
                pairs={this.getPairs()}
                candles={this.state.candles}
                pendingTrades={this.getPendingTrades()}
                suggestedTrades={this.getUnapprovedTrades()}
                onReject={t => this.rejectTrade(t)}
                onApprove={t => this.approveTrade(t)}
                onCancel={t => this.cancelTrade(t)}
                onUpdateFunding={f => this.updateFunding(f)}
                onRemoveFunding={f => this.removeFunding(f)}
                rates={this.state.serviceRates.rates}
                funding={this.state.funding}
              />
            </div>
            <div>
              <h2>Trade History ({this.getHistoricalTrades().length})</h2>
              <TradeList
                trades={this.getHistoricalTrades()}
                rates={this.state.serviceRates.rates}
              />
            </div>
            <IconButton onClick={() => this.setState({ configure: true })}>
              <Build fontSize="large" />
            </IconButton>
          </div>
        )}

        <BottomNav currentState="sofi" />
      </div>
    );
  }
}
