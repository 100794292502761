import React from "react";
import { AppState, AppData } from "../../contexts/AppData";
import { RouteComponentProps } from "react-router";
import { BasicStyles } from "../../styles";
import MaterialTable from "material-table";
import { Util } from "../../services/Util";
import { TradingService } from "../../services/TradingService";
import { Loading } from "../../components/Loading";
import { SideNav } from "../../components/SideNav";
import { BottomNav } from "../../components/BottomNav";
type Props = AppState & RouteComponentProps<{}>;

export class Holdings extends React.Component<Props, {}> {
  async componentDidMount() {
    const loggedIn = await TradingService.isLoggedIn();
    if (typeof loggedIn === "boolean") {
      this.props.history.push("/login");
    } else {
      this.props.setUser(loggedIn);
      await this.props.initializeAll();
      this.props.doneLoading();
    }
  }

  getMergedTotalBalance() {
    let total = 0;
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.totalBalance) {
        total += this.props.services[service].totalBalance;
      }
    }
    return Util.toFixedNumber(total);
  }

  getAllHoldings() {
    const totalBalance = this.getMergedTotalBalance();
    const allGains: {
      [currency: string]: {
        currency: string;
        totalShares: number;
        currentPrice: number;
        totalValue: number;
        percentPortfolio: number;
      };
    } = {};
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.balances) {
        for (const currency of Object.keys(serviceData.balances)) {
          const existingBalance = allGains[currency]
            ? allGains[currency].totalShares
            : 0;
          const totalShares = Util.toFixedNumber(
            serviceData.balances[currency].unlocked + existingBalance,
            3
          );
          const currentPrice = serviceData.serviceRates.rates[currency];
          const totalValue = Util.toFixedNumber(totalShares * currentPrice);
          const percentPortfolio = Util.toFixedNumber(
            (totalValue / totalBalance) * 100
          );
          if (totalValue) {
            allGains[currency] = {
              currency,
              totalShares,
              currentPrice,
              totalValue,
              percentPortfolio
            };
          }
        }
      }
    }
    return Object.values(allGains).sort(
      (g1, g2) => g2.totalValue - g1.totalValue
    );
  }

  render() {
    if (this.props.loading) {
      return <Loading />;
    }
    const data = this.getAllHoldings();
    const totalBalance = this.getMergedTotalBalance();
    return (
      <div>
        <SideNav currentState={"home"} />
        {window.innerWidth <= 700 ? <img height={150} src="/logo.png" /> : null}
        <div>
          <h1
            style={{
              fontSize: "48px",
              marginBottom: "0px",
              color: "#66bb6a",
              ...BasicStyles.moneyText
            }}
          >
            ${totalBalance.toLocaleString()}
          </h1>
          <p style={BasicStyles.moneyText}>{data.length} Assets</p>
        </div>

        <div style={BasicStyles.primaryTableFull}>
          <MaterialTable
            isLoading={this.props.loading}
            columns={[
              {
                title: "Symbol",
                field: "currency" as "currency",
                type: "string" as "string"
              },
              {
                title: "Shares",
                field: "totalShares" as "totalShares",
                type: "numeric"
              },
              {
                title: "Current Price",
                field: "currentPrice" as "currentPrice",
                type: "currency"
              },
              {
                title: "Total Value",
                field: "totalValue" as "totalValue",
                type: "currency"
              },
              {
                title: "Portfolio %",
                field: "percentPortfolio" as "percentPortfolio",
                type: "numeric"
              }
            ]}
            data={data}
            totalCount={data.length}
            options={{ pageSize: data.length }}
            title="All Holdings"
          />
        </div>
        <BottomNav currentState="home" />
      </div>
    );
  }
}
export function HoldingsContainer(props: RouteComponentProps) {
  return (
    <AppData.Consumer>
      {context => <Holdings {...props} {...context} />}
    </AppData.Consumer>
  );
}
