import { Paper, TextField } from "@material-ui/core";
import * as React from "react";
import { IUserFund } from "../types";
import { BasicStyles } from "../styles";
import Button from "@material-ui/core/Button";

type Props = {
  onCreate: (fund: Partial<IUserFund>) => void;
};

const styles = {
  input: {
    minWidth: "166px"
  }
};
export function CreateFund(props: Props) {
  const [fundName, setFundName] = React.useState("");
  const [fundGoal, setFundGoal] = React.useState(0);
  const [fundBenchmark, setFundBenchmark] = React.useState(0);

  return (
    <Paper style={{ ...BasicStyles.primaryCardFull, maxWidth: "85%" }}>
      <div style={BasicStyles.rowOrColumn}>
        <TextField
          style={styles.input}
          label="Fund Name"
          margin="normal"
          value={fundName}
          type="text"
          onChange={e => setFundName(e.target.value)}
        />
        <TextField
          label="Fund Goal"
          margin="normal"
          value={fundGoal}
          type="text"
          onChange={e => setFundGoal(Number(e.target.value))}
        />
        <TextField
          label="Fund Name"
          margin="normal"
          value={fundBenchmark}
          type="text"
          onChange={e => setFundBenchmark(Number(e.target.value))}
        />
        <Button
          onClick={() =>
            props.onCreate({
              benchmark: fundBenchmark,
              goal: fundGoal,
              name: fundName
            })
          }
        >
          Create Fund
        </Button>
      </div>
    </Paper>
  );
}
