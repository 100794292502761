export type MongoBound<T> = T & { _id: string };

export interface IUser {
  email: string;
  passwordHash: string;
  phone?: string;
  alerts: boolean;
  stripeSession?: string;
  stripeSubscription?: string;
  stripeCustomer?: string;
  subscriptionValidTil: number;
  postTaxIncome?: number;
}

export interface IUserUpdate {
  email: string;
  password: string;
  newPassword: string;
  phone: string;
  alerts: boolean;
  postTaxIncome: number;
}

export interface IUserFunding
  extends MongoBound<{
    user: string;
    amount: number;
    interval: number;
    lastFunded: number;
    purchaseSymbol: string;
    service: string;
    nextFunding: number;
    rolloverBalance: number;
    autoApprove: boolean;
    strategyName: string;
    fund?: string;
  }> {}

export interface IUserTrade
  extends MongoBound<{
    service: string;
    user: string;
    funding: string;
    type: string;
    side: string;
    amount: number;
    price: number;
    historicalPrice: number;
    productId: string;
    lastUpdated: number;
    status?:
      | "approved"
      | "rejected"
      | "placed"
      | "failed"
      | "filled"
      | "cancelled";
    order: any;
  }> {}

export interface IFilledUserTrade extends IUserTrade {
  unrealized: number;
  unrealizedLT: number;
  unrealizedST: number;
  realizedAmount: number;
  realized: number;
  realizedLT: number;
  realizedST: number;
  gainAgeDays: number;
}

export interface IUserDividend {
  service: string;
  amount: number;
  productId: string;
  date: string;
}

export interface IUserBot {
  user: string;
  service: string;
  enabled: boolean;
  lastRun: number;
}

export type Candle = {
  time: number;
  low: number;
  high: number;
  open: number;
  close: number;
  volume: number;
};
export type CurrencyCandles = { [currency: string]: Array<Candle> };
export type Rates = { [currency: string]: number };
export interface GainType {
  gainColor: string;
  gainWord: string;
  gainStyle: { color: string };
}

export const Intervals = [
  { name: "Hourly", value: 60 * 60 * 1000 },
  { name: "Daily", value: 24 * 60 * 60 * 1000 },
  { name: "Weekly", value: 7 * 24 * 60 * 60 * 1000 },
  { name: "Monthly", value: 4 * 7 * 24 * 60 * 60 * 1000 },
  { name: "Yearly", value: 12 * 4 * 7 * 24 * 60 * 60 * 1000 },
];

const second = 1000;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;
const week = 7 * day;
const month = 4 * week;
const year = 12 * month;

export const IntervalMap = {
  minute,
  hourly: hour,
  daily: day,
  weekly: week,
  monthly: month,
  yearly: year,
};

export type Order = {
  id?: string;
  product_id: string;
  type: "limit" | "market";
  side: "buy" | "sell";
  price: number;
  size: number;

  stop?: "loss" | "entry";
  stop_price?: number;
  note?: string;
  orderDetails?: any;
  created_at: Date;

  funds?: string;
  specified_funds?: string;
  post_only?: boolean;
  done_at?: string;
  done_reason?: string;
  fill_fees?: string;
  filled_size?: string;
  executed_value?: string;
  status?: string;
  settled?: boolean;
};

export type Bot = {
  allowPartialShares: boolean;
  state: BotState;
};
export type CurrencyBalances = {
  [currency: string]: { locked: number; unlocked: number };
};

export type ServiceRates = {
  currency: string;
  rates: { [currency: string]: number };
};
export type ServicesCurrencyBalances = {
  [currency: string]: {
    locked: number;
    unlocked: number;
    services: Array<string>;
  };
};

export type UsdBalanceHistory = {
  date: string;
  usd: number;
  services: number;
  currencies: Array<string>;
  contributions: Array<{ currency: string; value: number }>;
};

export type UsdBalanceHistoryAggregate = {
  usdHistory: Array<UsdBalanceHistory>;
  lowestBalance: UsdBalanceHistory;
  maxBalance: UsdBalanceHistory;
};

export type BotState = {
  historicalData: CurrencyCandles;
  balances: CurrencyBalances;
  pendingTrades: Array<Order>;
  filledTrades: Array<Order>;
};

export interface IUserNotification {
  text: string;
  href: string;
  service: string;
  createdAt: number;
  viewed: boolean;
}

export interface Gain {
  growth: number;
  percent: number;
  high: number;
  low: number;
}

export type ProductGain = {
  productId: string;
  yearly: Gain;
  monthly: Gain;
  quarterly: { q1: Gain; q2: Gain; q3: Gain; q4: Gain };
};

export type ProductGains = {
  [product: string]: ProductGain;
};

export interface IUserFund {
  user: string;
  name: string;
  goal?: number;
  benchmark?: number;
}
