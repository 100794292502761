import React from "react";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuIcon from "@material-ui/icons/Menu";
import Lock from "@material-ui/icons/Lock";
import LocalBar from "@material-ui/icons/LocalBar";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MailIcon from "@material-ui/icons/Mail";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import Dashboard from "@material-ui/icons/Dashboard";
import AccountBox from "@material-ui/icons/AccountBox";
import { Link } from "react-router-dom";
import { AppData, AppState } from "../contexts/AppData";

export function SideNavComponent(props: {
  currentState?: string;
  serviceNotifications: { [service: string]: number };
  notifications: AppState["notifications"];
  pro: boolean;
}) {
  const { currentState = "dashboard" } = props;
  const [open, setOpen] = React.useState(true);
  const handleDrawerClose = () => {
    setOpen(!open);
  };

  if (window.innerWidth <= 1100) {
    return null;
  }

  const unviewedNotifications = props.notifications.filter((p) => !p.viewed);
  return (
    <Drawer variant="persistent" open={open}>
      <Link to={"/dashboard"}>
        <div>
          <img height={150} src="/logo.png" />
        </div>
      </Link>
      <Divider />
      <Link to={"/notifications"}>
        <IconButton aria-label="show new notifications" color="inherit">
          <Badge badgeContent={unviewedNotifications.length} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Link>
      <List>
        <Link to={"/dashboard"}>
          <ListItem
            button
            key={"dashboard"}
            selected={currentState === "dashboard"}
          >
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <Dashboard />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
        </Link>
        <Link to={"/profile"}>
          <ListItem
            button
            key={"profile"}
            selected={currentState === "profile"}
          >
            <ListItemIcon>
              <Badge badgeContent={0} color="secondary">
                <AccountBox />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"User Settings"} />
          </ListItem>
        </Link>
        <Divider />

        <Link to={"/coinbase-advanced"}>
          <ListItem
            button
            key={"coinbase-advanced"}
            selected={currentState === "coinbase-advanced"}
          >
            <ListItemIcon>
              <Badge
                badgeContent={props.serviceNotifications["coinbase-advanced"]}
                color="secondary"
              >
                <img height="25" src="/coinbase-icon.svg" />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Coinbase"} />
          </ListItem>
        </Link>
      </List>
      <List>
        <Link to={"/kraken"}>
          <ListItem button key={"kraken"} selected={currentState === "kraken"}>
            <ListItemIcon>
              <Badge
                badgeContent={props.serviceNotifications.kraken}
                color="secondary"
              >
                <img height="25" src="/kraken-icon.jpg" />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Kraken"} />
          </ListItem>
        </Link>
      </List>

      <List>
        <Link to={"/robinhood"}>
          <ListItem
            button
            key={"robinhood"}
            selected={currentState === "robinhood"}
          >
            <ListItemIcon>
              <Badge
                badgeContent={props.serviceNotifications.robinhood}
                color="secondary"
              >
                <img height="25" src="/robinhood-icon.jpg" />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Robinhood"} />
          </ListItem>
        </Link>
      </List>
      <List>
        <Link to={"/sofi"}>
          <ListItem button key={"sofi"} selected={currentState === "sofi"}>
            <ListItemIcon>
              <Badge
                badgeContent={props.serviceNotifications.sofi}
                color="secondary"
              >
                <img height="25" src="/sofi-icon.png" />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Sofi"} />
          </ListItem>
        </Link>
      </List>

      <List>
        <Link to={"/binance"}>
          <ListItem
            button
            key={"binance"}
            selected={currentState === "binance"}
          >
            <ListItemIcon>
              <Badge
                badgeContent={props.serviceNotifications.binance}
                color="secondary"
              >
                <img height="25" src="/binance-icon.png" />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Binance US"} />
          </ListItem>
        </Link>
      </List>

      <List>
        <Link to={"/celcius"}>
          <ListItem button key={"celcius"} selected={currentState === "celcius"}>
            <ListItemIcon>
              <Badge
                badgeContent={props.serviceNotifications.celcius}
                color="secondary"
              >
                <img height="25" src="/celcius-icon.png" />
              </Badge>
            </ListItemIcon>
            <ListItemText primary={"Celcius"} />
          </ListItem>
        </Link>
      </List>

      <Divider />
      <List>
        <Link to={"/pro"}>
          <ListItem button key={"pro"}>
            <ListItemIcon>
              <LocalBar />
            </ListItemIcon>
            <ListItemText
              primary={props.pro ? "Pro Features" : "Upgrade to Pro"}
            />
          </ListItem>
        </Link>

        <Link to={"/login"}>
          <ListItem button key={"logout"}>
            <ListItemIcon>
              <Lock />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
}

export function SideNav(props: { currentState?: string }) {
  return (
    <AppData.Consumer>
      {(context) => (
        <SideNavComponent
          {...props}
          pro={context.user.subscriptionValidTil > Date.now() / 1000}
          serviceNotifications={{
            "coinbase-advanced": context.getNotificationCountFor("coinbase-advanced"),
            robinhood: context.getNotificationCountFor("robinhood"),
            sofi: context.getNotificationCountFor("sofi"),
          }}
          notifications={context.notifications}
        />
      )}
    </AppData.Consumer>
  );
}
