import { IUserTrade } from "../../types";
import React from "react";
import { TradeList } from "../../components/TradeList";
import { BotState, Order, IntervalMap } from "../../types";
import { RouteComponentProps } from "react-router";
import { TradingService } from "../../services/TradingService";
import { Util } from "../../services/Util";
import { Balance } from "../../components/Balance";
import { Loading } from "../../components/Loading";
import { Prices } from "../../components/Prices";

interface Props extends RouteComponentProps<any> {}

interface State {
  loading: boolean;
  service: string;
  intervalName: string;
  interval: number;
  fundingAmount: number;
  symbol: string;
  bot: BotState;
}
export class BacktestContainer extends React.Component<Props, State> {
  state: State = {
    loading: true,
    bot: {} as BotState,
    service: this.props.match.params.service,
    symbol: this.props.match.params.symbol,
    fundingAmount: this.props.match.params.fundingAmount,
    intervalName: this.props.match.params.interval.toString().toLowerCase(),
    interval: IntervalMap.weekly
  };

  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    const loggedIn = await TradingService.isLoggedIn();
    if (!loggedIn) {
      this.props.history.push("/login");
    }
    const intervalName = this.props.match.params.interval
      .toString()
      .toLowerCase() as keyof typeof IntervalMap;
    const interval = IntervalMap[intervalName];

    this.setState({
      loading: true,
      service: this.props.match.params.service,
      symbol: this.props.match.params.symbol,
      fundingAmount: this.props.match.params.fundingAmount,
      intervalName,
      interval
    });

    const botState = await this.backtest(this.props);
    this.setState({ bot: botState.state });
    console.log(botState);

    this.setState({ loading: false });
  }

  backtest(props: Props) {
    return TradingService.backtest(
      this.state.service,
      this.state.fundingAmount,
      this.state.interval,
      this.state.symbol
    );
  }

  botTradeToUserTrade(trade: Order) {
    const x: IUserTrade = {
      _id: "",
      amount: trade.size,
      productId: this.state.symbol,
      price: trade.price,
      historicalPrice: trade.price,
      side: trade.side,
      service: this.state.service,
      status: trade.status as any,
      funding: "",
      order: null,
      type: trade.type,
      lastUpdated: Date.now(),
      user: ""
    };
    return x;
  }

  render() {
    if (this.state.loading) {
      return <Loading />;
    }

    const historical = this.state.bot.historicalData[this.state.symbol];
    const lastHigh = historical[historical.length - 1].high;
    const rates = {
      [this.state.symbol]: lastHigh,
      [Util.getCurrencyFromPair(this.state.symbol)]: lastHigh
    };
    const pendingTrades = this.state.bot.pendingTrades.map(t =>
      this.botTradeToUserTrade({ ...t, status: "placed" })
    );
    const filledTrades = this.state.bot.filledTrades.map(t =>
      this.botTradeToUserTrade({ ...t, status: "filled" })
    );
    const valueOfTrades = this.state.bot.filledTrades.reduce(
      (sum, t) => sum + Number(t.size) * Number(rates[t.product_id]),
      0
    );

    const sumOfInputs = this.state.bot.filledTrades.reduce(
      (sum, t) => sum + Number(t.size) * Number(t.price),
      0
    );

    return (
      <div>
        <h1>Simulating 6 Months of Trading...</h1>
        <h2>
          Buy ${this.state.fundingAmount} of {this.state.symbol}{" "}
          {this.state.intervalName}
        </h2>
        <Balance
          balance={this.state.bot.balances.USD.unlocked + valueOfTrades}
          unlocked={this.state.bot.balances.USD.unlocked}
          locked={this.state.bot.balances.USD.locked}
          gain={valueOfTrades - sumOfInputs}
          funding={[]}
        />
        <Prices
          fundingLimit={10}
          pro={true}
          pairs={[
            {
              symbol: this.state.symbol,
              price: rates[this.state.symbol],
              pair: this.state.symbol
            }
          ]}
          candles={this.state.bot.historicalData}
          pendingTrades={pendingTrades}
          suggestedTrades={[]}
          onCancel={t => {}}
          onReject={t => {}}
          onApprove={t => {}}
          onUpdateFunding={f => {}}
          onRemoveFunding={f => {}}
          rates={{}}
          funding={[]}
        />

        <h2>Pending Trades ({pendingTrades.length})</h2>
        <TradeList trades={pendingTrades} rates={rates} />
        <h2>Filled Trades ({filledTrades.length})</h2>
        <TradeList trades={filledTrades} rates={rates} />
      </div>
    );
  }
}
