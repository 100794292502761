import React from "react";
import { RouteComponentProps } from "react-router";
import {
  IUserFunding,
  IUserTrade,
  Candle,
  Rates,
  IUserBot,
  Intervals,
  CurrencyCandles,
} from "../../../types";
import { Util } from "../../../services/Util";
import { TradingService } from "../../../services/TradingService";
import Paper from "@material-ui/core/Paper";
import VpnKey from "@material-ui/icons/VpnKey";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import DateRange from "@material-ui/icons/DateRange";
import Button from "@material-ui/core/Button";
import { BasicStyles } from "../../../styles";
import { AppData, ServiceName } from "../../../contexts/AppData";
import { Membership } from "../../../constants";

export interface Props extends RouteComponentProps<any> {}
export interface State {
  loading: boolean;
  service: ServiceName;
  bot: Partial<IUserBot>;
  requestStarted: boolean;
  needsSecret: boolean;
  needsFunding: boolean;
  configure: boolean;
  fundingDate: string;
  createFunding: Partial<IUserFunding>;
  funding: Array<IUserFunding>;
  trades: Array<IUserTrade>;
  candles: CurrencyCandles;
  totalBalance: number;
  balances: { [currency: string]: { locked: number; unlocked: number } };
  serviceRates: { currency: string; rates: Rates };
}

export abstract class BaseContainer extends React.Component<Props, any> {
  static contextType = AppData;
  context!: React.ContextType<typeof AppData>;
  state: State = {
    loading: true,
    service: "coinbase-advanced",
    bot: {},
    requestStarted: false,
    needsFunding: true,
    needsSecret: true,
    configure: false,
    fundingDate: Util.toInputDate(new Date()),
    createFunding: {
      interval: Intervals[3].value,
      purchaseSymbol: "",
    },
    funding: [],
    trades: [],
    balances: { USD: { locked: 0, unlocked: 0 } },
    totalBalance: 0,
    serviceRates: { currency: "USD", rates: { USD: 1 } },
    candles: {},
  };

  async componentDidMount() {
    const loggedIn = await TradingService.isLoggedIn();
    if (typeof loggedIn === "boolean") {
      this.props.history.push("/login");
    } else {
      this.context.setUser(loggedIn);
      const client = this.context.getSocket();
      client.on(`${this.state.service}:update`, async (u: any) => {
        await this.context.refreshTrades(this.state.service);
        await this.context.refreshFunding(this.state.service);
        await this.context.loadAllNotifications();
        await this.initialize();
      });
      client.on(`${this.state.service}:funding`, async (u: any) => {
        console.log(`${this.state.service}:funding`, u);
        const funding = await TradingService.getUserFunding(
          this.state.service,
          u.funding
        );
        if (funding) {
          this.context.updateFunding(this.state.service, funding);
        }
        await this.context.loadAllNotifications();
        console.log(funding);
      });
      client.on(`${this.state.service}:trades`, async (u: any) => {
        console.log(`${this.state.service}:trades`, u);
        const trade = await TradingService.getUserTrade(
          this.state.service,
          u.trade
        );
        if (trade) {
          this.context.updateTrade(this.state.service, trade);
          const funding = await TradingService.getUserFunding(
            this.state.service,
            trade.funding
          );
          if (funding) {
            this.context.updateFunding(this.state.service, funding);
          }
        }
        await this.context.loadAllNotifications();
        console.log(trade);
      });
    }
  }

  async componentWillUnmount() {
    this.context.getSocket().removeAllListeners();
  }

  getFundingLimit() {
    return this.isProUser()
      ? Membership.Pro.limit[this.state.service]
      : Membership.Free.limit[this.state.service];
  }

  isProUser() {
    return this.context.user.subscriptionValidTil > Date.now() / 1000;
  }

  async initialize(refresh = false, startLoading = false) {
    try {
      if (startLoading) {
        this.setLoading();
      }
      const [secret, bot] = await Promise.all([
        TradingService.getSecret(this.state.service),
        TradingService.getTradingBot(this.state.service),
      ]);
      this.setState({ needsSecret: !secret, bot });
      const serviceData = this.getServiceData();
      if (!serviceData || serviceData.trades.length === 0 || refresh) {
        await this.context.initialize(this.state.service);
      }
      await Promise.all([
        this.loadRates(),
        this.loadBalances(),
        this.loadTrades(),
        this.loadFunding(),
      ]);
      await this.loadCandles();
      this.doneLoading();
      this.setState({ needsFunding: this.state.funding.length == 0 });
    } catch (e) {
      console.log(e);
      this.doneLoading();
    }
  }

  getServiceData() {
    return this.context.services[this.state.service];
  }

  async loadTrades() {
    const serviceData = this.getServiceData();
    this.setState({ trades: serviceData.trades });
  }

  async loadFunding(refresh = false) {
    if (refresh) {
      return this.initialize(true);
    }
    const serviceData = this.getServiceData();
    this.setState({ funding: serviceData.funding });
  }

  async loadBalances() {
    const serviceData = this.getServiceData();
    const balances = serviceData.balances;
    await this.setState({ balances });
    const totalBalance = this.getTotalBalance();
    this.setState({ totalBalance });
  }

  holdings() {
    return Object.entries(this.state.balances)
      .filter(([k, v]) => v.unlocked)
      .map(([k, v]) => ({
        currency: k,
        unlocked: Util.toFixedNumber(v.unlocked),
        usd: Util.toFixedNumber(v.unlocked * this.getPriceOfPair(k)),
      }));
  }

  async loadRates() {
    const serviceData = this.getServiceData();
    this.setState({ serviceRates: serviceData.serviceRates });
  }

  setLoading() {
    return this.setState({ requestStarted: true });
  }

  doneLoading() {
    setTimeout(() => {
      this.setState({ requestStarted: false, loading: false });
    }, 500);
  }

  toggleFunding() {
    this.setState({
      needsFunding: !this.state.needsFunding,
    });
  }

  async loadCandles() {
    const serviceData = this.getServiceData();
    this.setState({ candles: serviceData.candles });
  }

  getPairs() {
    return Array.from(
      new Set(this.state.funding.map((f) => f.purchaseSymbol))
    ).map((pair) => ({
      symbol: Util.getCurrencyFromPair(pair),
      price: this.getPriceOfPair(pair),
      pair,
    }));
  }

  getPriceOfPair(pair: string) {
    const currency = Util.getCurrencyFromPair(pair);
    const currentRate = this.state.serviceRates.rates[currency] || 1;
    return Util.toFixedNumber(currentRate);
  }

  getTotalBalance(): number {
    return this.context.getTotalBalance(
      this.state.service,
      this.state.balances,
      this.state.serviceRates
    );
  }

  getWordForInterval(interval: number) {
    const match = Intervals.find((i) => i.value === interval);
    return match ? match.name : interval + " Seconds";
  }

  handleFundingAmountChange(newValue: string) {
    try {
      const createFunding = this.state.createFunding;
      createFunding.amount = Number.parseFloat(newValue);
      this.setState({ createFunding });
    } catch (e) {}
  }

  handleFundingIntervalChange(newValue: string) {
    try {
      const createFunding = this.state.createFunding;
      createFunding.interval = Number.parseFloat(newValue);
      this.setState({ createFunding });
    } catch (e) {}
  }

  handleFundingPurchaseSymbolChange(newValue: string) {
    console.log(newValue);
    const createFunding = this.state.createFunding;
    createFunding.purchaseSymbol = newValue;
    this.setState({ createFunding });
  }

  handleFundingUpdate<K extends keyof IUserFunding>(
    property: K,
    index: number,
    newValue: IUserFunding[K]
  ) {
    const arr = this.state.funding.slice();
    const funding = arr[index];
    funding[property] = newValue;
    arr[index] = funding;
    this.setState({ funding: arr });
  }

  async addFunding() {
    await TradingService.updateUserFunding(
      this.state.service,
      this.state.createFunding
    );
    await this.initialize(true);
  }

  async updateFunding(funding: IUserFunding) {
    await TradingService.updateUserFunding(this.state.service, funding);
    await this.loadFunding(true);
  }

  async removeFunding(funding: IUserFunding) {
    await TradingService.deleteUserFunding(this.state.service, funding);
    await this.loadFunding(true);
  }

  async setBotState(newState: string) {
    const normalizedState = newState.toLowerCase();
    if (normalizedState === "enable") {
      await TradingService.enableTrading(this.state.service);
    } else {
      await TradingService.disableTrading(this.state.service);
    }
    this.initialize(true);
  }

  async approveTrade(trade: IUserTrade) {
    console.log("Approving trade", trade);
    await TradingService.approveUserTrade(trade);
    await this.initialize(true);
  }

  async rejectTrade(trade: IUserTrade) {
    console.log("Rejecting trade", trade);
    await TradingService.rejectUserTrade(trade);
    this.initialize(true);
  }

  async cancelTrade(trade: IUserTrade) {
    console.log("Canceling trade", trade);
    await TradingService.cancelTrade(trade);
    this.initialize(true);
  }

  getUnapprovedTrades() {
    return this.state.trades.filter((t) => t.status === undefined);
  }

  getPendingTrades() {
    return this.state.trades.filter((t) => t.status === "placed");
  }

  getHistoricalTrades() {
    return this.state.trades.filter((t) => t.status === "filled");
  }

  getTotalGain() {
    const totalGain = Number(
      this.getServiceData()
        .allTrades.filled.reduce(
          (sum, t) => sum + (t.unrealized || 0) + (t.realized || 0),
          0
        )
        .toFixed(2)
    );
    return totalGain;
  }

  getGainPerTrade(t: IUserTrade) {
    return Util.getGainPerTrade(t, this.state.serviceRates.rates);
  }

  fundingProvided() {
    return (
      <Paper style={BasicStyles.primaryCard}>
        <div>
          <MonetizationOn fontSize="large" />
          <DateRange fontSize="large" />
        </div>
        <p>Funding Schedule Supplied!</p>
        <Button onClick={() => this.toggleFunding()}>
          Click here to modify
        </Button>
      </Paper>
    );
  }

  filledTradeDetails(trade: IUserTrade) {
    const gain = this.getGainPerTrade(trade);
    const { gainWord, gainStyle } = Util.getGainType(gain);

    return (
      <p style={gainStyle}>
        <b>{gainWord}: </b>${Math.abs(gain).toFixed(2)}
      </p>
    );
  }
}
