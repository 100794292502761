import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
interface Props {}
interface State {}

const styles = {
  spinner: {
    marginTop: "25%",
  }
};
export class Loading extends React.Component<Props, State> {
  render() {
    return (
      <div>
        <CircularProgress style={styles.spinner} size={"100px"} />
      </div>
    );
  }
}
