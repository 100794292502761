import React from "react";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Button from "@material-ui/core/Button";
import { TradingService } from "../../services/TradingService";
import { RouteComponentProps } from "react-router";
import { BasicStyles } from "../../styles";
import { Link } from "react-router-dom";
import { Membership } from "../../constants";

const styles = {
  logoContainer: {
    maxWidth: "320px"
  },
  textContainer: { textAlign: "left" as "left" },
  img: { maxWidth: "100%" }
};

interface IProps extends RouteComponentProps {}
export class LandingContainer extends React.Component<IProps, {}> {
  render() {
    return (
      <div>
        <div>
          <img src="logo.png" />
        </div>
        <div>
          <div
            style={{ ...BasicStyles.primaryCardFull, ...styles.textContainer }}
          >
            <h1>Automate Your Investing</h1>
            <h2>Compatible with Coinbase, Robinhood, and SoFi</h2>

            <ul>
              <li>
                Recurring scheduled investments into cryptocurrencies and
                stocks.
              </li>
              <li>
                Dollar cost averaging, and snagging good deals via intelligent
                price analysis and backtesting.
              </li>
            </ul>
            <div style={{ textAlign: "center" }}>
              <Link to={"/login"}>
                <Button variant="contained" color="primary" size="large">
                  Login or Join
                </Button>
              </Link>
            </div>
          </div>
          <div
            style={{ ...BasicStyles.primaryCardFull, ...styles.textContainer }}
          >
            <h1>Quick Demo</h1>
            <iframe
              width="100%"
              height="500"
              src="https://www.youtube.com/embed/XoYu-cmMGzc"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowFullScreen={true}
            />
          </div>

          <div
            style={{
              ...BasicStyles.primaryCardFull,
              ...styles.textContainer
            }}
          >
            <div
              style={{
                ...BasicStyles.rowOrColumn,
                justifyContent: "space-around"
              }}
            >
              <div style={BasicStyles.slightMargin}>
                <h1>Free Plan</h1>
                <ul>
                  <li>
                    Coinbase ({Membership.Free.limit.coinbase} purchase
                    schedules)
                  </li>
                  <li>
                    Robinhood ({Membership.Free.limit.robinhood} purchase
                    schedules)
                  </li>
                  <li>
                    SoFi ({Membership.Free.limit.sofi} purchase schedules)
                  </li>
                  <li>
                    <Link to={"/profile"}>SMS Alerts</Link>
                  </li>
                </ul>
              </div>
              <div style={BasicStyles.slightMargin}>
                <h1>Pro Plan - ${Membership.Pro.cost}</h1>
                <ul>
                  <li>
                    Coinbase ({Membership.Pro.limit.coinbase} purchase
                    schedules)
                  </li>
                  <li>
                    Robinhood ({Membership.Pro.limit.robinhood} purchase
                    schedules)
                  </li>
                  <li>SoFi ({Membership.Pro.limit.sofi} purchase schedules)</li>
                  <li>
                    <Link to={"/profile"}>SMS Alerts</Link>
                  </li>
                  <li>Daily Buy Schedule</li>
                  <li>Pro Support</li>
                  <li>Auto Approve Trades</li>
                </ul>
                <div style={{ textAlign: "center" }}>
                  <Link to={"/pro"}>
                    <Button variant="contained" color="primary">
                      Upgrade to PRO
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
