import React from "react";
import { IUserFunding, Intervals, IntervalMap } from "../types";
import { Util } from "../services/Util";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import { BasicStyles } from "../styles";

const styles = {
  buttons: {
    paddingTop: "15px",
    paddingBottom: "5px",
    width: window.innerWidth > 750 ? "350px" : "100%",
    margin: "0 auto",
    ...(window.innerWidth <= 750 && { height: "130px" })
  },
  input: {
    minWidth: "166px"
  },
  keys: {
    padding: "15px",
    maxWidth: window.innerWidth > 750 ? "650px" : "",
    margin: "0 auto",
    marginBottom: "30px"
  }
};
export function EditFunding(props: {
  funding: IUserFunding;
  pro: boolean;
  onUpdate: (f: IUserFunding) => any;
  onRemove: (f: IUserFunding) => any;
}) {
  const f = props.funding;
  const [fundingAmount, setFundingAmount] = React.useState(
    props.funding.amount
  );
  const [fundingInterval, setFundingInterval] = React.useState(
    props.funding.interval
  );
  const [fundingNext, setFundingNext] = React.useState(
    props.funding.nextFunding
  );
  const nextFundingStr = Util.toInputDate(new Date(fundingNext));

  const [fundingStrategy, setFundingStrategy] = React.useState(
    props.funding.strategyName || ""
  );

  const [open, setOpen] = React.useState(false);
  const [autoApprove, setAutoApprove] = React.useState(f.autoApprove || false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function update(update: Partial<IUserFunding>) {
    return props.onUpdate({
      ...f,
      ...update
    });
  }
  return (
    <Paper style={{ ...BasicStyles.primaryCardFull, maxWidth: "85%" }}>
      <div style={BasicStyles.rowOrColumn}>
        <TextField
          id="standard-name"
          style={styles.input}
          label="$ Amount"
          margin="normal"
          value={fundingAmount}
          type="number"
          onChange={e => setFundingAmount(Number(e.target.value))}
        />
        <TextField
          label="How Often"
          style={styles.input}
          select
          value={fundingInterval}
          margin="normal"
          onChange={e => setFundingInterval(Number(e.target.value))}
        >
          {Intervals.filter(i => {
            return props.pro || !["Daily", "Hourly"].includes(i.name);
          }).map((i, index) => (
            <MenuItem key={index} value={i.value}>
              {i.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
      {window.innerWidth > 750 ? (
        <div style={BasicStyles.rowOrColumn}>
          <TextField
            label="Strategy"
            style={styles.input}
            select
            SelectProps={{ displayEmpty: true }}
            value={fundingStrategy}
            margin="normal"
            onChange={e => setFundingStrategy(e.target.value)}
          >
            <MenuItem value="">Dynamic</MenuItem>
            <MenuItem value="BullishMarketBuy">Market Buy</MenuItem>
            <MenuItem value="WeeklyMean">Catch Weekly dips</MenuItem>
            <MenuItem value="MonthlyMean">Catch Monthly dips</MenuItem>
            <MenuItem value="HighVolatilityBuy">
              Catch Weekly and Monthly dips
            </MenuItem>
            <MenuItem value="Ladder">Staircase Up</MenuItem>
          </TextField>
          <TextField
            id="next-run"
            label="Next Run"
            margin="normal"
            value={nextFundingStr}
            type="datetime-local"
            onChange={e => {
              const newFunding = new Date(e.target.value + ".000Z");
              try {
                if (
                  newFunding.toISOString() &&
                  newFunding.toISOString() !== "Invalid Date"
                ) {
                  setFundingNext(newFunding.getTime());
                }
              } catch (e) {
                setFundingNext(new Date().getTime());
              }
            }}
          />
        </div>
      ) : null}

      {props.pro ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={autoApprove}
              onChange={(e, v) => {
                setAutoApprove(v);
              }}
              value={autoApprove}
              color="primary"
            />
          }
          label="Auto Approve"
        />
      ) : null}
      <Divider style={BasicStyles.slightMargin} />
      <div style={{ ...styles.buttons, ...BasicStyles.rowOrColumn }}>
        <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
          Remove
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            update({
              amount: fundingAmount,
              interval: fundingInterval,
              nextFunding: fundingNext,
              strategyName: fundingStrategy,
              autoApprove: autoApprove
            })
          }
        >
          Update
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            const nextFunding = Date.now() - IntervalMap.daily;
            setFundingNext(nextFunding);
            update({
              nextFunding,
              amount: fundingAmount,
              interval: fundingInterval,
              strategyName: fundingStrategy,
              autoApprove: autoApprove
            });
          }}
        >
          Run Now
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Remove this funding?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will no longer receive trade suggestions for {f.purchaseSymbol}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button
            onClick={() => {
              props.onRemove(f);
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
