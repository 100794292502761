import React from "react";
import { IUserTrade, IUserDividend } from "../types";
import Timeline from "@material-ui/icons/Timeline";
import {
  LineChart,
  Bar,
  Legend,
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid,
  Line,
  ResponsiveContainer,
} from "recharts";
import { Util } from "../services/Util";
export function DividendsHistoryChart(props: {
  dividends: Array<IUserDividend>;
}) {
  const monthlyData = Object.values(
    props.dividends
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .reduce(
        (group, o) => {
          console.log(o);
          const placed = new Date(o.date);
          const placedKey = placed.getMonth() + 1 + "-" + placed.getFullYear();

          if (!group[placedKey]) {
            group[placedKey] = {
              month: placedKey,
              year: placed.getFullYear(),
              dividends: 0,
            };
          }
          group[placedKey].dividends = Util.toFixedNumber(
            group[placedKey].dividends + o.amount, 2
          );
          return group;
        },
        {} as {
          [monthKey: string]: {
            month: string;
            year: number;
            dividends: number;
          };
        }
      )
  );

  if (!monthlyData || !monthlyData.length) {
    return (
      <div style={{ width: window.innerWidth > 700 ? 400 : 350 }}>
        <div>
          <Timeline style={{ fontSize: "200px", color: "gray" }} />
        </div>
        <p>Some stocks pay dividends! Get some and you'll get a cool graph</p>
      </div>
    );
  }
  return (
    <LineChart
      width={window.innerWidth > 700 ? 700 : 350}
      height={300}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
      data={monthlyData}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line dataKey="dividends" stroke="#8884d8" />
    </LineChart>
  );
}
