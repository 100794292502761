import React from "react";
import * as qs from "qs";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import { IUser } from "../../types";
import { TradingService } from "../../services/TradingService";
import { AppData } from "../../contexts/AppData";
import { RouteComponentProps } from "react-router";
import { UpdateUser } from "../../components/UpdateUser";
import { Loading } from "../../components/Loading";
import { SideNav } from "../../components/SideNav";
import { BottomNav } from "../../components/BottomNav";
import { Link } from "react-router-dom";
import { BasicStyles } from "../../styles";
import { Membership } from "../../constants";

const liveKey = "pk_live_YVMWXaWNgc1bQm0iahekh2fN";
const testKey = "pk_test_2Dr5cUREN5hXRAbjIG8UI0Yb";
const backendUrl = process.env.REACT_APP_BACKEND || "http://localhost:8000";

const stripeKey = backendUrl.includes("localhost") ? testKey : liveKey;

const prodUrl = "https://www.robo-trades.com/pro";
const DOMAIN = window.location.hostname;
const testUrl = window.location.protocol + "//" + DOMAIN + ":3000/pro";
const redirectUrl = backendUrl.includes("localhost") ? testUrl : prodUrl;

const planId = backendUrl.includes("localhost")
  ? "plan_Ga175J1YhiV82k"
  : "plan_Ga4BkoniW7HUqd";

type Props = RouteComponentProps<{}> & {
  user: IUser;
  setUser: (user: IUser) => void;
};
type State = {
  error?: string;
};
export class ProComponent extends React.Component<Props, State> {
  async componentDidMount() {
    const loggedIn = await TradingService.isLoggedIn();
    if (typeof loggedIn === "boolean") {
      this.props.history.push("/login");
    } else {
      this.props.setUser(loggedIn);
    }
  }

  handleResult(result: any) {
    if (result.error) {
      var displayError = document.getElementById("error-message");
      this.setState({ error: result.error.message });
    }
  }

  handleSubscribe() {
    const stripe = (window as any).Stripe(stripeKey);
    const DOMAIN = window.location.hostname;
    stripe
      .redirectToCheckout({
        items: [{ plan: planId, quantity: 1 }],
        customerEmail: this.props.user.email,
        successUrl: redirectUrl + "/success",
        cancelUrl: redirectUrl + "/canceled"
      })
      .then((r: any) => this.handleResult(r));
  }
  render() {
    if (!this.props.user || !this.props.user.email) {
      return <Loading />;
    }
    const isSubbed = this.props.user.stripeSubscription;
    const subDate = this.props.user.subscriptionValidTil;
    return (
      <div>
        <SideNav currentState={"profile"} />
        <img height={150} src="/logo.png" />
        <div
          style={{
            ...BasicStyles.rowOrColumn,
            maxWidth: "720px",
            ...(window.innerWidth < 750 ? { minHeight: "900px" } : null),
            margin: "auto"
          }}
        >
          <div>
            <Paper
              style={{
                marginTop: "25px",
                ...BasicStyles.primaryCardFull,
                margin: " 0 auto",
                alignItems: "center",
                maxWidth: "350px",
                textAlign: "left"
              }}
            >
              <h1>Free Plan</h1>
              <ul>
                <li>
                  Coinbase ({Membership.Free.limit.coinbase} purchase schedules)
                </li>
                <li>
                  Robinhood ({Membership.Free.limit.robinhood} purchase
                  schedules)
                </li>
                <li>SoFi ({Membership.Free.limit.sofi} purchase schedules)</li>
              </ul>
            </Paper>
          </div>
          <div>
            <Paper
              style={{
                marginTop: "25px",
                ...BasicStyles.primaryCardFull,
                margin: " 0 auto",
                alignItems: "center",
                maxWidth: "350px",
                textAlign: "left"
              }}
            >
              <h1>Pro Plan - ${Membership.Pro.cost}</h1>
              {subDate ? (
                <span>
                  Renews {new Date(subDate * 1000).toLocaleDateString()}{" "}
                </span>
              ) : null}
              <ul>
                <li>
                  Coinbase ({Membership.Pro.limit.coinbase} purchase schedules)
                </li>
                <li>
                  Robinhood ({Membership.Pro.limit.robinhood} purchase
                  schedules)
                </li>
                <li>SoFi ({Membership.Pro.limit.sofi} purchase schedules)</li>

                <li>
                  <Link to={"/profile"}>SMS Alerts</Link>
                </li>
                <li>Daily Buy Schedule</li>
                <li>Hourly Buy Schedule</li>
                <li>Pro Support</li>
                <li>Auto Approve Trades</li>
              </ul>
              {subDate && subDate * 1000 > Date.now() ? (
                <p>
                  If you have any questions, or requests, you can reach me via
                  the chat, or email micahriggan@gmail.com
                </p>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => this.handleSubscribe()}
                >
                  Upgrade to Pro
                </Button>
              )}
            </Paper>
          </div>
        </div>
        <BottomNav currentState="home" />
      </div>
    );
  }
}
export function ProContainer(props: RouteComponentProps) {
  return (
    <AppData.Consumer>
      {context => <ProComponent {...props} {...context} />}
    </AppData.Consumer>
  );
}
