import React from "react";
import "./App.css";
import { createBrowserHistory } from "history";
import { Router, Switch, Route } from "react-router";
import { LoginContainer } from "./containers/login/login";
import { RobinhoodContainer } from "./containers/exchanges/robinhood/Robinhood";
import { CoinbaseContainer } from "./containers/exchanges/coinbase/CoinbaseContainer";
import { KrakenContainer } from "./containers/exchanges/kraken/Kraken";
import { DashboardContainer } from "./containers/dashboard/DashboardContainer";
import { BacktestContainer } from "./containers/backtest/BacktestContainer";
import { AppDataProvider } from "./contexts/AppData";
import { ProfileContainer } from "./containers/profile/Profile";
import { SofiContainer } from "./containers/exchanges/sofi/Sofi";
import { PerformanceContainer } from "./containers/performance/PerformanceContainer";
import { DividendsContainer } from "./containers/dividends/DividendContainer";
import { ProContainer } from "./containers/pro/ProContainer";
import { LandingContainer } from "./containers/landing";
import { NotificationsContainer } from "./containers/notifications/Notifications";
import { HoldingsContainer } from "./containers/holdings/HoldingsContainer";
import { AllocatedContainer } from "./containers/allocated/AllocatedContainer";
import { HoldingContainer } from "./containers/holding/HoldingContainer";
import { FundsContainer } from "./containers/funds/FundsContainer";
import { BinanceContainer } from "./containers/exchanges/binance/Binance";
import { PasswordResetContainer } from "./containers/passwordreset/passwordreset";
import { CelciusContainer } from "./containers/exchanges/celcius/CelciusContainer";

const customHistory = createBrowserHistory();
const App: React.FC = () => {
  return (
    <div className="App">
      <AppDataProvider>
        <Router history={customHistory}>
          <Switch>
            <Route
              exact
              path="/notifications"
              component={NotificationsContainer}
            />
            <Route exact path="/profile" component={ProfileContainer} />
            <Route exact path="/pro" component={ProContainer} />
            <Route exact path="/pro/:success" component={ProContainer} />
            <Route exact path="/login" component={LoginContainer} />
            <Route
              exact
              path="/passwordreset"
              component={PasswordResetContainer}
            />
            <Route
              exact
              path="/passwordreset/:secret"
              component={PasswordResetContainer}
            />
            <Route exact path="/holdings" component={HoldingsContainer} />
            <Route exact path="/funds" component={FundsContainer} />
            <Route
              exact
              path="/:service/holding/:currency"
              component={HoldingContainer}
            />
            <Route exact path="/allocated" component={AllocatedContainer} />
            <Route exact path="/performance" component={PerformanceContainer} />
            <Route exact path="/dividends" component={DividendsContainer} />
            <Route exact path="/coinbase-advanced" component={CoinbaseContainer} />
            <Route exact path="/kraken" component={KrakenContainer} />
            <Route exact path="/robinhood" component={RobinhoodContainer} />
            <Route exact path="/sofi" component={SofiContainer} />
            <Route exact path="/binance" component={BinanceContainer} />
            <Route exact path="/celcius" component={CelciusContainer} />
            <Route
              exact
              path="/backtest/:service/:symbol/:fundingAmount/:interval/"
              component={BacktestContainer}
            />
            <Route exact path="/dashboard" component={DashboardContainer} />
            <Route exact path="/" component={LandingContainer} />
          </Switch>
        </Router>
      </AppDataProvider>
    </div>
  );
};

export default App;
