import React from "react";
import { AppState, AppData } from "../../contexts/AppData";
import { RouteComponentProps } from "react-router";
import { TradingService } from "../../services/TradingService";
import { Util } from "../../services/Util";
import { SideNav } from "../../components/SideNav";
import { BasicStyles } from "../../styles";
import MaterialTable from "material-table";
import { BottomNav } from "../../components/BottomNav";

type Props = AppState & RouteComponentProps<{}>;

export class Dividends extends React.Component<Props, {}> {
  async componentDidMount() {
    const loggedIn = await TradingService.isLoggedIn();
    if (typeof loggedIn === "boolean") {
      this.props.history.push("/login");
    } else {
      this.props.setUser(loggedIn);
      await this.props.initializeAll();
      this.props.doneLoading();
    }
  }

  getAllDividends() {
    const dividends = {} as {
      [productId: string]: {
        amount: number;
        productId: string;
        balance: number;
        usdAmount: number;
        percent: number;
      };
    };
    for (const service of Object.keys(this.props.services)) {
      const serviceData = this.props.services[service];
      if (serviceData && serviceData.dividends) {
        const balances = serviceData.balances;
        const rates = serviceData.serviceRates.rates;
        for (const dividend of serviceData.dividends) {
          const { productId, amount } = dividend;
          const balance = balances[productId]
            ? balances[productId].unlocked
            : 0;
          const rate = rates[productId] ? rates[productId] : 0;
          const usdAmount = Util.toFixedNumber(balance * rate);

          if (!dividends[productId]) {
            dividends[productId] = {
              productId: productId,
              balance,
              usdAmount,
              amount: 0,
              percent: 0,
            };
          }
          dividends[productId].amount += dividend.amount;
          dividends[productId].amount = Util.toFixedNumber(
            dividends[productId].amount
          );
          dividends[productId].percent = balance
            ? Util.toFixedNumber(
                (dividends[productId].amount / dividends[productId].usdAmount) *
                  100
              )
            : 0;
        }
      }
    }
    return Object.values(dividends).sort((a, b) => b.amount - a.amount);
  }

  render() {
    const dividends = this.getAllDividends();
    return (
      <div>
        <SideNav currentState={"home"} />
        <img height={150} src="logo.png" />

        <div style={BasicStyles.primaryTableFull}>
          <MaterialTable
            isLoading={this.props.loading}
            columns={[
              {
                title: "Symbol",
                field: "productId" as "productId",
                type: "string" as "string",
              },
              {
                title: "Shares",
                field: "balance" as "balance",
                type: "numeric",
              },
              {
                title: "Current Balance",
                field: "usdAmount" as "usdAmount",
                type: "numeric",
              },
              {
                title: "Yield",
                field: "percent" as "percent",
                type: "numeric",
              },
              {
                title: "Total Dividends",
                field: "amount" as "amount",
                type: "numeric",
              },
            ]}
            data={dividends}
            options={{ pageSize: 10 }}
            title="Dividends"
          />
        </div>
        <BottomNav currentState="home" />
      </div>
    );
  }
}

export function DividendsContainer(props: RouteComponentProps) {
  return (
    <AppData.Consumer>
      {(context) => <Dividends {...props} {...context} />}
    </AppData.Consumer>
  );
}
