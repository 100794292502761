import React from "react";
import Paper from "@material-ui/core/Paper";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { BasicStyles } from "../styles";
export function EmptyTrade() {
  return (
    <div style={BasicStyles.primaryCard}>
      <CheckCircle fontSize="large" />
      <p>All good! No trades to review</p>
    </div>
  );
}
