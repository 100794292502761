import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import IconButton from "@material-ui/core/IconButton";
export function TopBar() {
  return (
    <AppBar
      position="static"
      style={{
        backgroundColor: "darkcyan",
      }}
    >
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Link className="App-link" to={"/dashboard"}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "xxx-large" }}>💰</span>{" "}
            <span
              style={{
                margin: "10px",
                fontSize: "x-large",
                fontWeight: 200,
              }}
            >
              invested.cash
            </span>
          </div>
        </Link>
        <Link className="App-link" to={"/profile"}>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Link>
      </Toolbar>
    </AppBar>
  );
}
