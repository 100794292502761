// curl https://api.pro.coinbase.com/products | jq 'map(select(.id | test("^.*-USD$"))) | sort_by(.id) | map({name: .id})'
export const CoinbaseTradingPairs = [
  {
    name: "AAVE-USD",
  },
  {
    name: "ALGO-USD",
  },
  {
    name: "ATOM-USD",
  },
  {
    name: "BAL-USD",
  },
  {
    name: "BAND-USD",
  },
  {
    name: "BCH-USD",
  },
  {
    name: "BNT-USD",
  },
  {
    name: "BTC-USD",
  },
  {
    name: "CGLD-USD",
  },
  {
    name: "COMP-USD",
  },
  {
    name: "DAI-USD",
  },
  {
    name: "DASH-USD",
  },
  {
    name: "EOS-USD",
  },
  {
    name: "ETC-USD",
  },
  {
    name: "ETH-USD",
  },
  {
    name: "FIL-USD",
  },
  {
    name: "GRT-USD",
  },
  {
    name: "KNC-USD",
  },
  {
    name: "LINK-USD",
  },
  {
    name: "LRC-USD",
  },
  {
    name: "LTC-USD",
  },
  {
    name: "MKR-USD",
  },
  {
    name: "NMR-USD",
  },
  {
    name: "NU-USD",
  },
  {
    name: "OMG-USD",
  },
  {
    name: "OXT-USD",
  },
  {
    name: "REN-USD",
  },
  {
    name: "REP-USD",
  },
  {
    name: "SNX-USD",
  },
  {
    name: "UMA-USD",
  },
  {
    name: "UNI-USD",
  },
  {
    name: "WBTC-USD",
  },
  {
    name: "XLM-USD",
  },
  {
    name: "XRP-USD",
  },
  {
    name: "XTZ-USD",
  },
  {
    name: "YFI-USD",
  },
  {
    name: "ZEC-USD",
  },
  {
    name: "ZRX-USD",
  },
];
